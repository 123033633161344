import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Cia_agent.css';
import img8 from '../images/8.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Criminologist = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Criminologist - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CRIMINOLOGIST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href={`#what_are_the_duties_of_a_criminologist`}>What Are The Duties Of A Criminologist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#requirements_to_become_a_criminologist`}>Requirements To Become A Criminologist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#STATES`}>STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#steps_to_become_a_criminologist`}>Steps To Become A Criminologist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_long_does_it_take_to_become_a_criminologist`}>How Long Does It Take To Become A Criminologist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_much_does_a_criminologist_usually_earn`}>How Much Does A Criminologist Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="what_are_the_duties_of_a_criminologist">
                                <h2>WHAT ARE THE DUTIES OF A CRIMINOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A criminologist investigates the reasons why someone commits a crime and its effects on the victims as well as the society by doing research as well as social experiments. Here is the quick list of the duties that a criminologist usually carries out. A criminologist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Investigate how and why a crime is committed</li>
                                        <br />
                                        <li>Try to find the information that could solve a case</li>
                                        <br />
                                        <li>Predict a crime</li>
                                        <br />
                                        <li>Find ways to prevent the crime from happening</li>
                                        <br />
                                        <li>Study criminal behaviors</li>
                                        <br />
                                        <li>Prevent criminals from acting on their criminal urges</li>
                                        <br />
                                        <li>Investigate the responsiveness of law enforcers to the crime</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CRIMINOLOGIST</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img8} alt="STEPS TO BECOME A CRIMINOLOGIST" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_criminologist">
                                <h2>REQUIREMENTS TO BECOME A CRIMINOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think that becoming a criminologist is your life goal then you must familiarize yourself with the requirements of becoming a criminologist. A criminologist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Must have a bachelor’s degree in criminology, sociology or other related fields</li>
                                        <br />
                                        <li>Have a graduate curriculum including study of genetic, statistics or other related fields</li>
                                        <br />
                                        <li>Have excellent analytical skills</li>
                                        <br />
                                        <li>Must have a keen observation</li>
                                        <br />
                                        <li>Must have interest in math, computer science and statistics</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_criminologist">
                                <h2>STEPS TO BECOME A CRIMINOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the step by step guide to help you comprehend the process of becoming a criminologist in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Every candidate aiming to become a criminologist in future must have a High School Diploma or GED or any degree equivalent to it. Afterwards, one must secure an admission in a college to pursue a bachelor’s degree in criminology, sociology or other related fields.
                                        When one completes the bachelor’s degree, one is eligible to apply for the entry level job. But nowadays, most institutions demand a graduate degree in criminology, sociology or other related fields for the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Secure An Internship</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Police department, law office, state or federal government office, a community organization involved with the justice system are some of the institutions where one can apply for internship. In addition to that, one can also join the research groups to learn how to put the skills one has learnt in college to practical use.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Getting A License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the internship, one should study to pass the written examination in order to get the license of criminology. The format of the license written test will be different for different state and will depend on the policy of the agency that you wish to work for. The test is often very comprehensive that is why a student is often advised to do a rigorous study.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Landing A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        There are a number of organizations in United States of America that provides jobs to licensed criminologists. The International Society for Criminology, The Academy of Criminal Justice Sciences and The Academy of Criminal Justice Sciences are some of the institutions that provide criminologists with opportunities to grow and learn. You can apply in Police Department, FBI and in consultancy agencies for the criminologist job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Pursue A Master’s Or Doctorate Degree</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        If you want to teach criminology or go into research field in the niche of criminology then you need to pursue further studies. You need to have a Master’s degree or a doctorate degree in criminology, sociology or other related fields for a job as a teacher or researcher. You can also get your research papers published in the esteemed journals like The Criminologist, The Journal of Quantitative Criminology, International Annals of Criminology and others.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_criminologist">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CRIMINOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one needs to complete the High School Diploma to become eligible for admission in college for a bachelor’s degree in criminology, sociology or other related fields. It takes four years to complete the bachelor’s degree in criminology, sociology or other related fields. Then it takes another four years to do a Master’s degree in the same fields.
                                        <br />
                                        It takes a few years to do internship as well and then one more year to pass the written examination for license. Thus, it takes quite a few years to become a professional, licensed criminologist.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_criminologist_usually_earn">
                                <h2>HOW MUCH DOES A CRIMINOLOGIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A professional criminologist averagely ears around $82,100 per year as per the data stated by the United States Bureau of Labor Statistics. The salary of criminologist may vary state to state due to different laws of different states of United States. But the state that provides best wage rate to criminologists in United States of America is New Jersey.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}