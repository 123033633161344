import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import logo from '../images/main-logo.png';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaBars } from "@react-icons/all-files/fa/FaBars";

export default function Header() {
    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(states);

    function openNav() {
        document.getElementById("links1").style.width = "85%";
    }

    function closeNav() {
        document.getElementById("navbarSupportedContent").classList.remove("show");
    }

    return (
        <header>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className="container">
                <div className="row justify-content-center pt-2 pb-md-4">
                    <NavLink to={'/'} rel="home">
                        <img src={logo} />
                    </NavLink>
                </div>
            </div>

            {/* For Mobile screens */}
            <Navbar expand="lg" className="d-md-none d-block">
                <Container fluid>
                    <Navbar.Toggle className="ml-auto bg-dark text-white" aria-controls="navbarSupportedContent">
                        <FaBars/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav id="links2">
                            <li className="nav-item active border-0 d-lg-none d-block text-center">
                                <img src={logo} style={{ width: '50%' }} />
                                <button className="close" data-dismiss="navbar-collapse" onClick={closeNav} aria-expanded="false" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    ARTS & HUMANITIES <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/animal-cop/`}>ANIMAL COP</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/business-analyst/`}>BUSINESS ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/customs-agent/`}>CUSTOMS AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/chef/`}>CHEF</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/hedge-fund-manager/`}>HEDGE FUND MANAGER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/librarian/`}>LIBRARIAN</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/loan-officer/`}>LOAN OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/paralegal/`}>PARALEGAL</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/park-ranger/`}>PARK RANGER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    ENGINEERING <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/diesel-mechanic/`}>DEISEL MECHANIC</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/electrical-engineer/`}>ELECTRICAL ENGINEER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/software-developer/`}>SOFTWARE DEVELOPER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/guide-to-become-software-engineer/`}>SOFTWARE ENGINEER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    MEDICAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/cna/`}>CNA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/medical-assistant/`}>MEDICAL ASSISTANT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/midwife/`}>MIDWIDE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-midwife-in-california/`}>MIDWIDE IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-midwife-in-florida/`}>MIDWIDE IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-midwife-in-new-york/`}>MIDWIDE IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-midwife-in-pennsylvania/`}>MIDWIDE IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-midwife-in-texas/`}>MIDWIDE IN TEXAS</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/nurse/`}>NURSE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/nurse-anesthetista/`}>NURSE ANESTHETISTA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/nurse-practitioner/`}>NURSE PRACTITIONER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/phlebotomist/`}>PHLEBOTOMIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/pharmacist/`}>PHARMACIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/physician-assistant/`}>PHYSICIAN ASSISTANT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/registered-nurse/`}>REGISTERED NURSE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/surgeon/`}>SURGEON</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/pastoral-counselor/`}>PASTORAL COUNSELOR</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    PUBLIC SERVICE/ LEGAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/border-patrol-officer/`}>BORDER PATROL OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/criminologist/`}>CRIMINOLOGIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/court-reporter/`}>COURT REPORTER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/cia-agent/`}>CIA AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/crime-scene-investigator/`}>CRIME SCENE INVESTIGATOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/cosmetologist/`}>COSMETOLOGIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/data-analyst/`}>DATA ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/fbi-agent/`}>FBI AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/financial-advisor/`}>FINANCIAL ADVISOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/financial-analyst/`}>FINANCIAL ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/parole-officer/`}>PAROLE OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/police-officer/`}>POLICE OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-police-officer-in-california/`}>POLICE OFFICER IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-police-officer-in-florida/`}>POLICE OFFICER IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-police-officer-in-california/`}>POLICE OFFICER IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-police-officer-in-new-york/`}>POLICE OFFICER IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-police-officer-in-texas/`}>POLICE OFFICER IN TEXAS</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/private-investigator/`}>PRIVATE INVESTIGATOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/real-estate-agent/`}>REAL ESTATE AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/realtor/`}>REALTOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/sheriff/`}>SHERIFF</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-sheriff-in-california/`}>SHERIFF IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-sheriff-in-florida/`}>SHERIFF IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-sheriff-in-new-york/`}>SHERIFF IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-sheriff-in-pennsylvania/`}>SHERIFF IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-sheriff-in-texas/`}>SHERIFF IN TEXAS</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter/`}>FIREFIGHTER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter-in-california/`}>FIREFIGHTER IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter-in-florida/`}>FIREFIGHTER IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter-in-new-york/`}>FIREFIGHTER IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter-in-pennsylvania/`}>FIREFIGHTER IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/firefighter-in-texas/`}>FIREFIGHTER IN TEXAS</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    SCIENCE/ TECH <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/cpa/`}>CPA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/detective/`}>DETECTIVE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-detective-in-california/`}>DETECTIVE IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-detective-in-florida/`}>DETECTIVE IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-detective-in-new-york/`}>DETECTIVE IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-detective-in-pennsylvania/`}>DETECTIVE IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-detective-in-texas/`}>DETECTIVE IN TEXAS</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/forensic-scientist/`}>FORENSIC SCIENTIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-forensic-scientist-in-california/`}>FORENSIC SCIENTIST IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-forensic-scientist-in-florida/`}>FORENSIC SCIENTIST IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-forensic-scientist-in-new-york/`}>FORENSIC SCIENTIST IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-forensic-scientist-in-pennsylvania/`}>FORENSIC SCIENTIST IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-forensic-scientist-in-texas/`}>FORENSIC SCIENTIST IN TEXAS</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    VOCATIONAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/accountant/`}>ACCOUNTANT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/actuary/`}>ACTUARY</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/fish-and-game-warden/`}>FISH AND GAME WARDER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/statistician/`}>STATISTICIAN</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/truck-driver/`}>TRUCK DRIVER</NavLink>
                                    <hr />
                                    {/* <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-truck-driver-in-california/`}>TRUCK DRIVER IN CALIFORNIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-truck-driver-in-florida/`}>TRUCK DRIVER IN FLORIDA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-truck-driver-in-new-york/`}>TRUCK DRIVER IN NEW YORK</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-truck-driver-in-pennsylvania/`}>TRUCK DRIVER IN PENNSYLVANIA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/how-to-become-a-truck-driver-in-texas/`}>TRUCK DRIVER IN TEXAS</NavLink>
                                    <hr /> */}
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/writer/`}>WRITER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" onClick={closeNav} to={`/welder/`}>WELDER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item active">
                                <NavLink className="nav-link" onClick={closeNav} to={`/schools/`}>SCHOOLS</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    STATES <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <NavLink className="dropdown-item" onClick={closeNav} key={key} to={`/` + item.state.replace(/ /g, '-').toLowerCase() + `/`}>{item.state}</NavLink>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* For Laptop & PC screens */}
            <Navbar expand="lg" className="bg-dark d-md-block d-none">
                <Container fluid>
                    <Navbar.Collapse>
                        <Nav className="mx-auto" id='links1'>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    ARTS & HUMANITIES <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/animal-cop/`}>ANIMAL COP</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/business-analyst/`}>BUSINESS ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/customs-agent/`}>CUSTOMS AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/chef/`}>CHEF</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/hedge-fund-manager/`}>HEDGE FUND MANAGER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/librarian/`}>LIBRARIAN</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/loan-officer/`}>LOAN OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/paralegal/`}>PARALEGAL</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/park-ranger/`}>PARK RANGER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    ENGINEERING <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/diesel-mechanic/`}>DEISEL MECHANIC</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/electrical-engineer/`}>ELECTRICAL ENGINEER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/software-developer/`}>SOFTWARE DEVELOPER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/guide-to-become-software-engineer/`}>SOFTWARE ENGINEER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    MEDICAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/cna/`}>CNA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/medical-assistant/`}>MEDICAL ASSISTANT</NavLink>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-25%' }} to={`/midwife/`} role="button" aria-expanded="false">
                                                MIDWIFE <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '100%', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-midwife-in-california/`}>MIDWIDE IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-midwife-in-florida/`}>MIDWIDE IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-midwife-in-new-york/`}>MIDWIDE IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-midwife-in-pennsylvania/`}>MIDWIDE IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-midwife-in-texas/`}>MIDWIDE IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/nurse/`}>NURSE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/nurse-anesthetista/`}>NURSE ANESTHETISTA</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/nurse-practitioner/`}>NURSE PRACTITIONER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/phlebotomist/`}>PHLEBOTOMIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/pharmacist/`}>PHARMACIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/physician-assistant/`}>PHYSICIAN ASSISTANT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/registered-nurse/`}>REGISTERED NURSE</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/surgeon/`}>SURGEON</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/pastoral-counselor/`}>PASTORAL COUNSELOR</NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    PUBLIC SERVICE/ LEGAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/border-patrol-officer/`}>BORDER PATROL OFFICER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/criminologist/`}>CRIMINOLOGIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/court-reporter/`}>COURT REPORTER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/cia-agent/`}>CIA AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/crime-scene-investigator/`}>CRIME SCENE INVESTIGATOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/cosmetologist/`}>COSMETOLOGIST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/data-analyst/`}>DATA ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/fbi-agent/`}>FBI AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/financial-advisor/`}>FINANCIAL ADVISOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/financial-analyst/`}>FINANCIAL ANALYST</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/parole-officer/`}>PAROLE OFFICER</NavLink>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/police-officer/`} role="button" aria-expanded="false">
                                                POLICE OFFICER <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '100%', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-police-officer-in-california/`}>POLICE OFFICER IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-police-officer-in-florida/`}>POLICE OFFICER IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-police-officer-in-pennsylvania/`}>POLICE OFFICER IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-police-officer-in-new-york/`}>POLICE OFFICER IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-police-officer-in-texas/`}>POLICE OFFICER IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/private-investigator/`}>PRIVATE INVESTIGATOR</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/real-estate-agent/`}>REAL ESTATE AGENT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/realtor/`}>REALTOR</NavLink>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/sheriff/`} role="button" aria-expanded="false">
                                                SHERIFF <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '100%', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-sheriff-in-california/`}>SHERIFF IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-sheriff-in-florida/`}>SHERIFF IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-sheriff-in-new-york/`}>SHERIFF IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-sheriff-in-pennsylvania/`}>SHERIFF IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-sheriff-in-texas/`}>SHERIFF IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/firefighter/`} role="button" aria-expanded="false">
                                                FIREFIGHTER <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '100%', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/firefighter-in-california/`}>FIREFIGHTER IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/firefighter-in-florida/`}>FIREFIGHTER IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/firefighter-in-new-york/`}>FIREFIGHTER IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/firefighter-in-pennsylvania/`}>FIREFIGHTER IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/firefighter-in-texas/`}>FIREFIGHTER IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    SCIENCE/ TECH <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/cpa/`}>CPA</NavLink>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/detective/`} role="button" aria-expanded="false">
                                                DETECTIVE <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '-310px', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-detective-in-california/`}>DETECTIVE IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-detective-in-florida/`}>DETECTIVE IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-detective-in-new-york/`}>DETECTIVE IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-detective-in-pennsylvania/`}>DETECTIVE IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-detective-in-texas/`}>DETECTIVE IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/forensic-scientist/`} role="button" aria-expanded="false">
                                                FORENSIC SCIENTIST <FaChevronRight/>
                                            </NavLink>
                                            <div className="dropdown-menu" style={{ marginLeft: '-390px', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-forensic-scientist-in-california/`}>FORENSIC SCIENTIST IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-forensic-scientist-in-florida/`}>FORENSIC SCIENTIST IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-forensic-scientist-in-new-york/`}>FORENSIC SCIENTIST IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-forensic-scientist-in-pennsylvania/`}>FORENSIC SCIENTIST IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-forensic-scientist-in-texas/`}>FORENSIC SCIENTIST IN TEXAS</NavLink>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                    VOCATIONAL <FaChevronDown/>
                                </NavLink>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to={`/accountant/`}>ACCOUNTANT</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/actuary/`}>ACTUARY</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/fish-and-game-warden/`}>FISH AND GAME WARDER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/statistician/`}>STATISTICIAN</NavLink>
                                    <hr />
                                    <ul>
                                        <li className="nav-item dropdown" style={{listStyle: 'none'}} id="submenu">
                                            <NavLink className="nav-link dropdown-toggle" style={{ marginLeft: '-22%' }} to={`/truck-driver/`} role="button" aria-expanded="false">
                                                TRUCK DRIVER
                                            </NavLink>
                                            {/* <div className="dropdown-menu" style={{ marginLeft: '-310px', marginTop: '-25%' }}>
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-truck-driver-in-california/`}>TRUCK DRIVER IN CALIFORNIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-truck-driver-in-florida/`}>TRUCK DRIVER IN FLORIDA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-truck-driver-in-new-york/`}>TRUCK DRIVER IN NEW YORK</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-truck-driver-in-pennsylvania/`}>TRUCK DRIVER IN PENNSYLVANIA</NavLink>
                                                <hr />
                                                <NavLink className="dropdown-item" to={`/how-to-become-a-truck-driver-in-texas/`}>TRUCK DRIVER IN TEXAS</NavLink>
                                            </div> */}
                                        </li>
                                    </ul>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/writer/`}>WRITER</NavLink>
                                    <hr />
                                    <NavLink className="dropdown-item" to={`/welder/`}>WELDER</NavLink>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={`/schools/`}>SCHOOLS</NavLink>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}