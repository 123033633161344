import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Actuary.css';
import actuary from '../images/actuary.png';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Actuary = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become an Actuary - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`The actuary career is a fast-growing and highly paid job that requires certification and education including bachelors degrees that can be obtained online.`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME AN ACTUARY
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of An Actuary</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become An Actuary</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become An Actuary</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become An Actuary</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does An Actuary Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF AN ACTUARY</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An actuary carries out various mathematical calculations and statistical analyses for risk management and ruling out uncertainties. Here is the inventory of the major duties that every actuary in USA usually carries out. An actuary:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Make analysis of the risks by using mathematics as well as statistics</li>
                                        <br />
                                        <li>Calculate the chances of failure and success when a company decides to make a risky business decision</li>
                                        <br />
                                        <li>Suggest ways for risk management</li>
                                        <br />
                                        <li>Try to find financial methods to minimize the losses and increase profits</li>
                                        <br />
                                        <li>Advises the senior management about major decisions</li>
                                        <br />
                                        <li>Play an important role in setting the prices of products</li>
                                        <br />
                                        <li>Suggest premium installations in insurance companies</li>
                                        <br />
                                        <li>Predict new trends in business and advise to make major financial decisions accordingly</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME AN ACTUARY</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={actuary} alt="HOW TO BECOME AN ACTUARY" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME AN ACTUARY</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is no smooth sailing to pursue a career as competent and technical as that of an actuary. Every candidate for the post of an actuary have to meet a plethora of different as well as intellectual requirements. An actuary:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have excellent mathematical skills</li>
                                        <br />
                                        <li>Must have secured excellent grades in mathematics, statistics, business, economic and finance</li>
                                        <br />
                                        <li>Must have a Bachelor’s degree from an accredited institute</li>
                                        <br />
                                        <li>Must have at least the basic knowledge of computer programming languages</li>
                                        <br />
                                        <li>Must know how to operate Microsoft Word, Excel and PowerPoint</li>
                                        <br />
                                        <li>Pass the 10 actuarial examinations from two institutions; The Society of Actuaries (SOA) and the Casualty Actuarial Society (CAS)</li>
                                        <br />
                                        <li>Must complete all the VEE (Validation by Education Experience) credits</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME AN ACTUARY</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you are capable enough to become an actuary in USA then here is a step by step guide for you to follow in order to achieve your dreams.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important for an actuary to have excellent skills when it comes to calculations of any sorts. The three year undergraduate actuarial science degree is the most forward and to the point route to take when one is thinking of becoming an actuary. But most often, people often pursue the 4 year Bachelor’s degree in commerce, economics, mathematics, statistics or other related niches.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Pass The Mandatory Actuarial Examinations</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the degree, one should start preparing to pass the 10 mandatory actuarial examinations. The Society of Actuaries (SOA) is the institution through which the aspiring actuaries take their examinations. First of all, it is recommended that one should attend some workshops online or in person to know the inner workings of the examination process.
                                        One can achieve the Associate of the Society of Actuaries (ASA) credentials by successfully undertaking the first five examinations. The examinations can be quiet tricky that is why, it can easily take 4 to 5 years even for a very competent candidate to take these 5 examinations. Then the candidate has to give the remaining five examinations to earn his license from the national institute.
                                        If the candidate is thinking of working in the insurance department, both of people and their property, then the candidate is required to achieve certification from Casualty Actuarial Society (CAS).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Work As An Actuary</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When a candidate finally successfully clears the 10 examinations, one becomes a licensed actuary and can easily land a very high paying job with six figures yearly salary, in an established company.
                                        It is understandable that the actuarial tests are quiet complex to successfully clear, that is why one can start working as an actuary after successfully clearing 2-3 examinations. But if one cannot clear the rest of the examinations then one can work as an associate actuary or as an actuarial advisor too.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME AN ACTUARY?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes 4 years to successfully secure a Bachelor’s degree in mathematics, economics, finance and many other fields. Then it takes nearly 7 to 8 years to pass the 10 mandatory actuarial examinations. When one has successfully passed the 10 examinations, one finally earns the certification of an actuary. But the best thing is that one can start working as an actuary, just after passing 2-3 examinations. Thus considering this interesting fact, it is safe to assume that one can start working as an actuary in USA in 7-8 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES AN ACTUARY USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The facts and figures provided by the United States Bureau of Labor Statistics state the average yearly salary of an actuary to be around $102,880. Some of the states that offer best wage rates to actuaries are New York, Massachusetts and New Hampshire.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/park-ranger/">Steps to become a Park Ranger</NavLink></li>
                                        <li><NavLink to="/truck-driver/">Steps to become a Truck Driver</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}