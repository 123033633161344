import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Pharmacist.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Pharmacist = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Pharmacist - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PHARMACIST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Pharmacist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Pharmacist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Pharmacist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Pharmacist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Pharmacist Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PHARMACIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Pharmacists are medical personnel that provide the medicines to the patients prescribed by the licensed doctors and also monitor the effects of a particular drug on the patient. A pharmacist carries out a lot of different duties when working on the job. Here is a quick inventory of the duties that a typical pharmacist carries out. A pharmacist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Work hand to hand with doctors in fields of medicines and pharmaceuticals</li>
                                        <br />
                                        <li>Provide patients with medicines prescribed by the doctors</li>
                                        <br />
                                        <li>Maintain records of the patients refilling the medicines to avoid drug abuse</li>
                                        <br />
                                        <li>Guide the patients how to take the medicine as per the instruction of the doctors</li>
                                        <br />
                                        <li>Suggest beverages to take medicines with like water, milk, fresh juices and other beverages</li>
                                        <br />
                                        <li>Warn patients to avoid using alcohol, drugs and cigarettes when one is on medication</li>
                                        <br />
                                        <li>Research to create new medicines</li>
                                        <br />
                                        <li>Test new medicines on a set of specific individuals legally</li>
                                        <br />
                                        <li>Monitor these individuals very closely and make a detailed report of the effects of the new drugs on the people</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PHARMACIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a pharmacist is quiet a challenging task on its own as it takes a lot of intellect, patience , excellent grades as well as finance to pursue a career as a pharmacist. Anyone thinking of becoming a pharmacist has to meet the requirements mentioned below. A pharmacist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 21 years of age</li>
                                        <br />
                                        <li>Must have a high school diploma in Biology, Chemistry, Physics with excellent grades</li>
                                        <br />
                                        <li>Must pass all seven subjects in Pharmacy College Admission Test (PCAT)</li>
                                        <br />
                                        <li>Must have a two year degree featuring subjects like biology, chemistry, physics, anatomy and physiology to get admission in a post-graduate pharmacy school</li>
                                        <br />
                                        <li>Usually have the degree of Pharm-D (Doctor in Pharmacy)</li>
                                        <br />
                                        <li>Have to do internships under the senior pharmacists</li>
                                        <br />
                                        <li>Might also have to do Residency of 1 to 2 years according to the requirements of the specific pharmacy</li>
                                        <br />
                                        <li>Acquire the license from American Pharmacists Association</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PHARMACIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you always dreamt of becoming a pharmacist in the future, then we are here to guide you how to make your dream, your reality. We have compiled a step by step procedure of becoming a pharmacist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Students are required to complete the High School by securing excellent grades in biology, chemistry, physics, anatomy, physiology and statistics to complete the prerequisites. Then one has to pass all seven subjects of Pharmacy College Admission Test (PCAT) and meet the merit for admission in Pharm-D.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Pass The Pharmacy Exams To Acquire License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to have a pharmacy license in order to practice as a pharmacist. After completing the Pharm-D degree, one should prepare to pass the national examinations in order to acquire the license. In order to acquire the license one has to pass the Multistate Pharmacy Jurisprudence Exam as well as the North American Pharmacist Licensure Exam.
                                        One would be issued a license only after one successfully clears all these examinations. In addition to that, the pharmacist who administers intravenous injections might also need another license provided by the American Pharmacists Association.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Join The Job As An Intern</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally acquired the license one should apply for the internship under a senior pharmacists to learn the ropes of the trade. Some Pharmacies also require the pharmacists to do 1 or 2 years of Residency before accepting them as their employ. That is why, one must closely study the guidelines of the pharmacy and fulfill them before applying for the job in order to maximize the chances of getting hired.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PHARMACIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one needs to successfully clear the Pharmacy College Admission Test (PCAT) to get admission into Pharm-D. The time duration needed for the completion of the Pharm-D (Doctor in Pharmacy) is four years. Afterwards, one has to pass the national examinations to acquire the license of pharmacy. Some pharmacists also do internships as well as Residency of one or two years according to the rules of some pharmacies. So we can say, it approximately takes 6 to 8 years to pursue a career as a pharmacist in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PHARMACIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics state that a pharmacist averagely earns around $126,120 per year. California, Vermont, Oregon and Minnesota are the states of United States of America that pays best wage rates to the pharmacists.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}