import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Detective_in_new_york.css';
import img18 from '../images/18.png';
import { NavLink } from 'react-router-dom';

export const Detective_in_new_york = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=new york")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=new york&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Detective in New York - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DETECTIVE IN NEW YORK
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#responsibilities_of_a_detective_in_new_york">Responsibilities Of A Detective In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_detective_in_new_york">Requirements To Become A Detective In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_detective_in_new_york">Steps To Become A Detective In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_does_it_take_to_become_a_detective_in_new_york">How Long Does It Take To Become A Detective In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_a_detective_make_in_new_york">How Much Does Detective Make In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#career_outlook_for_a_detective_in_new_york">Career Outlook For A Detective In New York</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A detective is an essential part of the law enforcing department of New York. A Detective in New York performs a variety of duties which includes crime scene investigation, inspection of evidence and interviewing of suspects etc.
                                        <br />
                                        The essentials to become a Detective in New York in given below
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="responsibilities_of_a_detective_in_new_york">
                                <h2>RESPONSIBILITIES OF A DETECTIVE IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Collection of evidence to make arrest</li>
                                        <br />
                                        <li>Inspection of evidence</li>
                                        <br />
                                        <li>Investigation of crime scenes</li>
                                        <br />
                                        <li>Filing of paperwork</li>
                                        <br />
                                        <li>Interviewing of informants, suspects and victims.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A DETECTIVE IN NEW YORK</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img18} alt="HOW TO BECOME A DETECTIVE IN NEW YORK" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_detective_in_new_york">
                                <h2>REQUIREMENTS TO BECOME A DETECTIVE IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A high-School diploma/GED certificate</li>
                                        <br />
                                        <li>An associate/ bachelor’s degree</li>
                                        <br />
                                        <li>Work experience as a police officer</li>
                                        <br />
                                        <li>Clear background</li>
                                        <br />
                                        <li>US citizenship</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_detective_in_new_york">
                                <h2>STEPS TO BECOME A DETECTIVE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This entails a high-school diploma/GED certificate, US citizenship, valid license of the state of New York and clear background. These requirements make you eligible to get enrolled in a police academy of New York.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get An Associate/ Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In New York, a detective’s post depends on the degree program they hold. The posts may vary as juvenile detective, technical investigation detective or homicide detective on the basis of degree programs in criminology, psychology, sociology or criminal law.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In a police academy of New York, the initial training usually comprises of 6-8 months. Once completed, the additional training starts that’s necessary to become a detective. This training includes investigation of various crime scenes such as financial crimes, fraud, kidnapping and homicide etc.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Take A Police Detective Test/NDIT (National Detective/Investigation Test)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This test is holds 75 questions all based on job related questions such as about law/constitution of New York, crime scene investigation, inspection of alibis and clues and and investigative interviews. This test qualifies you to be a candidate for the position of detective in New York.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Apply For The Position Of Detective</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After passing the test, the applicant is eligible to apply for the position of a detective in New York. The applicant must have fulfilled all the above-mentioned requirements and hold a 3-5 years job experience as a police officer before applying.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_detective_in_new_york">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DETECTIVE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time frame includes 6-8 months of initial training, 1-3 years of additional training and 3-4 years of work experience to become a Detective in New York. The time limit vary accordingly with the candidate and their proficiency.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_detective_make_in_new_york">
                                <h2>HOW MUCH DOES DETECTIVE MAKE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An average detective in New York City makes around $87,278 per year whereas the highest salary recorded for a detective in New York was around $97,070 in 2019, respectively.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="career_outlook_for_a_detective_in_new_york">
                                <h2>CAREER OUTLOOK FOR A DETECTIVE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is foretold that the job opportunities for the detectives in New York may increase by the rate of 4-7% in between 2018-28. However, another prediction states that it may go upto 9% till 2016.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-detective-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>DETECTIVE SCHOOLS IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/new-york/" className="btn btn-dark">Detective Schools in New York</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}