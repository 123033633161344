import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Firefighter.css';
import firefighter from '../images/_firefighter.jpg';
import map from '../images/_usamap.png';
import { NavLink } from 'react-router-dom';
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";

export const Firefighter = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Firefighter - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FIREFIGHTER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Requirements To Become A Firefighter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">States</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Firefighter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Firefighter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Firefighter Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>REQUIREMENTS TO BECOME A FIREFIGHTER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a firefighter is not an easy feat as every person aiming to become a firefighter has to meet a lot of requirements. A firefighter:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 18 or 21 years old till 28 years old for entry level job</li>
                                        <br />
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Must have Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Must have a clean criminal background with no felony, traffic violation, drug abuse charge as it will lead to dismissal</li>
                                        <br />
                                        <li>Get EMT certification</li>
                                        <br />
                                        <li>Get an associate degree or a bachelor’s degree for better job opportunities</li>
                                        <br />
                                        <li>Apply and acquire license for paramedic personnel</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A FIREFIGHTER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={firefighter} alt="HOW TO BECOME A FIREFIGHTER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="l2">
                                <h2>Select STATES</h2>
                            </div>
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    <div className="col-md-7">
                                        <div className="row pt-2 pb-2 pl-3">
                                            {
                                                states != null && states.length > 0 &&
                                                states.map((item, key) => {
                                                    return (
                                                        item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                            <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/firefighter-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                            : <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-5 mt-auto mb-auto">
                                        <img src={map} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A FIREFIGHTER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the step by step guide to help you understand the process of becoming a firefighter in a better way.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Basic Education And Commercial Driver’s License (CDL)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every person aiming to pursue a career as firefighter must have a High School Diploma or GED in order to become a firefighter. It is compulsory for every firefighter to have a Commercial Driver’s License (CDL). It is due to the fact that the need to drive a vehicle could arise very easily in case of an emergency.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Selection Into The Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The candidates have to pass extensive written test, physical test, drug test as well as personal interviews to get admission in the firefighting program. Every candidate for the firefighting job must have strong physical capacity to carry out strenuous exercises of climbing trees, removing debris and rescuing people. In the firefighting academy, the candidates are trained how to put out fires, rescue people from emergency as well as safeguard the public and their property from every sort of disaster.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: EMT Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every candidate aiming to become a firefighter also has to go through extensive background check. Any candidate having a record of felony, traffic violations and drug abuse is immediately dismissed from the program. It is necessary for quite a few states to accept firefighter candidates with an EMT certification as it results in less training during firefighting training programs.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Get An Associate Degree Or A Bachelor’s Degree</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One can increase the chances of rising in the ranks by getting an associate or bachelor’s degree in Public Safety and Security in the related fields. It will also open more job opportunities with better wages and job timings as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5: Become A Licensed Paramedic</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Paramedics are part of the instant response task force that is why they always in great demand. If you have an EMT certificate then you can surely do further studies to become a licensed paramedic. It will make your application stand out from the rest of the applications as many departments are actively looking for licensed paramedics.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FIREFIGHTER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First and foremost, one needs a High School Diploma or GED to apply to be a fighter in United States of America. It takes about six months to two years to complete the EMT training as well as the firefighting training in order to get the certification. It takes two years to complete an associate degree while it takes four years to get the bachelor’s degree in the related fields. Thus it takes about six to eight years to become a firefighter in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A FIREFIGHTER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics provides the mean annual salary of a firefighter to be around $46,870. The pay may vary because experienced firefighters earn at a higher hourly wage than newly hired firefighters. In addition to that, some states like New Jersey, Washington and California pay firefighters at a higher rate than other states.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}