import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Police_officer_in_texas.css';
import Texas from '../images/Texas-2.png';
import { NavLink } from 'react-router-dom';

export const Police_officer_in_texas = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=texas")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Police Officer In Texas - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A POLICE OFFICER IN TEXAS
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Police_Officer_in_Texas">Responsibilities Of A Police Officer In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Police_Officer_in_Texas">Requirements To Become A Police Officer In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_successful_Police_Officer">Steps To Become A Police Officer in Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_takes_to_become_a_Police_Officer_in_Texas">How Long Does It Take To Become A Successful Police Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Police_Officer_make_in_Texas">How Much Does A Police Officer Make In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_For_A_Police_Officer_in_Texas">Career Outlook For A Police Officer Make In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l7">Cities</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l8">Police Officers Schools in Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Police officers in Texas hold an impactful and strong position in the community as they put their lives on the line everyday for the public.
                                        <br />
                                        Now if you’re interested in opting the career of a police officer in Texas, the basic required information is given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Police_Officer_in_Texas">
                                <h2>RESPONSIBILITIES OF A POLICE OFFICER IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Responsible for the Enforcement of laws.</li>
                                        <br />
                                        <li>Make arrests of the violators of laws</li>
                                        <br />
                                        <li>Investigate complaints</li>
                                        <br />
                                        <li>Respond to emergencies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A POLICE OFFICER IN TEXAS</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Texas} alt="HOW TO BECOME A POLICE OFFICER IN TEXAS" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Police_Officer_in_Texas">
                                <h2>REQUIREMENTS TO BECOME A POLICE OFFICER IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>At least the age of 18</li>
                                        <br />
                                        <li>A valid Texas’ driving license</li>
                                        <br />
                                        <li>Clear background and no criminal record</li>
                                        <br />
                                        <li>A high-school diploma/GED certificate</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_successful_Police_Officer">
                                <h2>STEPS TO BECOME A SUCCESSFUL POLICE OFFICER</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To get the job as a police officer in Texas, the applicant first need to fulfill the eligibility criteria, that is to hold at least a high-school diploma, Texas driving license, an age of 18-21 and no criminal record.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass The Law Enforcement Entrance Exam:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Now, if the applicant is eligible enough, the next step is to pass the Law Enforcement Entrance Exam of Texas. This exam includes grammar, reading comprehension, maths and analytical skills. The passing score is mostly 70% or more.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once the applicant pass their LEE exam, they’re supposed to get admission in a police Academy of their choice in Texas. UT police Academy, Houston police academy, OSS Law Enforcement Advisors & OSS Academy and Dallas Police academy are some of the well-known and best academies in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Graduate From The Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After getting admission, the training of becoming a police officer begins. The training comprises of all the necessary education required to become a police officer in Texas, including how to handle crisis, criminal statuses, law etc. The training is of 26 weeks (6 months). Associate degree program of 2-year or a bachelor’s degree can also be obtained if the intended police department of Texas requires it.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Wait For The Final Review And Job Offer</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When the applicant is done with the training, s/he waits for the final review that is given by the higher authorities of the police department of Texas. Once they through the applicant’s Records, training review, physical exam and interview, they’re called to confirm the job offer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_takes_to_become_a_Police_Officer_in_Texas">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A POLICE OFFICER IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        To become a Police Officer in Texas, the training required is of 6 months (26 weeks), if the LEE exam and the final review is included then it takes approximately takes 8-10 months.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Police_Officer_make_in_Texas">
                                <h2>HOW MUCH DOES A POLICE OFFICER MAKE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A Police Officer in Texas earns $61,590 per year approximately. If the job status is above average, as in Detectives and Criminal Investigators, they earn about $77,120 per year in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_For_A_Police_Officer_in_Texas">
                                <h2>CAREER OUTLOOK FOR A POLICE OFFICER IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Employment opportunities, in Texas, will increase by 5-7% for Police Officers in between 2018-28, as per analysis of bureau of labor statistics.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-police-officer-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>POLICE OFFICER SCHOOLS IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/texas/" className="btn btn-dark">Police Officer Schools in Texas</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}