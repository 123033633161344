import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";

import './Midwife_in_other_states.css';
import _midwife from '../images/_midwife.jpg';
import midwife1 from '../images/midwife1.PNG';
import midwife2 from '../images/midwife2.PNG';
import midwife3 from '../images/midwife3.PNG';

export const Midwife_in_other_states = () => {
    window.location.href = '/';
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const parameter = useParams();
    parameter.parameter = parameter.parameter.replace('how-to-become-a-midwife-in-', ' ').replace(/-/g, ' ').trim();
    ////console.log(parameter.parameter);

    const [count, setCount] = useState(0);
    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    const [rltdcities, setrltdcities] = useState([]);
    const [statecitytable, setstatecitytable] = useState([]);
    useEffect(() => {
        if (parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
            || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
            || parameter.parameter === "district of columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
            || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
            || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
            || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
            || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
            || parameter.parameter === "nevada" || parameter.parameter === "new hampshire" || parameter.parameter === "new jersey" || parameter.parameter === "new mexico"
            || parameter.parameter === "new york" || parameter.parameter === "north carolina" || parameter.parameter === "north dakota" || parameter.parameter === "ohio"
            || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode island"
            || parameter.parameter === "south carolina" || parameter.parameter === "south dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
            || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
            || parameter.parameter === "washington" || parameter.parameter === "west virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming") {
            axios
                .get(Base_API_URL + "/api/Home/GetStatesByCity?state=" + parameter.parameter)
                .then(response => {
                    setcities(response.data)
                    setProgress(100)
                })
                .catch(err => {
                    ////console.log(err);
                })
        }
        else {
            setProgress(50)
            window.scrollTo(0, 0)
            axios
                .get(Base_API_URL + "/api/Home/GetRelatedCities?City=" + parameter.parameter.slice(0, -4))
                .then(response => {
                    setrltdcities(response.data)
                    setProgress(100)
                })
                .catch(err => {
                    ////console.log(err);
                })

            axios
                .get(Base_API_URL + "/api/Home/GetStateCityTable?City=" + parameter.parameter.slice(0, -4))
                .then(response => setstatecitytable(response.data))
                .catch(err => {
                    ////console.log(err);
                })
        }
    }, [count]);
    ////console.log(cities);
    ////console.log(rltdcities);
    ////console.log(statecitytable);


    function endCaseWords(input) {
        return input.toLowerCase().split(" ").map(function (item) {
            return item.slice(0, -1) + item.slice(-1).toUpperCase();
        }).join(" ");
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    if (parameter.parameter == "alabama" || parameter.parameter == "alaska" || parameter.parameter == "arizona" || parameter.parameter == "arkansas"
        || parameter.parameter == "california" || parameter.parameter == "colorado" || parameter.parameter == "connecticut" || parameter.parameter == "delaware"
        || parameter.parameter == "district of columbia" || parameter.parameter == "florida" || parameter.parameter == "georgia" || parameter.parameter == "hawaii"
        || parameter.parameter == "idaho" || parameter.parameter == "illinois" || parameter.parameter == "indiana" || parameter.parameter == "iowa"
        || parameter.parameter == "kansas" || parameter.parameter == "kentucky" || parameter.parameter == "louisiana" || parameter.parameter == "maine"
        || parameter.parameter == "maryland" || parameter.parameter == "massachusetts" || parameter.parameter == "michigan" || parameter.parameter == "minnesota"
        || parameter.parameter == "mississippi" || parameter.parameter == "missouri" || parameter.parameter == "montana" || parameter.parameter == "nebraska"
        || parameter.parameter == "nevada" || parameter.parameter == "new hampshire" || parameter.parameter == "new jersey" || parameter.parameter == "new mexico"
        || parameter.parameter == "new york" || parameter.parameter == "north carolina" || parameter.parameter == "north dakota" || parameter.parameter == "ohio"
        || parameter.parameter == "oklahoma" || parameter.parameter == "oregon" || parameter.parameter == "pennsylvania" || parameter.parameter == "rhode island"
        || parameter.parameter == "south carolina" || parameter.parameter == "south dakota" || parameter.parameter == "tel" || parameter.parameter == "tennessee"
        || parameter.parameter == "texas" || parameter.parameter == "utah" || parameter.parameter == "vermont" || parameter.parameter == "virginia"
        || parameter.parameter == "washington" || parameter.parameter == "west virginia" || parameter.parameter == "wisconsin" || parameter.parameter == "wyoming") {
        rltdcities.length = 0;
        statecitytable.length = 0;
    }
    else {
        cities.length = 0;
        if (!parameter.parameter.includes(",")) {
            parameter.parameter = parameter.parameter.slice(0, -3) + ", " + parameter.parameter.slice(-2);
            //parameter.parameter = endCaseWords(parameter.parameter);
        }
    }

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=" + parameter.parameter.toLowerCase() + "&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide </title>
                    <meta name='description' content={`To become a Midwife in ` + parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); }) + `, one must first get their certification.  Find ` + parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); }) + ` Midwifery schools and programs here.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MIDWIFE IN {parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">How to Become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">How Long Does it take to Become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">What is Average Salary of Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Benefits</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Cities</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l7">Midwife Schools in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>HOW TO BECOME A MIDWIFE IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>To become a midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} meet the minimum requirements</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} get a bachelor’s degree in Nursing</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} GED in science subject</li>
                                        <br />
                                        <li>To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} get a high school diploma</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} monitoring of pregnancy</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} assistance during childbirth</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A MIDWIFE IN {parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={_midwife} alt={'HOW TO BECOME A MIDWIFE IN ' + parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A MIDWIFE IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Following are the steps to become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h4>Personal Requirements:</h4>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must be at least 21 Years old.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} strong observational and communication skills.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} hold a Midwifery license.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} able to handle emergencies.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} able to work under pressure.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 6</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} able to take responsibility
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 7</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} able to cope up with stress conditions
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="l2">
                                <h4>Educational requirements:</h4>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} candidates must have a bachelor’s degree in Nursing.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must have completed the midwifery program.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} candidates must have a high school Diploma or GED In science subject like Biology and Chemistry.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>HOW LONG IT TAKES TO BECOME A MIDWIFE IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        To become a midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} the time-limit is usually around 8 years. Midwife {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} includes the time required for bachelor’s degree (4-years), and Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} midwifery program of three years in an accredited college or university. Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} one has to apply for the license of certified Nurse Midwife.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>WHAT IS THE AVERAGE SALARY OF MIDWIFE IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average annual salary for Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} is around $116,181 per year. Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} annual salaries increase or decrease with the number of deliveries administered or assisted, working hours and experience.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <img src={midwife1} style={{ width: '100%', height: '350px' }} />
                            <br /><br />
                            <div className="row heading_area">
                                <h5>MIDWIFE in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Salary Potential</h5>
                            </div>
                            <img src={midwife2} style={{ width: '100%' }} />
                            <br /><br />
                            <div className="row heading_area">
                                <h5>MIDWIFE in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Salary Comparison By Gender</h5>
                            </div>
                            <img src={midwife3} style={{ width: '100%' }} />
                            <br /><br />
                            <div className="row heading_area" id="l5">
                                <h2>MIDWIFE IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} BENEFITS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Competitive pay</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Dental insurance</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Health insurance</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Life insurance</li>
                                        <br />
                                        <li>Midwife in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Vision insurance</li>
                                    </ul>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink onClick={() => setCount((c) => c + 1)} to={`/how-to-become-a-midwife-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l7">
                                            <h2>MIDWIFE SCHOOLS IN {parameter.parameter.toUpperCase()}</h2>
                                        </div>
                                        <div className="container">
                                            <div className="row btn_related">
                                                <div className="col-md-6">
                                                    <NavLink to={`/` + parameter.parameter.replace(/ /g, '-').toLowerCase() + `/`} className="btn btn-dark">MIDWIFE Schools in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table table-striped table-hover table-bordered" id="unibystate">
                                            <thead>
                                                <tr>
                                                    <th>University Name</th>
                                                    <th>Rank</th>
                                                    <th>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    unibystate.length > 0 ?
                                                        unibystate.map((u =>
                                                            <tr>
                                                                <td>{u.uniName}</td>
                                                                <td>{u.rank}</td>
                                                                <td>{u.address}</td>
                                                            </tr>
                                                        ))
                                                        : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    : ""
                            }
                            {
                                rltdcities.length > 0 && rltdcities != null ?
                                    <div>
                                        <div className="row heading_area">
                                            <h2>Cities related to {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    rltdcities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink onClick={() => setCount((c) => c + 1)} to={`/how-to-become-a-midwife-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                statecitytable.length > 0 && statecitytable != null ?
                                    <div>
                                        <div className="row heading_area">
                                            <h2>About {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                                        </div>
                                        <table className="table table-striped table-hover table-bordered table-responsive-md" id="aboutcity">
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Latitude</th>
                                                    <th>Longitude</th>
                                                    <th>Population</th>
                                                    <th>Military</th>
                                                    <th>Incorporated</th>
                                                    <th>Time Zone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    statecitytable.map((x, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{x.ranking}</td>
                                                                <td>{x.lat}</td>
                                                                <td>{x.lng}</td>
                                                                <td>{x.population}</td>
                                                                <td>{x.military}</td>
                                                                <td>{x.incorporated}</td>
                                                                <td>{x.timezone.replace('_', ' ')}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                </div >
                <br />
                <br />
            </div>
        </>
    )
}