import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Court_reporter.css';
import img10 from '../images/10.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Court_reporter = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Court Reporter - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A COURT REPORTER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#duties_of_a_court_reporter">Duties Of A Court Reporter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_court_reporter">Requirements To Become A Court Reporter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_court_reporter">Steps To Become A Court Reporter?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_does_it_take_to_become_a_court_reporter">How Long Does It Take To Become A Court Reporter</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_a_court_reporter_usually_earn">How Much Does A Court Reporter Usually Earn?</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_court_reporter">
                                <h2>DUTIES OF A COURT REPORTER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A court reporter plays an important role in keeping a record of all the things that transpire in the court and the cases that are being trialed in the court. That is why, the court reporters have earned themselves the title of “Guardians of the Record”.  Here is a quick glimpse of all the major duties that a court reporter usually carries out. A court reporter:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Record everything that was said and presented in the court</li>
                                        <br />
                                        <li>Capture the court status quo in pictures as well as writing</li>
                                        <br />
                                        <li>Record the verbatim scripts that both, defendants and prosecutors, share in the court</li>
                                        <br />
                                        <li>Make an inventory of the points raised in the court</li>
                                        <br />
                                        <li>Make an effort to record everything about the court case assigned to him</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A COURT REPORTER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img10} alt="STEPS TO BECOME A COURT REPORTER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_court_reporter">
                                <h2>REQUIREMENTS TO BECOME A COURT REPORTER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The career of a court reporter is quiet exciting as well as thrilling. Hence, if you want to become a court reporter then here is a list of requirements to become a court reporter. A court reporter must:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Be at least 18 years of age</li>
                                        <br />
                                        <li>Have a High School Diploma or the degree equivalent to it</li>
                                        <br />
                                        <li>Have a degree related to court reporting or a degree that covers stenography</li>
                                        <br />
                                        <li>Have the license and certification of the court reporter</li>
                                        <br />
                                        <li>Be impartial and record only the truth</li>
                                        <br />
                                        <li>Have excellent command over English language</li>
                                        <br />
                                        <li>Have the stenography typing speed of at least 180 wpm for literary shorthand, 200 wpm for jury instruction</li>
                                        <br />
                                        <li>Have the stenography typing speed of 225 wpm for the testimony as well as questions and answers</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_court_reporter">
                                <h2>STEPS TO BECOME A COURT REPORTER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is a step by step guide to help you understand the process of becoming a court reporter.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important for a court reporter to either have a diploma in court reporting or an associate degree in court reporting to pursue a career as a court reporter. Some states demands an associate degree specifically while some states only want the court reporter to have the diploma. One can enroll in the college to get the associate degree in court reporting or stenography, or in a school to get a court reporting diploma after completing High School or a degree equivalent to it. Even after getting proper degree, most of the court reporters are encouraged to take additional classes and attend workshops about English grammar and punctuations. Court reporters also show interest in attention transcription workshops and other related events to polish their skills.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get License And The Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The process of acquiring the license and certification is different for different states.  Hence, after securing the associate degree or diploma in court reporting or stenography one must review the state laws and plan the next step. Some states require an examination where the skills of the court reporter are assessed and the stenography typing rate is calculated.
                                        <br />
                                        In order to get the license, the court reporters may have to become the notary public and to pass the written examination and the stenography speed tests. The court reporters can get their certification from a nationally recognized institution as well like National Court Reporting Association (NCRA) and National Verbatim Reporters Association (NVRA).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Join A Court House Or Work As A Freelance Court Reporter</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The court reporters usually work under government in the government courts to record the trials. Some court reporters also work with some court reporting agencies as well. A few reporters work as freelance reporters which may work part time or may draw a proper contract with the client for court reporting a particular case.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_court_reporter">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A COURT REPORTER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        You enroll in an associate degree program after completing the High School or any degree equivalent to it. The time duration of completing the associate degree in court reporting is about two years. Then one has to pass the court reporting test as well if it is included in the rules of state.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_court_reporter_usually_earn">
                                <h2>HOW MUCH DOES A COURT REPORTER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The data provided by the United States Bureau of Labor Statistics state that the average yearly income of a court reporter is about $57,150. Some states like New York pay court reporters hefty amounts of money because of the demand of the court reporters in that area. Some court reporters even earn in six figures per annum due to their experience and skill in court reporting.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}