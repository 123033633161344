import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Police_officer_in_pennsylvania.css';
import Pennsylvania from '../images/Pennsylvania-2.png';
import { NavLink } from 'react-router-dom';

export const Police_officer_in_pennsylvania = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=pennsylvania")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Police Officer In Pennsylvania - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A POLICE OFFICER IN PENNSYLVANIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Police_Officer_in_Pennsylvania">Responsibilities Of A Police Officer In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Police_Officer_in_Pennsylvania">Requirements To Become A Police Officer In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Police_Officer_in_Pennsylvania">Steps To Become A Police Officer in Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_A_Police_Officer_in_Pennsylvania">How Long Does It Take To Become A Police Officer In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Police_Officer_make_in_Pennsylvania">How Much Does A Police Officer Make In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_For_Police_Officers_in_Pennsylvania">Career Outlook For A Police Officer Make In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l7">Cities</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l8">Police Officers Schools in Pennsylvania</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Police Officers are responsible for enforcing law in the state, prevent the crimes from happening, protection of liberty and properties of the civilians of Pennsylvania.
                                        <br />
                                        If you want to become a Police Officer in Pennsylvania, then the essentials that you should know are given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Police_Officer_in_Pennsylvania">
                                <h2>RESPONSIBILITIES OF A POLICE OFFICER IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Provide security to the citizens</li>
                                        <br />
                                        <li>Ensure a healthy and safe environment</li>
                                        <br />
                                        <li>Give social support </li>
                                        <br />
                                        <li>Enforcement of laws</li>
                                        <br />
                                        <li>Respond to emergencies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A POLICE OFFICER IN PENNSYLVANIA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Pennsylvania} alt="HOW TO BECOME A POLICE OFFICER IN PENNSYLVANIA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Police_Officer_in_Pennsylvania">
                                <h2>REQUIREMENTS TO BECOME A POLICE OFFICER IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A high-School diploma/ GED certificate</li>
                                        <br />
                                        <li>A Minimum age of 18</li>
                                        <br />
                                        <li>No criminal record/ clear background</li>
                                        <br />
                                        <li>Valid Pennsylvania’s driving license</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Police_Officer_in_Pennsylvania">
                                <h2>STEPS TO BECOME A POLICE OFFICER IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The first thing that you need to do is to fulfill the criteria for being an applicant in Pennsylvania. This Eligibility Criteria may include at least a high-school diploma, an age between 18-21, no criminal record and Pennsylvania’s driving license.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass The Law Enforcement Entrance Exam:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Next thing that an applicant should do after being eligible is to pass the LEE exam of Pennsylvania. This exam comprises of comprehension reading, analytical skills, grammar, maths and good communication skills. The applicant should score 70% or above to pass the exam.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once the applicant pass their exam, they’re supposed to find a police academy of their choice in Pennsylvania. Allentown Police Academy, Allegheny County Police Academy and Mansfield Police Academy are a few of those that offer training for every post of police department in Pennsylvania.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Graduate From The Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When they’re enrolled in a police academy, their training to be a police officer in Pennsylvania starts. The training is usually of 26 weeks (6 months). If they want a post of higher rank or the one that require specific educational diploma, like Crime Scene Investigator or Detectives, they can get a 2-year Associate Degree or a Bachelor’s degree.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Wait For The Final Review And Job Offer</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After they’re graduated from the police academy. They are told to wait for the final review from the higher authorities of the police department of Pennsylvania as they go through their interview, training records and physical exam etc. After that they’re called for the firm job offer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_A_Police_Officer_in_Pennsylvania">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A POLICE OFFICER IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The training to be a Police Officer in Pennsylvania takes 26 weeks (6 months), if the time taken for the clearance of LEE exam and the final review is included, then it takes approximately 8-10 months to become a Police Officer in Pennsylvania.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Police_Officer_make_in_Pennsylvania">
                                <h2>HOW MUCH DOES A POLICE OFFICER MAKE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The salary for the state police officer of Pennsylvania ranges in between $60,140 to $71,653 per year.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_For_Police_Officers_in_Pennsylvania">
                                <h2>CAREER OUTLOOK FOR A POLICE OFFICER IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is predicted that the employment rate in the police department of Pennsylvania will increase by 5-7% in between 2018-28.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-police-officer-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>POLICE OFFICER SCHOOLS IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/pennsylvania/" className="btn btn-dark">Police Officer Schools in Pennsylvania</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}