import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Detective_in_california.css';
import img15 from '../images/15.png';
import { NavLink } from 'react-router-dom';

export const Detective_in_california = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=california")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Detective in California - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DETECTIVE IN CALIFORNIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#responsibilities_of_a_detective_in_california">Responsibilities Of A Detective In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_detective_in_California">Requirements To Become A Detective In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_detective_in_california">Steps To Become A Detective In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_does_it_take_to_become_a_detective_in_california">How Long Does It Take To Become A Detective In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_a_detective_make_in_california">How Much Does Detective Make In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#career_outlook_for_a_detective_in_california">Career Outlook For A Detective In California</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A detective in California is a law enforcement official who is responsible for the collection of evidence, inspection of crime scenes and interviews of suspects, witnesses and informants.
                                        <br />
                                        The necessary details to become a Detective in California is given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="responsibilities_of_a_detective_in_california">
                                <h2>RESPONSIBILITIES OF A DETECTIVE IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Investigation of crime scenes</li>
                                        <br />
                                        <li>Collect and use evidence to make arrest</li>
                                        <br />
                                        <li>Interview suspects, victims and informants</li>
                                        <br />
                                        <li>Investigate alibis, time frames and clues</li>
                                        <br />
                                        <li>File paperwork</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A DETECTIVE IN CALIFORNIA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img15} alt="HOW TO BECOME A DETECTIVE IN CALIFORNIA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_detective_in_California">
                                <h2>REQUIREMENTS TO BECOME A DETECTIVE IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>US citizenship</li>
                                        <br />
                                        <li>A high-school diploma/GED certificate</li>
                                        <br />
                                        <li>Associate or Bachelor’s degree in criminology, psychology and law.</li>
                                        <br />
                                        <li>At least 4 years of experience as a police officer</li>
                                        <br />
                                        <li>Training to become a detective</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_detective_in_california">
                                <h2>STEPS TO BECOME A DETECTIVE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The minimum eligibility criteria includes citizenship of US, no criminal record, a valid driving license of California and a high-school/GED diploma. These requirements are the primary that a candidate must hold if s/he wants to become a detective in California.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get An Associate/ Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This is mandatory to get a position of a detective in the police department in California. The degree may be in criminology, criminal law, psychology and sociology. It determines your job position such as juvenile detective, technical investigation and forensic detective.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once enrolled, your police academy training, the investigating training may take 6-8 months. After that the additional training starts which includes investigation of different crimes such as homicide, fraud, kidnapping and financial crimes. The time limit may vary in between 1-3 years.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Take A Police Detective Test/NDIT (National Detective/Investigation Test)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It consists of 75 questions that tests the candidate’s ability investigate crimes, handling of court cases and investigative interviews. The applicant is eligible after a few years of experience as a police officer in California.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Apply For A Detective Position</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After fulfilling all the necessary requirements, you may apply for the position of a detective in California. The applicant must have work experience of nearly 4 years with a clear record.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_detective_in_california">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DETECTIVE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time includes 6-8 months of training, 1-3 years of investigative training and 3-5 years of work experience as a police officer in California before you are eligible for the post of a Detective.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_detective_make_in_california">
                                <h2>HOW MUCH DOES DETECTIVE MAKE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        According to California Employment Development Department, the average salary for a Detective is recorded $97,260 per year on the division of $46.76 per hour.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="career_outlook_for_a_detective_in_california">
                                <h2>CAREER OUTLOOK FOR A DETECTIVE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is predicted by the bureau of labor statistics that the job opportunities may increase at the rate of 5% during 2016-26. Another prediction states that it may increase by 7-9% during 2018-28.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-detective-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>DETECTIVE SCHOOLS IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <a href="/california/" className="btn btn-dark">Detective Schools in California</a>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}