import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Loan_officer.css';
import img12 from '../images/12.png';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Loan_officer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Loan Officer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A LOAN OFFICER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Loan Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Loan Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Loan Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Loan Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Loan Officer Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A LOAN OFFICER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The loan officer communicates with people who wants to acquire loans by acting as the official representative for any sort of finance company like a bank. A loan officer spend his whole day attending meetings, filing paperwork and other duties that he is bound to do. Here is a list of some of the general duties that loan officers in US usually carries out. A loan officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Personally have a meeting with the person or company that require a loan</li>
                                        <br />
                                        <li>Find the reasons behind taking the loan</li>
                                        <br />
                                        <li>Acquire the details about the financial situation of the loan applicant</li>
                                        <br />
                                        <li>Decide whether the clients are eligible for the loan or not</li>
                                        <br />
                                        <li>Discuss installment rate as well as the duration of the payment plan with the client before starting the process</li>
                                        <br />
                                        <li>Acquire necessary information required to fill the form of loan application</li>
                                        <br />
                                        <li>Find ways to facilitate the clients by speeding up the process of loan approval</li>
                                        <br />
                                        <li>Provide the clients with a loan contract and brief them on the terms and conditions written in the contract</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A LOAN OFFICER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img12} alt="STEPS TO BECOME A LOAN OFFICER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A LOAN OFFICER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There is a lot demand of loan officers in America nowadays thus one has to meet a lot of requirements in order to secure the career of a loan officer. Here are some general requirements for every candidate to meet and work as a loan officer in US. A loan officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a Bachelor’s degree in finance, economics or in other related fields with additional finance credit hours</li>
                                        <br />
                                        <li>Must be well versed in the loan laws at both state as well as national level</li>
                                        <br />
                                        <li>Must know how to operate computers to exchange official e-mails and file data digitally</li>
                                        <br />
                                        <li>Must be well versed in application of banking software</li>
                                        <br />
                                        <li>Must be understanding and patient with the clients</li>
                                        <br />
                                        <li>Must have at least 3 years of infield experience as a loan officer for license application</li>
                                        <br />
                                        <li>Should obtain license after passing the examination</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A LOAN OFFICER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you have enough skills and intellect to handle a challenging job like a loan officer then here is the step by step guide to help you plan out your life in order to secure your dream job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        It is compulsory for every candidate to have a Bachelor’s degree primarily in banking and finance. One could also pursue a Bachelor’s degree in business or economics but with extra credits for finance. Some companies require MBA as well for the high ranking loan officer position.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Gain Experience</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the education, one should apply for the entry level job in the field and receive on the job training. That training is extremely important because this training imparts practical knowledge to the loan officer that one could not find in any degree. One should work as a loan officer for 2-5 years to gain enough in-field experience to take the licensing exam.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Secure Mortgage Loan Originator (MLO) License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        In order to obtain the mortgage loan originator (MLO) license one first of all needs to do a really short course of just 20 credit hours and successfully pass the written exam. Then one has to go through a criminal background check as well as financial or credit background check. After passing all these tests one finally acquires the mortgage loan originator (MLO) license.
                                        <br />
                                        Furthermore, one could also become certified through prestigious institutions like Mortgage Bankers Association (MBA) and American Bankers Association (ABA). That will open gates of better opportunies for the officer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A LOAN OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First and foremost, Bachelor’s in finance, economics and related fields usually require 4 years. Then one have to complete the pre licensing requirements which also include at least 2-5 years in field experience as a loan officer. After that one has to give the examination to secure the license of mortgage loan originator (MLO). Some companies also require the loan officers to have a MBA as well which might take 2 more years. So, roughly one can require around 6 to 9 years become a licensed loan officer in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A LOAN OFFICER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A loan officer in America earns nearly $ 63,040 per year as per the statistics available on the official website of United Sates Bureau of Labor Statistics. Kansas, Texas and Nebraska are the American states that provide best financial incentives to the loan officers working in the state.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}