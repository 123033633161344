import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Forensic_scientist_in_pennsylvania.css';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist_in_pennsylvania = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=pennsylvania")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=pennsylvania&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist In Pennsylvania - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST IN PENNSYLVANIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Forensic_Scientist_in_Pennsylvania">Responsibilities Of A Forensic Scientist In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Forensic_Scientist_in_Pennsylvania">Requirements To Become A Forensic Scientist In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_Scientist_in_Pennsylvania">Steps To Become A Forensic Scientist In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Forensic_Scientist_in_Pennsylvania">How Long Does It Take To Become A Forensic Scientist In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Forensic_Scientist_make_in_Pennsylvania">How Much Does A Forensic Scientist Make In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_For_the_Forensic_Scientists_in_Pennsylvania">Career Outlook For Forensic Scientists In Pennsylvania</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A forensic scientist in Pennsylvania is an official who deals with the critical analysis and interpretation of physical evidence collected during crime scene investigation. They identify, collect, analyse and preserve the aforementioned evidence. Different forensic scientists are specialised in different tasks, some are designated for the collection of evidence from the crime scene whereas some are responsible for the analysis in the lab.
                                        <br />
                                        The essentials that you must know before opting this career as your profession are given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Forensic_Scientist_in_Pennsylvania">
                                <h2>RESPONSIBILITIES OF A FORENSIC SCIENTIST IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Identification and Collection of physical evidence</li>
                                        <br />
                                        <li>Analysis of the collected evidence</li>
                                        <br />
                                        <li>Preservation of the evidence</li>
                                        <br />
                                        <li>Communication with law enforcement</li>
                                        <br />
                                        <li>To make legal statements for court cases</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Forensic_Scientist_in_Pennsylvania">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Bachelor’s degree program in science subject</li>
                                        <br />
                                        <li>Optional Associate/Master’s degree program</li>
                                        <br />
                                        <li>Valid Pennsylvania’s driving license</li>
                                        <br />
                                        <li>US citizenship</li>
                                        <br />
                                        <li>Clear background and drug test</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_Scientist_in_Pennsylvania">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a forensic scientist, the candidate must hold a bachelor’s degree program in subjects such as Forensic science, anthropology, chemistry and biology. In Pennsylvania, the institutes offering such degree programs also aids the candidates in building and enhancing skills that are essential for this career such as competence, critical thinking, detail-orientation and communication skills.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get Experience For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once graduated, the candidate is ought to do an internship in a suitable place to achieve professional expertise and guidance. In Pennsylvania, the institute’s placement offices usually helps in finding such places for the candidates.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Consider A Master’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Although a master’s degree program isn’t mandatory yet it is very beneficial and highly recommended as many positions in the forensic department of Pennsylvania requires an associate/ master’s degree program. The candidate can opt subject like toxicology, criminology and material analysis.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Find A Job In Pennsylvania</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once the educational requirements are fulfilled, the candidate is eligible to apply for the job in New York where ones complete the initial screening before getting appointed which includes an interview, background check and a drug test. Candidates can find jobs in New York’s state or private labs as well as in the law enforcing department.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Consider Getting Certified</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Getting a certificate can be highly advantageous for the candidate as a certified forensic officer is always preferred the most for any available vacancy. There are many institutes in Pennsylvania that holds the authority to issue a certificate to a forensic scientist after the candidate holds experience of a few experience. The time limit for experience can be varied such as some certificates requires 3 years or more of professional experience before the candidate can give the certification exam.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Forensic_Scientist_in_Pennsylvania">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If the time required to complete educational requirements are included then it takes 4-years for bachelor’s degree, 2-3 years for Associate/master’s degree and 3-4 months to complete the internship. After that it might take around 5-6 months to get appointed on a firm job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Forensic_Scientist_make_in_Pennsylvania">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST MAKE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An average forensic scientist makes around $56,523 an year in Pennsylvania which is almost equal to the average yearly salary of a forensic scientist in States that is $55,930, respectively.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_For_the_Forensic_Scientists_in_Pennsylvania">
                                <h2>CAREER OUTLOOK FOR A FORENSIC SCIENTIST IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is predicted that the job opportunities may increase around 19-22% in between 2018-28 for the forensic scientists of Pennsylvania. Another prediction states that around 2,400 new jobs for forensic scientists would be introduced in next years in Pennsylvania.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-forensic-scientist-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FORENSIC SCIENTIST SCHOOLS IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/pennsylvania/" className="btn btn-dark">Forensic Scientists Schools in Pennsylvania</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}