import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Financial_analyst.css';
import financial_aanalyst from '../images/financial-aanalyst.png';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Financial_analyst = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Financial Analyst - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FINANCIAL ANALYST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Financial Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Financial Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Financial Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Financial Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Financial Analyst Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A FINANCIAL ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The financial analyst is a financial expert who critically evaluates all the financial transactions made, profits generated, and cost of the venture as well as suggest ways to minimize losses in order to earn more profits. Financial analysts are working long hours at the job in order to dutifully carry out their numerous duties. A financial analyst:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Analyses all the financial data of the company</li>
                                        <br />
                                        <li>Have to devise new plans and financial strategies for the company</li>
                                        <br />
                                        <li>Select the current trends in the market that will be most beneficial for the company</li>
                                        <br />
                                        <li>Predict new trends in the stock markets</li>
                                        <br />
                                        <li>Find out the cost of a new business endeavor</li>
                                        <br />
                                        <li>Calculate chances of the success or failure of any new venture</li>
                                        <br />
                                        <li>Try to find out the possible profits new business venture will generate</li>
                                        <br />
                                        <li>Submit detailed reports about their findings and research analysis</li>
                                        <br />
                                        <li>Give presentations about their analysis and add visual representation of the data as well in the form of pie charts, histograms or bar charts</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A FINANCIAL ANALYST</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={financial_aanalyst} alt="STEPS TO BECOME A FINANCIAL ANALYST" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A FINANCIAL ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you are sharp enough to tackle the convoluted financial matters then you should definitely aim to become a financial analyst. You need to meet the requirements mentioned below to become eligible for the job. A financial analyst:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a Bachelor’s degree in finance from an accredited college</li>
                                        <br />
                                        <li>Must have an MBA with major credits in finance and investment analysis for the high end designations</li>
                                        <br />
                                        <li>Must have at least 4 years in field experience before applying for the certification</li>
                                        <br />
                                        <li>Need to pass three examinations for the certification of financial analyst</li>
                                        <br />
                                        <li>Should be a Chartered Financial Analyst (CFA) by securing the certification from Chartered Financial Analyst (CFA) Institute</li>
                                        <br />
                                        <li>Should have acquired the license of financial analyst through Financial Industry Regulatory Authority</li>
                                        <br />
                                        <li>Need to have a sponsorship provided by the employer in order to acquire the license</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A FINANCIAL ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have a passion for becoming a financial analyst then for your aid we have compiled a step by step guide outlining the process of becoming a financial analyst.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to have a Bachelor’s degree in finance or any other business related field with credit hours in finance, statistics or investment analysis for entry level job. But it is advisable to have a MBA with aforementioned credits if one wants to work as a senior or executive financial analyst.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Certification Process</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the studies one should apply for the job and finally start working as a new financial analyst in the entry level setup. One will receive on the job training and will also learn how to use different analysis software to make reports and presentations. One needs to have at least 4 years of experience in order to apply for the certification process. One has to pass three examinations in order to become a Chartered Financial Analyst (CFA).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Acquire The License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to be on the job working as a financial analyst if one wants to acquire the license of financial analyst provided by the Chartered Financial Analyst (CFA) Institute. It is because of the fact that the financial analyst requires sponsorship provided by the employer to become eligible for the license. The license will help one to sell the company’s stocks as well as facilitate clients by providing legal advice.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FINANCIAL ANALYST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Every financial analyst first secures 4 years Bachelor’s degree then one needs to acquire at least 4 year of in field experience to become eligible to apply for the certification. The certification process requires the candidates to pass three examinations which can easily take a year or more. In addition to that, some candidates also pursue a MBA of two years in order to acquire higher positions. Some might even go for obtaining license of financial analyst in order to work as a executive financial analyst in the company. So, we can conclude one needs to invest 8 to 10 years in order to become a financial analyst.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A FINANCIAL ANALYST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics has mentioned the annual average salary of a general financial analyst to be around $ 85,660 on their official website. One should look forward to working as a financial analyst in California, Montana and District of Columbia in order to reap the financial benefits provided by the states.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}