import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Midwife.css';
import midwife from '../images/midwife.png';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Midwife = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Midwife - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`What is a Nurse Midwife? - A nurse midwife is an advanced practice registered nurse (APRN) with a master’s degree at minimum and expertise in women’s health and obstetric medicine. They are state-licensed and certified by the American Midwifery Certification Board and have independent authority to practice midwifery everywhere from in-home to hospitals.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MIDWIFE
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Midwife</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Midwife</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Midwife</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long It Takes To Become A Police Officer In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Midwife Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A MIDWIFE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The midwife or a Certified Nurse Midwife (CNM) is a professional nurse who has specialized in gynecology and parent and infant care. A midwife has to carry out a plethora of different duties while she is doing her job. Some of the basic duties that every midwife is supposed to carry out every day on the job are mentioned below. A midwife:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Deliver babies in normal delivery</li>
                                        <br />
                                        <li>Take care of mother and baby in case of a labor emergency</li>
                                        <br />
                                        <li>Assist doctors and the surgeons in case of caesarean operation</li>
                                        <br />
                                        <li>Stitch and repair cuts of labor</li>
                                        <br />
                                        <li>Discuss health problems regarding labor with patients</li>
                                        <br />
                                        <li>Guide the patients regarding the correct way to take care of their stitches especially in case of caesarean</li>
                                        <br />
                                        <li>Monitor the health issues and provide diagnosis</li>
                                        <br />
                                        <li>Educate patients about various gynecology related problems</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A MIDWIFE</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={midwife} alt="STEPS TO BECOME A MIDWIFE" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A MIDWIFE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Like every other specialized field, becoming a midwife is no smooth sailing either as every candidate aiming to become a midwife has to meet a lot of different requirements. A midwife:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 21 years of age</li>
                                        <br />
                                        <li>Must have secured a High School Diploma or GED in science subjects like Biology and Chemistry</li>
                                        <br />
                                        <li>Must have a bachelor’s degree in Nursing</li>
                                        <br />
                                        <li>Must have completed the midwifery program</li>
                                        <br />
                                        <li>Must secure the Certified Nurse Midwife (CNM) license after fulfilling the work experience and passing the test</li>
                                        <br />
                                        <li>Must be friendly with the patients especially anxious mother’s undergoing labor</li>
                                        <br />
                                        <li>Must be able to remain calm in pressing situation and try to minimize the damage</li>
                                        <br />
                                        <li>Must be quick on feet and can handle standing up straight for a few hours</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 &&
                                        states.map((item, key) => {
                                            return (
                                                item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <NavLink to={`/how-to-become-a-midwife-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`}>{item.state}</NavLink>
                                                    </div>
                                                    : <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state}</NavLink>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A MIDWIFE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you can imagine yourself working as a midwife in the future then let us be your mentor. We have invested our energies to create a step by step guide of becoming a midwife in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the High School with science subjects like Biology and Chemistry one should enroll in an accredited college to get bachelor’s degree in nursing. It takes four years to acquire the bachelor’s degree in nursing and related fields.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Becoming A Registered Nurse</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After finally getting the degree, one should acquire the license of a registered nurse by successfully passing the National Council Licensure Examination (NCLEX-RN). After that, one should either pursue a Master’s degree in nursing or work as a nurse for a year to get the working experience.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Enroll In A Midwifery School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally acquired the registered nurse license one has to enroll in a midwifery program to get the Master’s degree in Nursing by specialization in Midwifery which is of two to three years. In this program, the candidate learns all about the midwife field related information and also learn the specific skills like how to deliver a baby or administer pereneal stitches.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Get Professional Certified Nurse Midwife (CNM) Certification And Secure A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one finally graduates from the Midwifery school, one is eligible to acquire the Certified Nurse Midwife (CNM) Certification. In order to acquire it, one has to successfully pass the test administered by the esteemed American Midwifery Certification Board (AMCB). When one successfully clears the test one gets the license and can easily secure a job in a maternity home, private clinic or hospital as a midwife. The license needs to be renewed every five years otherwise it will expire and one will not be able to practice as a midwife.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A MIDWIFE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes nearly 4 years to secure a bachelor’s degree in nursing or related fields. The one has to work for a year as a nurse to acquire the nursing experience. After successfully completing that, one has to enroll in a midwifery program of three years in an accredited college or university. When one has successfully secured all these degrees, one has to apply for the license of Certified Nurse Midwife (CNM). So, we can conclude by the aforementioned information that it takes nearly 8 years to become a midwife in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A MIDWIFE USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The data provided by the United States Bureau of Labor Statistics state the median pay of a midwife to be $ 106,910 per annum. Some of the states of America that employ the midwives by paying them the best rates are Arizona, California, Maryland and Massachusetts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}