import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Hedge_fund_manager.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Hedge_fund_manager = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Hedge Fund Manager - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A HEDGE FUND MANAGER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Hedge Fund Manager</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Hedge Fund Manager</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Hedge Fund Manager</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Hedge Fund Manager</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Hedge Fund Manager Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A HEDGE FUND MANAGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The hedge fund managers is the officer that is in charge of managing all the matters regarding hedge fund. The hedge fund is an alternative investment in which investors provide capital which is further invested in new businesses to provide profits to the investors. Hedge fund manager lives a hectic life because of the number of duties one has to perform. A hedge fund manager:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Manage all the matters that are related to the hedge fund</li>
                                        <br />
                                        <li>Conduct research and survey to shortlist the investment opportunities that are most financially rewarding</li>
                                        <br />
                                        <li>Invest in the niches that provide maximum capital returns in less time</li>
                                        <br />
                                        <li>Make a list of all the short term as well long term investments that are done using the money in the hedge fund</li>
                                        <br />
                                        <li>Can invest the capital in any form like in stocks, property or in a new currency like Bitcoin</li>
                                        <br />
                                        <li>Provide new and innovative investment strategies that could result in more profits</li>
                                        <br />
                                        <li>Use capital to buy more businesses in order to maximize the yearly returns</li>
                                        <br />
                                        <li>Make reports about the performance of each investment and the net profit that the investment has provided to the hedge fund</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A HEDGE FUND MANAGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are a lot of requirements to meet to become a hedge fund manager because the job of hedge fund manager is among the most lucrative jobs in USA. Some of the major requirements are listed below for the sake of convenience. A hedge fund manager:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have secured a Bachelor’s degree in any finance related field for entry level job</li>
                                        <br />
                                        <li>Should have an MBA as the candidates with MBA are preferred over others</li>
                                        <br />
                                        <li>Need to have the experience of working as a financial analyst for a few years</li>
                                        <br />
                                        <li>Could be Certified Public Accountant (CPA) as well with a few years of in field experience</li>
                                        <br />
                                        <li>Must have general command on economics , mathematics, statistics as well as accounting</li>
                                        <br />
                                        <li>Should be a member of Hedge Fund Association for more influence in the market</li>
                                        <br />
                                        <li>Should have an impressive personality and exceptional social skills</li>
                                        <br />
                                        <li>Should be charismatic enough to convince clients to do business with him</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A HEDGE FUND MANAGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The job of hedge fund manager comes with a lot of benefits. So, if you want to reap all these benefits then make your dream a reality by following the step by step guide below that outlines the way how one becomes a hedge fund manager.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        One of the basic requirements for the entry level job is to have a Bachelor’s in any finance related field with extra credit hours in mathematics and accounting. It is advisable to pursue an MBA as well for better employment opportunities as well as to increase the chances if promotion.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Become A Financial Analyst Or Certified Public Accountant (CPA)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Generally, one either has to be a certified financial analyst with a few years’ experience under the belt or be an experienced Certified Public Accountant (CPA) in order to qualify for the job of Hedge Fund manager. It is rather challenging to become Certified Public Accountant (CPA) as one has to do 150 credit hours of course work as well as pass the national CPA examination in order to acquire the license which is quite a long process.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Work As A Hedge Fund Manager</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        When one has finally met all the requirements, one gets interviewed by the board of the directors of CEO of the company and finally acquires the hedge fund manager job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A HEDGE FUND MANAGER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One spends 4 years to acquire the Bachelor’s degree in finance or in other finance related fields. Then one could either become a financial analyst and gain experience or become a Certified Public Accountant (CPA) and acquire experience which takes around 4 years. Furthermore one could also become a member of Hedge Fund Association in order to increase the power one commands. So, in conclusion it can easily take at least 8 or more years to become a Hedge Fund manager in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A HEDGE FUND MANAGER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average yearly income of a hedge fund manager stated by the United States Bureau of Labor Statistics is $ 127, 990. If one wants to work as a hedge fund manager then one should work in District of Columbia, Virginia and California in order to secure best wage rates.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}