import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Writer.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Writer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Writer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A WRITER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Writer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Writer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Writer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Writer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Writer Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A WRITER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The writer use words and letters to express their inner emotions and to communicate their ideas, inhibitions and views with the rest of the world. Writers are one of the most educated as well as imaginative artists in the whole world. They do a lot of different types of chores while pursuing their careers as a writer. A writer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Write manuscripts, fictional and non-fictional books, novels and plays</li>
                                        <br />
                                        <li>Write poems, sonnets, songs and soliloquies, articles and other types of written content</li>
                                        <br />
                                        <li>Research new topics and write about them in detailed articles</li>
                                        <br />
                                        <li>Edit and rearrange the texts to polish the already written scripts</li>
                                        <br />
                                        <li>Translate works from other language to the language of choice</li>
                                        <br />
                                        <li>Rephrase and restructure sentences to give a more polished look to the text</li>
                                        <br />
                                        <li>Can use data to write detailed reports about the events that have transpired</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A WRITER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you have enough love for books and literature then you should definitely pursue the career of a writer. Here is a quick list of some of the major requirements that every enthusiast writer to be has to meet. A writer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have excellent command at language</li>
                                        <br />
                                        <li>Must have perfect grammar and use of punctuations</li>
                                        <br />
                                        <li>Should be aware of the best linguistics rules of the language</li>
                                        <br />
                                        <li>Must have a passion for reading and writing</li>
                                        <br />
                                        <li>Must have an associate or a Bachelor’s degree in language, literature, journalism or other relevant fields</li>
                                        <br />
                                        <li>Must write a few articles, stories or a novel to gain experience</li>
                                        <br />
                                        <li>Must write a Cover Letter (C.V) that highlights the essential personal information, writing experience, educational background and skill set of the writer</li>
                                        <br />
                                        <li>Contact a publishing agent, a newspaper editor or a senior journalist to acquire a writing job</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A WRITER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you can imagine yourself securing a Pulitzer some decades later then let us be your mentor in pointing out the journey to becoming a writer. We have assembled a step by step guide that will help you in understanding how one needs to study and fulfill the requirements to become a writer in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The basic skill one needs to be a writer is to have a passion for both readings as well as writing. After that, one needs to have absolute command on the grammar, punctuations as well as linguistics of the language in order to be an immaculate writer. One should score excellent grades in Language subject in High School. Then one should aim to secure an associate, Bachelor’s or a Master’s degree in English language, literature, journalism, mass communications or other fields relevant to the occupation.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Write Some Works To Build Portfolio</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One should start working on some novel, short story, poem, sonnet or any other literary work while pursuing the education. One should keep reading and writing new works regularly to polish the skills and garner a command over the skill set as well. By the time, one finishes the education, one should work hard on creating a few of the literary works of one’s own so that one can add them in the portfolio. One should also participate in the literary societies of school and college to acquire writing skill.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Use The Cover Letter (C.V) To Land A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The one should construct a Cover Letter (C.V) by first mentioning the basic personal information, education and then the literary work and skill set. One should present this CV at the interview to land a job as a writer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A WRITER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One can easily secure the associate degree in 2 years and Bachelor’s degree in 4 years from an accredited university. Then one has to write some literary works in order to build up the portfolio which one uses to land a job as a writer. One could use the Cover Letter (C.V) to land a job at a newspaper, television channel or get a publishing deal signed with a publishing house. So, it is safe to state that one can easily pursue the career of a writer in USA in almost 3 to 5 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A WRITER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The writers earn the mean salary of nearly $ 62,170 per year as per the reports made public by the United States Bureau of Labor Statistics. District of Columbia, California and Connecticut are some of the states of USA that provide best financial advantages to the writers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}