import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Crime_scene_investigator.css';
import img7 from '../images/7.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Crime_scene_investigator = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Crime Scene Investigator - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CRIME SCENE INVESTIGATOR
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href={`#duties_of_a_crime_scene_investigator`}>Duties Of A Crime Scene Investigator?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#requirements_to_become_a_crime_scene_investigator`}>Requirements To Become A Crime Scene Investigator?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#STATES`}>STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#steps_to_become_a_crime_scene_investigator`}>Steps To Become A Crime Scene Investigator?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#get_certification`}>Get Certification</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_long_does_it_take_to_become_a_crime_scene_investigator`}>How Long Does It Take To Become A Crime Scene Investigator</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_much_does_a_crime_scene_investigator_usually_earn`}>How Much Does A Crime Scene Investigator Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_crime_scene_investigator">
                                <h2>DUTIES OF A CRIME SCENE INVESTIGATOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Crime scene investigator is a skilled professional that collects maximum evidences from a crime scene and preserves them for future investigation. Here is the list of duties that every crime scene investigator usually carries out. A crime scene investigator:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Secure the crime scene from further contamination</li>
                                        <br />
                                        <li>Collect and document the evidences harvested from the crime scene</li>
                                        <br />
                                        <li>Make an inventory of all the evidences harvested from a crime scene</li>
                                        <br />
                                        <li>Write a detailed description of the crime scene</li>
                                        <br />
                                        <li>May also take pictures and draw drawings for future reference</li>
                                        <br />
                                        <li>Run analysis on the collected evidence and submit a report to the concerned officials</li>
                                        <br />
                                        <li>Give testimonies in the court for the evidence</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CRIME SCENE INVESTIGATOR</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img7} alt="STEPS TO BECOME A CRIME SCENE INVESTIGATOR" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_crime_scene_investigator">
                                <h2>REQUIREMENTS TO BECOME A CRIME SCENE INVESTIGATOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are a number of requirements that a candidate aiming to become a crime scene investigator has to fulfill. A crime scene investigator:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must have a valid driver’s license</li>
                                        <br />
                                        <li>Have a perfectly clean criminal background</li>
                                        <br />
                                        <li>Must have successfully secured a High School Diploma</li>
                                        <br />
                                        <li>Must have an associate or bachelor’s degree</li>
                                        <br />
                                        <li>Should complete police academy training if pursuing a government job</li>
                                        <br />
                                        <li>Pursue an internship or do a related job</li>
                                        <br />
                                        <li>Secure certification from the national institution</li>
                                        <br />
                                        <li>Acquire license by completing Peace Officer Standards and Training (P.O.S.T.) program</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_crime_scene_investigator">
                                <h2>STEPS TO BECOME A CRIME SCENE INVESTIGATOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There is a systematic process of becoming a crime scene investigator in United States of America. Here is the step by step guide to help you realize how one becomes a crime scene investigator:
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        The candidate aiming to become a crime scene investigator must be a permanent United States citizen and at least 18/21 years of age. The candidate must have secured a High School Diploma with good grades. In addition to that, most of the institutions nowadays prefer to hire candidates with at least an associate degree or a bachelor’s degree. A candidate can pursue a degree in criminal justice, forensics, biology, chemistry, natural sciences, or related fields to become a crime scene investigator. If one has already secured some non-related degree then one has to do a diploma in Crime Scene Investigation.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass The Crime Scene Investigator Test</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        A candidate aiming to become a crime scene investigator has to pass a written test as well as a physical test to qualify for the job. Furthermore, a candidate has to have a perfect criminal background with no felony, drug abuse, misdemeanor, sexual harassment and traffic abuse charges. When one finally passes the test, one moves onto the next step which is either to get admission in police academy or do an internship.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Join Police Academy Or Do Internship</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        There are two types of crime scene investigators; sworn and non-sworn. All the sworn crime scene investigators are police officers by law. They have undergone training in police academy and have acquired license by completing Peace Officer Standards and Training (P.O.S.T.) program. While those who are non-sworn investigator pursue internship under an experienced crime scene investigator to learn the skills for carrying out the respective duties.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="get_certification">
                                <h2>GET CERTIFICATION</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A professional crime scene investigator can also get certification from International Association for Identification (IAI). One must be working as a crime scene investigator for at least a year to become eligible for the certification. The certification process also includes a written and physical test which one has to pass to acquire the certification. The chances of promotion usually increases when one finally secures the certification
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_crime_scene_investigator">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CRIME SCENE INVESTIGATOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The associate degree takes 2 years while a bachelor’s degree takes 4 years to complete. Then it takes eight months to a year, sometimes 3 years for firearms analysis training. Then one must be working as a crime scene investigator for at least a year to get the certification from International Association for Identification (IAI). Hence, it is safe to mention that it takes five to eight years to become a crime scene investigator in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_crime_scene_investigator_usually_earn">
                                <h2>HOW MUCH DOES A CRIME SCENE INVESTIGATOR USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The data provided by United States Bureau of Labor Statistics estimate the average per annum pay of a crime scene investigator to be $81,920. The pay may differ from one place to another due to different laws and regulations, taxes, hourly wages and education requirements of different states.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}