import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Detective_in_texas.css';
import img14 from '../images/14.png';
import { NavLink } from 'react-router-dom';

export const Detective_in_texas = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=texas")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=texas&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Detective in Texas - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DETECTIVE IN TEXAS
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_detective_in_texas">Requirements To Become A Detective In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#responsibilities_of_a_detective_in_texas">Responsibilities Of A Detective In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_detective_in_texas">Steps To Become A Detective In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_does_it_take_to_become_a_detective_in_texas">How Long Does It Take To Become A Detective In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_detective_make_in_texas">How Much Does Detective Make In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#career_outlook_for_a_detective_in_texas">Career Outlook For A Detective In Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A Detective in Texas is an official who is responsible for investigation of crime scenes, evidence inspection and investigative interviews.
                                        <br />
                                        The information that must be acknowledged to be a Detective in Texas given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="requirements_to_become_a_detective_in_texas">
                                <h2>REQUIREMENTS TO BECOME A DETECTIVE IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Investigation of crime scenes</li>
                                        <br />
                                        <li>Inspection of alibis, clues and time frames.</li>
                                        <br />
                                        <li>Collection and use of evidences</li>
                                        <br />
                                        <li>Make a strong court case against a criminal</li>
                                        <br />
                                        <li>Interview the suspects, informants and victims.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A DETECTIVE IN TEXAS</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img14} alt="HOW TO BECOME A DETECTIVE IN TEXAS" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="responsibilities_of_a_detective_in_texas">
                                <h2>RESPONSIBILITIES OF A DETECTIVE IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A high school diploma/ GED certificate</li>
                                        <br />
                                        <li>Associate/ Bachelor’s degree in criminology, sociology, psychology and law</li>
                                        <br />
                                        <li>Work experience as a police officer</li>
                                        <br />
                                        <li>US citizenship</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_detective_in_texas">
                                <h2>STEPS TO BECOME A DETECTIVE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This includes US citizenship, valid driving license of Texas, a high-school diploma/GED certificate and a clear background. These necessities make you eligible to be an applicant for the trainings to become a Detective in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get An Associate/ Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        As a detective, you must be sure of the specialisation you require as a juvenile, technical investigation or a homicide detective. For such position you must hold an associate or a bachelor’s degree program in criminology, psychology, sociology or criminal law, accordingly.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In a police academy, after the initial training which holds the time frame of around 6-8 months, you may require additional training of 1-3 years where the time limit may vary. This training ensures that the applicant holds all the attributes and qualities required by a detective in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Take A Police Detective Test/NDIT (National Detective/Investigation Test)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This test hold 75 questions that qualifies you as an candidate for the position. The questions are to test the ability of candidate in investigating crimes, interviewing suspects, inspection of alibis etc. The applicant is required to hold an experience of a few years as a police officer in Texas before taking this test.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Apply For The Position Of A Detective</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To apply for the position of a detective, the above-mentioned requirements must be fulfilled along with the work experience of 3-5 years as a police officer in Texas. The applicant must also have a clear background as police officer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_detective_in_texas">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DETECTIVE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        After enrolling in a police academy, it takes around 6-8 months for the initial training. The total time frame includes, 1-2 year of additional training and 3-5 years of job experience to become eligible for the position of a detective in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_detective_make_in_texas">
                                <h2>HOW MUCH DOES DETECTIVE MAKE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In September-2019, it was recorded the an average detective make around $57,568 in Texas per year. Whereas an average homicide detective earn around $74,047 per year. The salary may vary because of the position and hours on-duty.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="career_outlook_for_a_detective_in_texas">
                                <h2>CAREER OUTLOOK FOR A DETECTIVE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that the job opportunities may increase for the detectives in Texas at the rate of 5-7% in between 2018-28. Another prediction states that it may increase upto 5-9% in between 2016-26.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-detective-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>DETECTIVE SCHOOLS IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/texas/" className="btn btn-dark">Detective Schools in Texas</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}