import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Chef.css';
import chef from '../images/chef.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Chef = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Chef - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CHEF
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Requirements To Become A Chef</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Steps To Become A Chef</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">How Long Does It Take To Become A Chef</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Much Does A Chef Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A chef is person whose passion, obsession as well as goal of life are flavor and texture of food. Becoming a chef requires dedication, hard work and sincerity to the food. Here are some of the duties that a chef carries out. A chef often:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Runs the kitchen and is usually considered the Head Boss of the kitchen</li>
                                        <br />
                                        <li>Selects the menu of the restaurant</li>
                                        <br />
                                        <li>Tastes all the food prepared in the kitchen</li>
                                        <br />
                                        <li>Choose as well as closely examines the ingredients of the dish</li>
                                        <br />
                                        <li>Assigns duties to the staff in the kitchen</li>
                                        <br />
                                        <li>Supervises the workers in the kitchen</li>
                                        <br />
                                        <li>Ensures that all orders are made and delivered in time</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CHEF</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={chef} alt="STEPS TO BECOME A CHEF" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>REQUIREMENTS TO BECOME A CHEF?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If one is thinking of pursuing a career as a chef then here is a list of requirements that every chef has to fulfill. Every person thinking of becoming a chef:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Should have an associate degree or diploma from the Culinary School</li>
                                        <br />
                                        <li>Should have a passion for cooking and food in general</li>
                                        <br />
                                        <li>Should be creative to invent more ways to serve food</li>
                                        <br />
                                        <li>Should be able enough to work long hours without much break</li>
                                        <br />
                                        <li>Must be quick enough to handle multiple tasks at one time</li>
                                        <br />
                                        <li>Must be able to work near heat</li>
                                        <br />
                                        <li>Should be able to handle pressure of rush hour in the restaurant</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>STEPS TO BECOME A CHEF?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If one wants to get familiar with the process of becoming a chef then one need not worry. Here is the step by step process of how to shape one’s future to become a chef.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Basic Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Every candidate aiming to become a chef must have at least the High School Diploma, GED, or any degree equivalent to it. One can join the apprenticeship program under a chef directly after getting the high school diploma. But if one are aiming to work in a prestigious restaurant or to open one’s restaurant one will need to pursue further studies in culinary arts.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Enter In Culinary School And Graduate</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        One can pursue a diploma, an associate degree or a bachelor’s degree in the culinary arts in the culinary school. After graduation when one look for apprenticeship and jobs, these degrees can play an important role in boasting one’s resume and also helps one to raise ranks quickly. They also open up new horizons and hone the skill of an aspiring chef.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Join Apprenticeship And Get Experience In The Field</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After graduating from the culinary school, one should look for the apprenticeship opportunities under the experienced chefs. The apprenticeship program will play an important role in learning the quicker and better way to make, serve and present the food as well as deal with the customers. In addition to that, one also learns the inner workings of a restaurant and the hierarchy followed in the premises of kitchen. Many apprenticeship programs also open up new employment windows for the fresh graduates.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Get Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the apprenticeship under a professional chef, one must strive to get the certification by American Culinary Foundation (ACF) founded in 1929. One can get fourteen different types of certificates in five niches of culinary art. One can get certification in Cooking Professionals, Personal Cooking Professionals, Baking and Pastry Professionals, Culinary Administrators; and Culinary Educators. The certification plays a pivotal role in finalizing what type of culinary art will be the niche of one’s professional career.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CHEF?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One secures admission in culinary school after completing then high school diploma. It takes one to four years to graduate from the culinary school depending on the degree. Diploma in culinary arts takes a year, associate degree takes two years while bachelor’s degree in culinary arts takes four years to complete. Afterwards, apprenticeship can take a few more years depending on the chef that is training the person. The one has to work for the certification as well in order to get better employment opportunities.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW MUCH DOES A CHEF USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A chef normally earns around $48,460 according to the data provided by the United States Bureau of Labor Statistics per year. Each state has its own hourly wage and tax percentage that is why the average annual wage may be different in the different states of United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/animal-cop/">Steps To Become A Animal Cop</NavLink></li>
                                        <li><NavLink to="/business-analyst/">Steps to become a Business Analyst</NavLink></li>
                                        <li><NavLink to="/firefighter/">Steps to become a Firefighter</NavLink></li>
                                        <li><NavLink to="/welder/">Steps To Become A Welder</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}