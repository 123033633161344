import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Forensic_scientist_in_texas.css';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist_in_texas = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=texas")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=texas&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist In Texas - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST IN TEXAS
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Forensic_Scientist_in_Texas">Responsibilities Of A Forensic Scientist In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Forensic_Scientist_in_Texas">Requirements To Become A Forensic Scientist In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_Scientist_in_Texas">Steps To Become A Forensic Scientist In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_it_takes_to_become_a_Forensic_Scientist_in_Texas">How Long Does It Take To Become A Forensic Scientist In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Forensic_Scientist_make_in_Texas">How Much Does A Forensic Scientist Make In Texas</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_for_a_Forensic_scientist_in_Texas">Career Outlook For Forensic Scientists In Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A Forensic Scientist in Texas plays a vital role in the law enforcing department. Their life revolves around analysing and collecting evidence, making legal statements and strengthening court cases against criminals.
                                        <br />
                                        The fundamentals to become a Forensic Scientist in Texas are given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Forensic_Scientist_in_Texas">
                                <h2>RESPONSIBILITIES OF A FORENSIC SCIENTIST IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Collection of Physical Evidence</li>
                                        <br />
                                        <li>Analysis of evidence</li>
                                        <br />
                                        <li>Make legal statements</li>
                                        <br />
                                        <li>Build up court cases against criminals</li>
                                        <br />
                                        <li>Communication with law enforcing officials</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Forensic_Scientist_in_Texas">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST IN TEXAS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A bachelor’s degree program</li>
                                        <br />
                                        <li>A Master degree Program (for higher posts)</li>
                                        <br />
                                        <li>Strong analytical and logical skills</li>
                                        <br />
                                        <li>Citizenship of US</li>
                                        <br />
                                        <li>Clear criminal record and drug test</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_Scientist_in_Texas">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is important to hold a bachelor’s degree program in subjects like forensic science, biology, chemistry and anthropology to be eligible for the post of forensic scientist in Texas. This 4-years degree program enhance and build up the analytical skills, qualitative analysis and handling of critical situations of the candidate.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get Experience For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is beneficial to get some professional experience before applying for the job and hence getting an internship is highly recommended for a forensic scientist in Texas. The university or college’s placement office usually helps their students with finding a suitable place for internship. It helps the candidate to get used to the professional responsibilities as well as the critical nature of the job of a forensic scientist in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Consider A Master’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Getting a master’s degree can surely aid the candidate in securing a higher position in forensic department of Texas. A degree in microscopy, toxicology and criminology is highly preferred. It assist the candidate in strengthening the skills required to be a forensic scientist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Find A Job In Texas</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The next step is to apply for the position of a forensic scientist in Texas. In 2017, the acting number of forensic scientists were 1,100. To get a job in the state’s crime labs or Law Enforcing department, the candidate must pass an extensive background check and drug test.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Consider Getting Certified</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        A certificate can be helpful for the profile and resume of a candidate to be a forensic scientist in Texas. The Forensic Specialities Accreditation Board (FSAB) has given the authority to ten organisations to issue certificates for the candidates.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_it_takes_to_become_a_Forensic_Scientist_in_Texas">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Once the candidate has completed the minimal educational requirement i.e 4-years bachelor’s degree program, an optional 2-4 years associate/master’s degree program and the internship (time-limit may vary). It may take around 4-6 months to get a firm job as forensic scientist in Texas.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Forensic_Scientist_make_in_Texas">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST MAKE IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is recorded that a forensic scientist in Texas makes an average of around $54,890 per year. Whereas, the salary may increase or decrease on the basis of working hours and job position. The highest salary recorded in 2019, was $114,680, respectively.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_for_a_Forensic_scientist_in_Texas">
                                <h2>CAREER OUTLOOK FOR A FORENSIC SCIENTIST IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is predicted that the job opportunities may increase upto 20% in between 2018-28 for the forensic scientists in Texas. However, another analysis predicts that it may increase around 17-25% in the next 10 years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-forensic-scientist-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FORENSIC SCIENTIST SCHOOLS IN TEXAS</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/texas/" className="btn btn-dark">Forensic Scientists Schools in Texas</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}