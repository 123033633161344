import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Forensic_scientist_in_florida.css';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist_in_florida = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=florida")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=florida&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist In Florida - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST IN FLORIDA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Forensic_Scientist_in_Florida">Responsibilities Of A Forensic Scientist In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Forensic_Scientist_in_Florida">Requirements To Become A Forensic Scientist In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_Scientist_in_Florida">Steps To Become A Forensic Scientist In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Forensic_Scientist_in_Florida">How Long Does It Take To Become A Forensic Scientist In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Forensic_Scientist_make_in_Florida">How Much Does A Forensic Scientist Make In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_for_a_Forensic_Scientist_in_Florida">Career Outlook For A Forensic Scientist In Florida</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In Florida, a forensic scientist is an official who collect, preserve, analyse and give legalised verdict during a criminal investigation. They play a vital role in the identification of criminals by the interpretation of physical evidence and hence holds a respectable and essential position in the law enforcing department of Florida.
                                        <br />
                                        The fundamentals that must be kept in mind before opting the profession of a forensic scientist in Florida are given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Forensic_Scientist_in_Florida">
                                <h2>RESPONSIBILITIES OF A FORENSIC SCIENTIST IN FLORIDA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Identification and collection of evidence</li>
                                        <br />
                                        <li>Preservation and analysis of evidence</li>
                                        <br />
                                        <li>Communication with the law enforcing officials</li>
                                        <br />
                                        <li>Making legal statements for the court cases</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Forensic_Scientist_in_Florida">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST IN FLORIDA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must pass a drug test/background check</li>
                                        <br />
                                        <li>Holds US citizenship</li>
                                        <br />
                                        <li>Must have a 4-years bachelor’s degree program</li>
                                        <br />
                                        <li>Must have a valid driving license of the state of Florida</li>
                                        <br />
                                        <li>Must hold the attributes such as good analytical and communicating skills, logical reasoning and critical thinking.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_Scientist_in_Florida">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The minimal educational requirement is to hold a 4-years bachelor’s degree program for the position of a forensic scientist in Florida. The subjects maybe forensic science, chemistry, anthropology and biology. This also helps the candidate in acquiring the abilities such as composure, critical thinking and qualitative analysis.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get Experience For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the 4-year degree program, the candidate must get some professional experience. In Florida, the college or university’s placement office helps in finding a place for internship. This helps the candidate to enhance their competency for the position of a forensic scientist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Consider A Master’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Master’s degree for the position of a forensic scientist in Florida is not compulsory but it is important if the candidate wants to achieve a high ranked position in the department. The degree may be in subjects like criminology, toxicology or microscopy.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Find A Job In California</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The candidate can find a job in Florida as a forensic scientist, forensic investigator, forensic technician and etc. The jobs may be available in state’s or public labs. Florida is one of the two states holding the high employment levels for forensic scientists. Currently, Florida is holding 1,450 employed forensic scientists.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Consider Getting Certified</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In Florida, several forensic colleges offer certification for the candidates, however, every college/institute and degree of certificate requires a specific time-frame before the candidate is eligible for the certification exam. The time-frame may be of one or two years and upto several years. The certificate also aids the candidate in achieving a higher position.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Forensic_Scientist_in_Florida">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The timespan that the candidate must take under consideration for becoming a forensic scientist in Florida includes a 4-years bachelor’s degree program, an additional 2-3 years master’s degree program, an internship of 3-4 months to complete the employment requirements and then around 5-6 months to get a firm job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Forensic_Scientist_make_in_Florida">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST MAKE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An average forensic scientist makes around $51,670 per year in Florida. Whereas in another survey it was recorded around $55,209 per year, respectively. The wages may increase or decrease depending upon the working hours and the nature of position.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_for_a_Forensic_Scientist_in_Florida">
                                <h2>CAREER OUTLOOK FOR A FORENSIC SCIENTIST IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that the jobs opportunities for forensic scientists may increase around 19% in Florida during 2018-22. Whereas it is also predicted that the number of new jobs for forensic scientists may increase upto 2,600 in next 10 years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-forensic-scientist-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FORENSIC SCIENTIST SCHOOLS IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/florida/" className="btn btn-dark">Forensic Scientists Schools in Florida</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}