import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";

import './Police_officer.css';
import police from '../images/_police.jpg';
import map from '../images/_usamap.png';
import { NavLink } from 'react-router-dom';

export const Police_officer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Police Officer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A POLICE OFFICER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#duties_of_a_Police_Officer">Duties Of A Police Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_Police_Officer">Requirements To Become A Police Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Police_Officer">Steps To Become A Police Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Police_Officer">How Long Does It Take To Become A Police Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Police_Officer_usually_earn">How Much Does A Police Officer Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_Police_Officer">
                                <h2>DUTIES OF A POLICE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you aim to serve your people and give back to the community which has helped to shape you then joining the police force is one of the best available options. Here is the list of duties that a Police Officer is generally required to carry out:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Protect the people and their property</li>
                                        <br />
                                        <li>Enforce laws in the area of their duty</li>
                                        <br />
                                        <li>Obtain warrants and arrest the criminals as well as people suspected of committing a crime</li>
                                        <br />
                                        <li>Secure the crime scenes as well as any accident site</li>
                                        <br />
                                        <li>Write detailed reports citing facts and figures about the case</li>
                                        <br />
                                        <li>Testify in the court against the criminals</li>
                                        <br />
                                        <li>Give quick response to any emergency and reach there as soon as possible</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A POLICE OFFICER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={police} alt="HOW TO BECOME A POLICE OFFICER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="requirements_to_become_a_Police_Officer">
                                <h2>REQUIREMENTS TO BECOME A POLICE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to become a Police Officer then you have to meet the following requirements. The Police Officer must:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Have a High School Diploma or GED at least</li>
                                        <br />
                                        <li>Have a valid driver’s license</li>
                                        <br />
                                        <li>Be at least 18/21 years of age depending on the respective state’s or department’s policy</li>
                                        <br />
                                        <li>Must not have any criminal record or charged with felony</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    <div className="col-md-7">
                                        <div className="row pt-2 pb-2 pl-3">
                                            {
                                                states != null && states.length > 0 &&
                                                states.map((item, key) => {
                                                    return (
                                                        item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                            <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/how-to-become-a-police-officer-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                            : <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-5 mt-auto mb-auto">
                                        <img src={map} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Police_Officer">
                                <h2>STEPS TO BECOME A POLICE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the step by step guide which can help you to realize what it takes to become a Police Officer.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Obtain High School Diploma Or GED (Minimum)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The minimum education required to pursue a career as a police officer is High School Diploma or GED, but a candidate definitely needs to pursue higher education if he is aiming for promotion to a higher post. The trend of accepting High School graduate  is changing as the Police Academy nowadays prefer college graduate candidates over High School graduates.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Meet The Necessary Requirements For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is the protocol of the Police Academy to accept only permanent US citizens, 18 or 21 years of age depending on the required policy of the state. The candidate must also have a valid driver’s license issued by the state. It is the part of the policy of the Police Academy to do a background check of the candidates as only candidates with a clean criminal record are recruited as trainees. In the candidate selection process, the candidates also have to go through a polygraph test that is why the candidate must be honest in the dealings.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Clear The Law Enforcement And The Fitness Test</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When you fulfill all these requirements then you are given where you have to fill in all your credentials and attach the required documents with them. In addition to that, you will also have to submit a small acceptance fee along with a written statement that acts as your cover letter. Upon acceptance into the academy, you will have to pass a written as well as fitness tests which are different for different states. In some states of United States of America, the candidates also have to go through a psychological test as well as a polygraph test during the selection process.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Successfully Graduate From The Police Academy And Work For Promotion</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When a candidate successfully completes the 14-22 weeks, nearly six months, training in the Police Academy, the fresh graduate is then put on a Probation Period. This period is more or less of six months, where one has to work with a senior partner to learn the ropes of the job and familiarize with the environment. If you fail to perform well during the Probation Period, you might get suspended from the job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Police_Officer">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A POLICE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are aiming to join Police force after completing your graduation then it is four years to meet the educational requirements for the job. Afterwards if you get accepted then the duration of the training course in Police Academy is about 12-22 weeks, nearly six months, depending on the rules and regulations of the given state.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Police_Officer_usually_earn">
                                <h2>HOW MUCH DOES A POLICE OFFICER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The mean annual pay of the Police Officer is around $60,270 according to the figures provided by the United States Bureau of Labor Statistics. The figures could be different for different states as per their respective rules. The average income of a new Police Officer is comparatively less than the experienced Police officer, but the pay scale increases as the officer is promoted to a higher post.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}