import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Librarian.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Librarian = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Librarian - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A LIBRARIAN
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Librarian</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Librarian</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Librarian</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Librarian</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Librarian Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A LIBRARIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The librarian have the responsibility of keeping books, journals, maps, guides and other pieces of literature in an orderly form. The librarian spends most of the time in library performing the duties expected of the librarian. The librarian:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Acts as the administrator of the whole library</li>
                                        <br />
                                        <li>Handles all the matters related to the library</li>
                                        <br />
                                        <li>Short list and handpick the books that are to be displayed in the library</li>
                                        <br />
                                        <li>Make sure that the requested books have arrived in the library</li>
                                        <br />
                                        <li>Arrange the display of books in some particular order like on the basis of each book’s writer, genre, year of publication, publishing house and others</li>
                                        <br />
                                        <li>Provide advice to the new readers</li>
                                        <br />
                                        <li>Guide the people visiting the library to their required section where the book is most likely to be present</li>
                                        <br />
                                        <li>Take required information and issue library card to the visitors of library</li>
                                        <br />
                                        <li>Issue the book that one wants to borrow from the library</li>
                                        <br />
                                        <li>Conduct book launches in the library</li>
                                        <br />
                                        <li>Upload the information about all the books in a cataloging log in order to make the process of bibliography easier</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A LIBRARIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The job of a librarian is quiet taxing but of you think that you can meet the requirements mentioned in the inventory below then you can definitely become a librarian in USA. The librarian:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a Bachelor’s degree in library science, education or in any other field with teaching credentials</li>
                                        <br />
                                        <li>Should pursue Masters as most library positions require Masters in Library Science (MLS)</li>
                                        <br />
                                        <li>Must acquire some teaching experience in some school or college</li>
                                        <br />
                                        <li>Must pass Praxis or National Evaluation Series (NES) exams in order to achieve certification for librarian</li>
                                        <br />
                                        <li>Must have a passion for books and literature</li>
                                        <br />
                                        <li>Must be able to think of new methods of cataloging books and making them more accessible for readers</li>
                                        <br />
                                        <li>Must be confident and assertive to ensure that no one is disturbing the serene environment of library</li>
                                        <br />
                                        <li>Should be helpful and patient to those who are unable to find the book they desire</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A LIBRARIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have a love for books and love to stay in a library then you can surely consult the step by step guide provided below in order to make you dream of becoming a librarian, a reality!
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        First of all, one must have a passion for books and literature. It is extremely important for a librarian to have a Bachelor’s degree in library science, education or in any other field with teaching credentials for entry level librarian job. But in order to get promoted to higher and better librarian posts it is eminent to have a Masters in Library Science (MLS).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Specialization, Internship And Teaching Experience</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        When one has finally completed the degree, one should either enroll in an internship program or acquire some teaching experience related to library science. One could work as an intern in museums, schools, colleges, universities, public libraries and gain in field experience. One should also consider specialization as a librarian like if one want to work as a law librarian or music librarian then one must pursue some short courses in the given field.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Certification Of Librarian</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        One could acquire a job and start working as a librarian after completing studies but it is important to acquire the certification of librarian especially for working as an executive librarian in a reputable library. Hence, one needs to do preparations to pass the Praxis or National Evaluation Series (NES) exams in order to obtain the certification of librarian.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A LIBRARIAN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One spends 4 years to secure Bachelors in library science in order to acquire any entry level job. But many higher librarian positions require the librarian to have Masters in Library Science (MLS) of 2 years. Then it is best to do internship for a few months or have some teaching experience. Then one has to secure certification of librarian by passing Praxis or National Evaluation Series (NES) exams. So, we can conclude, that by meeting all the requirements one can become a Librarian in America in 5to 7 nearly years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A LIBRARIAN USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average yearly salary of a general librarian is estimated somewhere around $ as per the facts and figures made public by the United States Bureau of Labor Statistics. One should definitely consider to earn better wages by working as a librarian in states like.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}