import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Registered_nurse.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Registered_nurse = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Registered Nurse - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A REGISTERED NURSE
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Registered Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Registered Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Registered Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Registered Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Registered Nurse Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A REGISTERED NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Registered Nurse is an integral part of the healthcare industry as the registered nurse provides medical assistance to the doctors as well as facilitate the patients. A registered nurse is required to perform a plethora of duties during the working hours. A Registered Nurse:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Help the doctors in treating the ailments of the patients</li>
                                        <br />
                                        <li>Take medical history of the patient</li>
                                        <br />
                                        <li>Help the patients to fill in the various healthcare forms</li>
                                        <br />
                                        <li>Educate the patients about the medical procedures as well as their ailment</li>
                                        <br />
                                        <li>Operates different sorts of medical equipment</li>
                                        <br />
                                        <li>Perform various diagnostics tests and provide the reports to the doctors</li>
                                        <br />
                                        <li>Record the body vitals levels and note them down in the chart</li>
                                        <br />
                                        <li>Take notice of the symptoms of the diseases</li>
                                        <br />
                                        <li>Teach the patients how and when they need to take which medicine</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A REGISTERED NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Does the thought of becoming a Registered Nurse makes your heart go fast in excitement? If yes, then here is the list of requirements that every candidate for the position of Registered Nurse has to follow. A Registered Nurse:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a High School Diploma with excellent grades in sciences</li>
                                        <br />
                                        <li>Could secure a diploma or an associate degree in nursing to get into the field</li>
                                        <br />
                                        <li>Should pursue the Bachelors of Science in Nursing (BSN)</li>
                                        <br />
                                        <li>Must secure the certification of a Registered Nurse from the National Council Licensing Exam for Registered Nurses (NCLEX-RN)</li>
                                        <br />
                                        <li>Must have excellent public skills</li>
                                        <br />
                                        <li>Should be able to calm a patient during a medical procedure</li>
                                        <br />
                                        <li>Must be empathetic towards the pain and worries of the patient</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A REGISTERED NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If the thought of becoming a Registered Nurse (RN) appeals you most out of all the available career options available then let us help you by educating you. Here is the step by step guide to teach you how one pursues a career as an RN in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The candidate first of all needs to graduate from High School by obtaining very good marks in biology, chemistry, statistics and others. When one has fulfilled all the prerequisites then one could either secure a diploma, an associate degree or a Bachelor’s degree in Nursing. The associate degree in Nursing like Associate of Applied Science in Nursing (AASN) or Associate of Science in Nursing (ASN) are some of the options to choose from. But the best option is to go for a comprehensive degree like Bachelors of Science in Nursing (BSN).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Secure The License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally completed the degree, one must strove to obtain the license of Registered Nurse. It is compulsory to have the license if one wants to practice as an RN in any state of USA has to successfully obtain the licensure of Registered Nurse from the National Council Licensing Exam for Registered Nurses (NCLEX-RN).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Work As A RN</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one finally becomes a certified RN, one should look for a job as a RN in some hospital or clinic. It is advised that initially one should work as an intern of an experienced nurse so that one could know how to put the knowledge one has learnt in the nursing school to practical use.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Think Of Specializing In A Particular Field</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When you have completed a year or two working as a RN in various niches of medical field and in different medical wards you should plan your specialization. You can pursue a Master’s degree in a field that attracts you the most out of all the nurse related fields like Nurse Anesthetist, Nurse Practitioner, Midwife and many other fields.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A REGISTERED NURSE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        According to the accredited colleges, it takes nearly 2 years to complete an associate degree and 4 years to complete the Bachelor’s degree in Nursing. Then one has to secure the license of Registered Nurse as well from the National Council Licensing Exam for Registered Nurses (NCLEX-RN). Hence, it is safe to say, that it takes approximately, 2 to 4 years to become a Registered Nurse (RN) in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A REGISTERED NURSE USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The facts and figures published by the Unites States Bureau of labor Statistics estimate the mean yearly wage of a registered nurse to be around $71,730. Hawaii, California and Alaska are some of the American states that provide best wage rates to the nurses working in the field as registered nurses.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}