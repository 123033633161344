import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Detective_in_florida.css';
import img16 from '../images/16.png';
import { NavLink } from 'react-router-dom';

export const Detective_in_florida = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=florida")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=florida&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Detective in Florida - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DETECTIVE IN FLORIDA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#responsibilities_of_a_detective_in_florida">Responsibilities Of A Detective In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirement_to_become_a_detectiv_in_florida">Requirements To Become A Detective In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_detective_in_florida">Steps To Become A Detective In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_it_takes_to_become_a_detective_in_florida">How Long Does It Take To Become A Detective In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_a_detective_make_in_florida">How Much Does Detective Make In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#career_outlook_for_detective_in_florida">Career Outlook For A Detective In Florida</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A detective in Florida is an official of police department who deals with the investigation of crime scenes, inspection of time-frames, collection of evidence and so much more. The position of a detective is not only a respectful but only very crucial for the law enforcing authorities of Florida.
                                        <br />
                                        The essentials to know about becoming a detective in Florida is given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="responsibilities_of_a_detective_in_florida">
                                <h2>RESPONSIBILITIES OF A DETECTIVE IN FLORIDA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Investigation of crime scenes</li>
                                        <br />
                                        <li>Interviewing of informants, suspects and victims</li>
                                        <br />
                                        <li>Collection of evidence to make a strong court case against the criminal</li>
                                        <br />
                                        <li>Inspection of alibis, time frames and clues.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A DETECTIVE IN FLORIDA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img16} alt="HOW TO BECOME A DETECTIVE IN FLORIDA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirement_to_become_a_detectiv_in_florida">
                                <h2>REQUIREMENTS TO BECOME A DETECTIVE IN FLORIDA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A high-school diploma/ GED certificate</li>
                                        <br />
                                        <li>An associate or bachelor’s degree program in criminology, psychology or law</li>
                                        <br />
                                        <li>Training to become a detective</li>
                                        <br />
                                        <li>Work experience as a police officer</li>
                                        <br />
                                        <li>US citizenship</li>
                                        <br />
                                        <li>Clear background</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_detective_in_florida">
                                <h2>STEPS TO BECOME A DETECTIVE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This entails a high school diploma/GED certificate, US citizenship, valid license of the state of Florida and clear background. These requirements must be fulfilled to become eligible enough to be applicant for the position in a police academy in Florida.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get An Associate/ Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In Florida, A detective’s position can vary on the basis of the degree that the applicant holds. A detective may be a juvenile, homicide or a technical investigation one. These post require the degree program accordingly such as a degree in psychology, sociology, criminology or criminal law.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        If you get enrolled in a police academy of Florida then you must complete the police training of 6-8 months. Once completed, the additional training starts that takes usually in between of 1-3 years. This training ensures that the applicant holds all the qualities and attributes that a detective in Florida must have.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Take A Police Detective Test/NDIT (National Detective/Investigation Test)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This test qualifies you as a candidate for the position of detective in Florida. It consist of 75 questions that are based on law/constitution of Florida, investigative interviews and inspection of crime scenes. The applicant is required to hold working experience of few years before taking this test.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Apply For The Position Of Detective</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After clearing the test, you’re eligible to be a candidate for the position. It must be kept in focus that all the requirements should be fulfilled before applying for the position. The applicant must hold a working experience as a police officer of 3-5 years too.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_it_takes_to_become_a_detective_in_florida">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DETECTIVE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time frame includes initial training of 6-8 months, additional training of 1-3 years and work experience of 3-5 years to become a Detective in Florida. It must be kept in mind that the time may vary accordingly with the candidate.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="#how_much_does_a_detective_make_in_florida">
                                <h2>HOW MUCH DOES DETECTIVE MAKE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It was recorded by the Florida department of Economic Opportunity that an average detective makes in between $64,625 to $72,924 per year, respectively. The highest salary of a Detective that was recorded in 2019 was $83,650 in Miami, Florida.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="career_outlook_for_detective_in_florida">
                                <h2>CAREER OUTLOOK FOR A DETECTIVE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated by BLS, that the job opportunities may increase at the rate of 5-9% in between 2018-28. The employment rate may increase or decrease during the years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-detective-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>DETECTIVE SCHOOLS IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/florida/" className="btn btn-dark">Detective Schools in Florida</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}