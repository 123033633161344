import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Nurse_practitioner.css';
import Nurse_Practitioner from '../images/Nurse-Anesthetist.png';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Nurse_practitioner = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Nurse Pactitioner - Salary, Qualifications, Skills & Reviews  : A Step-by-Step Guide</title>
                    <meta name='description' content={`Considering a career in nursing pactitioner? In this guide, we share the steps to becoming a nurse pactitioner, including education and licensure. Learn how to become a nurse pactitioner today!`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A NURSE PRACTITIONER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Nurse Practitioner</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Nurse Practitioner</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Nurse Practitioner</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Nurse Practitioner</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Nurse Practitioner Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A NURSE PRACTITIONER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A nurse practitioner is a highly skilled registered nurse that has the permission and authority of diagnosis of diseases as well as the prescription of medicine. A nurse practitioner carries out so many different duties related to one’s niche when one is working in one’s workplace. A Nurse Practitioner:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Provide general checkup to the patients</li>
                                        <br />
                                        <li>Prescribe medicines for minor diseases cough, cold, flu and fever</li>
                                        <br />
                                        <li>Prescribe medical tests to the patients</li>
                                        <br />
                                        <li>Analyze the results of the medical tests to find out the disease and prescribe medicines accordingly</li>
                                        <br />
                                        <li>Prescribe medicines for other diseases as well but only after the approval of physician doctor</li>
                                        <br />
                                        <li>Work in many different fields like pediatrics, psychological and mental health niche, geriatrics and many other fields</li>
                                        <br />
                                        <li>Educate the patients about the proper way to administer medicines</li>
                                        <br />
                                        <li>Assist doctors in surgeries</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A NURSE PRACTITIONER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Nurse_Practitioner} alt="STEPS TO BECOME A NURSE PRACTITIONER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A NURSE PRACTITIONER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The job of Nurse Practitioner is highly competitive! That is why, there is a very specific set of requirements which one has to meet if one wants to become a Nurse Practitioner in the future. A Nurse Practitioner:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must secure a High School Diploma with exceptional marks in biology, chemistry and other science subjects</li>
                                        <br />
                                        <li>Must have a Bachelors of Science in Nursing (BSN)</li>
                                        <br />
                                        <li>Should pursue the Bachelors of Science in Nursing (BSN) if one has a diploma or an associate degree in nursing</li>
                                        <br />
                                        <li>Must have the valid Registered Nurse license</li>
                                        <br />
                                        <li>Must have the required work experience of a few years</li>
                                        <br />
                                        <li>Must secure the degree of Master of Science in Nursing (MSN)</li>
                                        <br />
                                        <li>Could also go for Doctor of Nursing Practice (DNP) degree for better job options</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A NURSE PRACTITIONER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think that you have what it takes to become a Nurse Practitioner then we are here to help you plan how to secure your ultimate goal. Here is the step by step guide to teach you how to complete the necessary steps to become a Nurse Practitioner in United States.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        First and foremost, one must complete the High School Diploma by securing very good marks in Sciences, English and Statistics to ensure that all the prerequisites for admission in the baccalaureate program. It is required for every nurse aiming to become a Nurse Practitioner to have a Bachelors of Science in Nursing (BSN). If someone has secured a diploma or an associate degree in nursing then one should study for a few more years to complete the credit hours in order to make the degree equivalent to Bachelors of Science in Nursing (BSN).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Earn The RN License And Work As An RN</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally secured the Bachelors of Science in Nursing (BSN) then one has to work on acquiring the certification from the National Council Licensing Exam for Registered Nurses (NCLEX-RN). It is mandatory for every nurse to have a RN license if one wants to practice as an RN and apply for a job. One must work as an RN at least for a year or two to know more about the various fields that employ nurses.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: JEnter The Master’s Program To Become Nurse Practitioner</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After acquiring some work experience, one should choose the field in which one has to do specialization. One has to do Master of Science in Nursing (MSN) in order to become a Nurse Practitioner in fields like mental health, pediatrics, primary care and other fields. But of one wants to become Psychiatric nurse practitioner then one has to secure Doctor of Nursing Practice (DNP) degree too. After completing the degree, one has to acquire the license of nurse practitioner.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A NURSE PRACTITIONER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes 4 years to complete the Bachelors of Science in Nursing (BSN), then one has to acquire the RN License. After that one could either work as a RN for a year or two and then pursue Master of Science in Nursing (MSN) or directly go for the Master’s degree of 2 to 3 years and then secure license of nurse practitioner. Hence, one becomes a Nurse Practitioner in United States in 6 to 8 years after extensive education and training in the given field.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A NURSE PRACTITIONER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Nurse Practitioner averagely earns $113,930 per annum as stated by the United States Bureau of Labor Statistics. California, Alaska and Hawaii are the states of USA that rewards the Nurse Practitioners with the most handsome wages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}