import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Physician_assistant.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Physician_assistant = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Physician Assistant - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PHYSICIAN ASSISTANT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Physician Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Physician Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Step By Step Guide To Become A Physician Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Time Duration Required To Become A Physician Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Average Annual Earnings Of A Physician Assistant</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Every candidate for the job of physician assistant has to acquire a number of degrees, undergo training programs as well as fulfill different requirements to work as a physical assistant in USA.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <table className="table table-striped border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>Minimum Age Requirement</td>
                                                <td>Must be at least 21 years old</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Education Criteria</td>
                                                <td>Master’s degree in Physician Assistant</td>
                                            </tr>
                                            <tr>
                                                <td>Preferred Major</td>
                                                <td>Medicine, Physician Assistant Degree</td>
                                            </tr>
                                            <tr>
                                                <td>Experience Required to Secure Entry Level Job</td>
                                                <td>Have 3-4 year of experience of working in the medical field as a nurse or a paramedic</td>
                                            </tr>
                                            <tr>
                                                <td>Duration of Training Required</td>
                                                <td>Two to three years</td>
                                            </tr>
                                            <tr>
                                                <td>Other Requirements</td>
                                                <td>Must be intelligent and creative, have great cognitive abilities and must be cordial with the doctors as well as the patients</td>
                                            </tr>
                                            <tr>
                                                <td>Median salary per annum (BLS)</td>
                                                <td>$108,610</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PHYSICIAN ASSISTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The physician assistant have the major duty of diagnosing and treating patients all the while working under the supervision of a physician. They are an integral part of the field of medicine as they provide healthcare facilities to patients at much lower rates than physicians. They work in medical camps and provide healthcare facilities to the patients that are unable to avail state of the art healthcare facilities. A physician assistant:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Chat with the patient and find out the main reason why a person is visiting the doctor</li>
                                        <br />
                                        <li>Note down the medical history of the patient and review it</li>
                                        <br />
                                        <li>Do a thorough checkup of a patient</li>
                                        <br />
                                        <li>Physically examine the injuries, wounds and other physical anomalies of the patients</li>
                                        <br />
                                        <li>Diagnose the disease of the patient</li>
                                        <br />
                                        <li>Write tests and medicine prescriptions</li>
                                        <br />
                                        <li>Study the tests and reports of the patient and decide the next course of action in the patient’s treatment</li>
                                        <br />
                                        <li>Guide the patient when and how to take the prescribed medicines</li>
                                        <br />
                                        <li>Provide medical assistance to the surgeons in the operation rooms</li>
                                        <br />
                                        <li>Provide counselling services to the patient</li>
                                        <br />
                                        <li>Collaborate with other medical healthcare officials on medical cases</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PHYSICIAN ASSISTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There is a constant increase in the demand for physician assistant in the field of medicine due to increase in population. Hence, a lot of people are now aiming to become a physician assistant in the future. If you think you have what it takes to become a physician assistant than you need to follow the specific requirements provided. A physician assistant:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a 4 year Bachelor’s degree in any science field like Biology, Psychology, Chemistry , Biophysics and others</li>
                                        <br />
                                        <li>Must secure 4 year experience as a nurse or paramedic</li>
                                        <br />
                                        <li>Must enroll in the physician assistant (PA) program for the Master’s degree</li>
                                        <br />
                                        <li>Must successfully clear the Physician Assistant National Certifying Exam (PANCE) as one need to have Physician Assistant Certification for practice</li>
                                        <br />
                                        <li>Must renew the certification before it expires</li>
                                        <br />
                                        <li>Must be very intelligent and creative</li>
                                        <br />
                                        <li>Must display a kind behavior with patients and should be able to alleviate their stress and bring them at ease for the check up</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEP BY STEP GUIDE TO BECOME A PHYSICIAN ASSISTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to serve humanity by treating patients and saving their lives then you can take notes from the comprehensive step by step we have compiled for you to follow in order to become a physician assistant.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The candidates need to have a high school diploma with excellent grades in science subjects in order to qualify for the Bachelor’s degree. In addition to that, one needs to have a 4 years Bachelor’s degree in biology, biochemistry, biophysics, psychology or any other degree related to medical field. One needs to obtain excellent GPA in order to enroll in a medical course and pursue working as a medical healthcare officer.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Experience In The Field</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important to obtain at least 3-4 year experience in the medical field working as some sort of a healthcare officer. One could work as a nurse, a paramedic or as an EMT in order to acquire the mandatory in field experience.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Master’s Degree In Physician Assistant</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has secured excellent grades in the Bachelor’s degree as well as have acquired 3-4 year experience in the medical field as a healthcare officer, one is eligible for the Master’s degree. One needs to acquire the 2 year Master’s degree in Physician Assistant. In addition to coursework, the candidate also has to complete 2000 compulsory clinical hours during the 26 months degree. That is why, one should join the internship of a licensed physician to complete these clinical hours in a convenient way.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Certification Of Physician Assistant</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for every physician assistant to have the physician assistant certification. In order to acquire the certification one has to successfully clear the Physician Assistant National Certifying Exam (PANCE) which might take a few months. One can give 6 tries of the test in 6 years, but in case of failure in all 6 times, one would have to repeat a specific abridged course. In order to keep the certification up-to-date one needs to obtain certification again after every 10 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>TIME DURATION REQUIRED TO BECOME A PHYSICIAN ASSISTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one completes the Bachelor’s degree in any medical field related science subject in nearly 4 years. One needs to have at least 4 year experience as a nurse, paramedic or any member of healthcare to be eligible for the Master’s degree in Physician assistant which takes nearly 26 months. After that, it is compulsory to acquire the Physician Assistant certification. One has to give the Physician Assistant National Certifying Exam (PANCE) which takes another 6 months to a year to prepare and successfully clear it. So, we can safely conclude that it takes one approximately 10-11 years to finally become a physician assistant in America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>AVERAGE ANNUAL EARNINGS OF A PHYSICIAN ASSISTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A general physician assistant working in the United States of America earns approximately $108,610 per year as stated on the official website of United States Bureau of Labor Statistics. One should consider working as a physician assistant in American States like Connecticut, Washington and Alaska as these states provide excellent wage rates to the physician assistants working there.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}