import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Guide_to_software_engineer.css';
import software_eng from '../images/software_eng.jpeg';

export const Guide_to_software_engineer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={100} />
            <div className='container'>
                <Helmet>
                    <title>Guide to Become a Software Engineer in {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A SOFTWARE ENGINEER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#guide">How to becoming a software engineer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_software_engineer">Steps To Become A Software Engineer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#bio">Author Bio</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container" id="guide">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Wondering what path to take to become a software engineer in {year}? Look no more. This blog is exactly what you need to understand what it takes to become a software engineer in {year}.
                                    </p>
                                    <p>
                                        If you’re wondering what a software engineer makes annually in the US, the average salary is $108,175 — courtesy Indeed.
                                    </p>
                                    <p>
                                        Now that we have your undivided attention, let’s take you through the steps you need to take to reach this salary figure, and surpass it. Some people seek help to crack their <a style={{ textDecoration: 'underline', fontWeight: '500' }} href="https://www.codinginterview.com/">coding interviews</a>. We’ve got that covered in this article.
                                    </p>
                                    <p>
                                        The matter of fact is that these <a style={{ textDecoration: 'underline', fontWeight: '500' }} href="https://www.educative.io/blog/how-to-become-a-software-engineer">software engineers</a> are the backbone of almost all the technological advancement in all the industries. The digital sector of almost all the industries want these geniuses to do the ‘tech modifications’ they require.
                                    </p>
                                    <p>
                                        For instance, we’ve all used cell phones and smartphones. But why does an iphone stand apart? This is due to the tech Steve Jobs applied into the device, which allowed for a seamless user experience and hence its worldwide popularity.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A SOFTWARE ENGINEER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={software_eng} alt="STEPS TO BECOME A SOFTWARE ENGINEER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_software_engineer">
                                <h2>STEPS TO BECOME A SOFTWARE ENGINEER</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Primarily, you need to have a college degree for computer science. That is one of the basic prerequisites. This’ll allow you to have the required knowledge to start working in the industry.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Next, you need to find a job that’ll give you insights to working in the industry and what companies are usually looking for in software engineers in {year}.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once completed, you now have somewhat working experience. Now you need to set a target of landing a software engineer role with a renowned company.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Traditionally, people had to upskill themselves with a relevant masters degree to take a step-up in your career ladder
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        However, thanks to companies like Educative, you can now take dedicated courses to prepare you for coding interview patterns, which equip you with the relevant tools and tricks to crack any company’s interview.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 6:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Software engineers have to stay updated and get certified while doing so to keep moving ahead in their career path. Upskilling does require a lot of patience, but when it all pans out as you imagined, it’s worth the effort and wait.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 7:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Globally, top coding languages include Python, Java, C++, Golang and Javascript. Specialize yourself within their realms. Online coding platforms offer interactive courses that cover these languages. You can select any you wish to upskill yourself in. Registering yourself for such courses to enable yourself to learn the required skills for coding interviews.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 8:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        For instance, try the <a style={{ textDecoration: 'underline' }} href="https://www.educative.io/courses/grokking-coding-interview-patterns-python">Grokking Coding Interview Patterns in Python</a> and you’ll have a better understanding of what to expect in coding interviews for python. Similarly, you can also go for <a style={{ textDecoration: 'underline' }} href="https://www.educative.io/courses/grokking-coding-interview-patterns-java">Grokking Coding Interview Patterns in Java</a>, if Java programming language is your primary focus.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 9:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Apart from the programming languages, you’d want to align yourself to a specific discipline of the software engineering industry. There are multiple in-demand today, with each offering you to take advantage of different technologies.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <h5 className="font-weight-bold">These are as follows:</h5>
                                    <br /><br />
                                    <ul className="steps_area1">
                                        <li>Front-end engineers — provides a satisfactory user experience (UX) with no issues, errors, or downtime.</li>
                                        <br />
                                        <li>Back-end engineers — build the structure of any software application, website, web services.</li>
                                        <br />
                                        <li>Full-stack engineers — create software and applications, including leadership for developing teams.</li>
                                        <br />
                                        <li>Data engineers — makes data accessible by creating systems for organizations for performance optimization.</li>
                                        <br />
                                        <li>Machine learning (ML) engineers — design and create artificial intelligence (AI) algorithms which are capable enough to learn and make predictions.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="bio">
                                <h2>Author Bio</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        <b>Haseeb Asim</b>
                                        <br />
                                        The author is a Growth Associate in a leading tech company. Loves reading about new technological advancements and spends free time go-karting.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}