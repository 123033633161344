import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Sheriff.css';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Sheriff = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Sheriff - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A SHERIFF
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Sheriff</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Sheriff</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Steps To Become A Sheriff</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Long Does It Take To Become A Sheriff</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">How Much Does A Sheriff Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A SHERIFF</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Sheriffs are law enforcement officers who are elected to keep the law and order in their region by enforcing laws in their counties. When a sheriff is on job, he carries out so many different duties mainly to keep the law and order in the area. The sheriff:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Enforce laws and suppress any sort of crimes and illegal activities in the county</li>
                                        <br />
                                        <li>Carry out arrests of the criminal suspects after securing the arrest warrant</li>
                                        <br />
                                        <li>Ensure to maintain peace and tranquility in the area</li>
                                        <br />
                                        <li>Carry out investigations of the crimes that are reported by the people</li>
                                        <br />
                                        <li>Collect evidences and interview suspects and witness of a reported crime</li>
                                        <br />
                                        <li>Protect the inhabitants of the area as well as their property from any sort of damage</li>
                                        <br />
                                        <li>Distribute funds between various law enforcement departments</li>
                                        <br />
                                        <li>Hire people for the jobs in the law enforcement departments in the area</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A SHERIFF</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a sheriff is definitely one of the most in demands career options in USA. That is why, when it comes to becoming a Sheriff, there are a number of very particular requirements that every candidate is expected to meet. A sheriff:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have the nationality of United States of America and be a permanent citizen as well</li>
                                        <br />
                                        <li>Must be above 18 years of age</li>
                                        <br />
                                        <li>Must be the local resident of the county</li>
                                        <br />
                                        <li>Must have secured a High School Diploma or GED</li>
                                        <br />
                                        <li>Should have an associate or Bachelor’s degree for better career options</li>
                                        <br />
                                        <li>Must have passed the written, physical tests and background check for selection in Police force</li>
                                        <br />
                                        <li>Successfully complete the Police Academy training</li>
                                        <br />
                                        <li>Secure the official Police Certification</li>
                                        <br />
                                        <li>Must have in field work experience of 1 to 5 years</li>
                                        <br />
                                        <li>Have to participate in the elections for Sheriff in the county</li>
                                        <br />
                                        <li>Should legally win the elections</li>
                                        <br />
                                        <li>Usually receives Sheriff training while on the job</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 &&
                                        states.map((item, key) => {
                                            return (
                                                item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                    item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <NavLink to={`/how-to-become-a-sheriff-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`}>{item.state}</NavLink>
                                                    </div>
                                                    : <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state}</NavLink>
                                                    </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>STEPS TO BECOME A SHERIFF</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the step by step guide to help you understand the journey of becoming a Sheriff in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The minimum education to become a Sheriff is a High School Diploma or a GED for a few states but most states prefer the Sheriffs to have higher education. Hence it is preferable if one pursues an associate degree or a Bachelor’s degree in criminology, law, criminal justice or to other related niches.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Apply For Police Officer And Gain Experience</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the education requirements one should apply for Police force when there is an opening available in the force. A candidate must pass all the tests; written, physical, polygraph tests and background check, one gets enrolled in the Police Academy for the mandatory training. The training is usually of 6 months or 20–36 weeks long depending on the state. When one finally graduates from the police academy, one must give the test to secure the Police Officer License.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Run For Sheriff Post</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The most recommended option is to work as a police officer for 1-5 years and then run for the Sheriff Position in the native county. When one finally wins the Sheriff elections, one holds the office for 4 years. It is mandatory for newly elected Sheriffs to get 2 week training from National Sheriffs’ Association (NSA) through National Sheriffs’ Institute (NSI) and then assume the office for 4 years. It is advised for the newly appointed Sheriffs to secure a comprehensive degree like a Bachelor’s degree during the job, if they do not already have one, for better incentives.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A SHERIFF?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes 2 years to successfully complete the associate degree and 4 years to complete the Bachelor’s degree. Then one has to enter the police force by passing all the tests and complete the mandatory 6 month training in the Police Academy. Afterwards, one has to secure the Police Officer License and then serve in the police force for 1-5 years as a police officer before running for the office of Sheriff. So, the estimated time to become a Sheriff in USA is around 5 to 12 years depending on the officer thus it is a lengthy and time consuming process.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>HOW MUCH DOES A SHERIFF USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The data provided by the United States Bureau of Labor Statistics state the median pay of Sheriff to be around $ 58,320 per year. Sheriffs that are appointed in the counties of states like California, New Jersey and Washington earn the best wages in whole of USA.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}