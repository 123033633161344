import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Fbi_agent.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Fbi_agent = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a FBI Agent - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FBI AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#duties_of_a_FBI_agent">Duties Of A FBI Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_FBI_agent">Requirements To Become A FBI Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#steps_to_become_a_FBI_agent">Steps To Become A FBI Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_long_does_it_take_to_become_a_FBI_agent">How Long Does It Take To Become A FBI Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#how_much_does_a_FBI_agent_usually_earn">How Much Does A FBI Agent Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_FBI_agent">
                                <h2>DUTIES OF A FBI AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Federal Bureau of Investigation (FBI) special agents work directly for the officials in the federal government and carries out investigations for the government. A FBI agent has to undertake many different as well as complex cases while working as a FBI agent foe the country. A FBI agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Locate the basic issues in a case and investigate it</li>
                                        <br />
                                        <li>Collect evidences and file them in the evidence section</li>
                                        <br />
                                        <li>Interview witnesses as well as the possible suspects of the crime</li>
                                        <br />
                                        <li>Record their testimonies with a tape recorder or camera for future reference</li>
                                        <br />
                                        <li>Compile a report about the progress of the case and the findings</li>
                                        <br />
                                        <li>Cross check the data collected via evidences and testimonies with the data already present in the FBI databases</li>
                                        <br />
                                        <li>Run the fingerprints, DNA samples and dental records in the FBI databases to gather information about the suspect</li>
                                        <br />
                                        <li>Makes a detailed reports about the whole case when the case is finally solved</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_FBI_agent">
                                <h2>REQUIREMENTS TO BECOME A FBI AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a FBI agent is a very competitive feat as every year thousands of people apply for the job in nearly every state of USA. That is why, there are some very peculiar requirements that every candidate for the job has to meet in every condition. The FBI agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States</li>
                                        <br />
                                        <li>Must be at least 23 and maximum 36 years old at the time of selection</li>
                                        <br />
                                        <li>Must retire from the job at the age of 57 years</li>
                                        <br />
                                        <li>Must have a bachelor’s degree in crime related fields</li>
                                        <br />
                                        <li>Must have the squeaky clean background so that one can get the clearance of Top Secret SCI (Sensitive Compartmented Information)</li>
                                        <br />
                                        <li>Must fulfill all the clauses in the FBI Drug Policy</li>
                                        <br />
                                        <li>Must complete the training of FBI Academy at Quantico, Virginia with best physical fitness</li>
                                        <br />
                                        <li>Must have a valid CDL as well as have the minimum six months experience of driving vehicles</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_FBI_agent">
                                <h2>STEPS TO BECOME A FBI AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you have the desired abilities to pursue the career of a FBI agent then here is the step by step guide that we have assembled for your aid.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for every FBI agent to have a 4 years Bachelor’s degree in criminal justice, criminology or other fields related to law enforcement.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Apply For The Job And Successfully Clears The Tests</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally graduated from the college with the degree, one has to look for the news of job opening at the official website. When there is a vacancy preset, apply online for the job. The online application is reviewed and then candidates are invited to take the Phase I test which tests the cognitive capabilities as well as sense of reasoning of the candidates.
                                        <br />
                                        When one passes the Phase I test, one moves onto the Phase II test which is a panel interview conducted by group of skilled special agents. Then one has to successfully clear the Fitness test as well as undergo thorough background check to secure Top Secret SCI (Sensitive Compartmented Information) clearance
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Training At FBI Academy At Quantico, Virginia</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Then one gets enrolled in the FBI Academy at Quantico, Virginia for 21 weeks training. When one graduates from the academy one joins the field as an in-field trainee to acquire the 18 months of compulsory in-field experience.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_FBI_agent">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FBI AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes four nearly years to secure the Bachelor’s degree in fields related to law enforcement. Then one has to pass the entrance tests including the written, physical, background and polygraph tests that could take 6 months to a year. Then one gets enrolled in the FBI Academy for FBI agent training which expands to 21 weeks. Then one has to take 18 months of the in-field training to acquire the mandatory hands-on experience.
                                        Sometimes, an agent also have to pursue a Master’s degree as well to open the doors of better job incentives. So, it is safe to say that in United States of America one can become a FBI agent in 6 to 8 years
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_FBI_agent_usually_earn">
                                <h2>HOW MUCH DOES A FBI AGENT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics estimates the annual average pay of a FBI agent to be somewhere around $81,920. The salary is same for people working in all the states of US as FBI is the department that falls under the supervision of the federal government of USA.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}