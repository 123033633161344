import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Forensic_scientist_in_new_york.css';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist_in_new_york = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=new york")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=new york&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist In New York - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST IN NEW YORK
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_forensic_scientist_in_New_York">Responsibilities Of A Forensic Scientist In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_forensic_scientist_in_New_York">Requirements To Become A Forensic Scientist In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_scientist_in_New_York">Steps To Become A Forensic Scientist In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Forensic_Scientist_in_New_York">How Long Does It Take To Become A Forensic Scientist In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_forensic_scientist_make_in_New_York">How Much Does A Forensic Scientist Make In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_for_Forensic_Scientists_in_New_York">Career Outlook For Forensic Scientists In New York</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Forensic scientists in New York are the experts concerned with the identification, collection, analysis and prevention of physical evidence during a criminal investigation. They communicate with the law enforcement and give/provide legal statements against a criminal in court cases. A forensic scientist either identify and collect evidence at the crime scene or stay in laboratory to analyse and preserve the said evidence in New York City.
                                        <br />
                                        A brief and precise information about this career is given below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_forensic_scientist_in_New_York">
                                <h2>RESPONSIBILITIES OF A FORENSIC SCIENTIST IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Identification and Collection of physical evidence</li>
                                        <br />
                                        <li>Interpretation of collected evidence</li>
                                        <br />
                                        <li>Preservation of the analysed evidence</li>
                                        <br />
                                        <li>To give legal statements against the criminal in court cases</li>
                                        <br />
                                        <li>Communication with law enforcement</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_forensic_scientist_in_New_York">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A valid NYC’s license and US citizenship</li>
                                        <br />
                                        <li>Bachelor’s degree program</li>
                                        <br />
                                        <li>Optional Associate/Master’s degree program</li>
                                        <br />
                                        <li>Clear criminal record and drug test</li>
                                        <br />
                                        <li>Attributes like analytical and communication skills, detail-oriented and critical thinking.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_scientist_in_New_York">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a forensic scientist in New York, the minimal educational requirement is a 4-years bachelor’s degree program that must be in science subjects such as forensic science, anthropology, biology and chemistry. The 4-year degree program helps the candidate in improving and building of analytical and communicating skills, critical thinking, composure and logical reasoning which are necessary for the position of a forensic scientist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get Experience For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is important to obtain some professional experience and guidance before applying for a vacancy. In New York, the institutes offering bachelor’s degree program for candidates also offer holds placement offices where one can get help in look for a suitable place for internship. This internship aids the candidate to take an inside look of the professional life of a forensic scientist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Consider A Master’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Holding a master’s degree in subjects like forensic statistics, crime scene investigation, criminology and material analysis can assist a candidate in securing higher and vital positions in the forensic department of New York. Some positions like Forensic Expert or Forensic Technician asks for a master degree program as an educational requirement.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Find A Job In New York</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once the educational requirements are fulfilled, the candidate is eligible to apply for the job in New York where ones complete the initial screening before getting appointed which includes an interview, background check and a drug test. Candidates can find jobs in New York’s state or private labs as well as in the law enforcing department.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Consider Getting Certified</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Obtaining a certificate can help the candidate in securing higher positions such as crime laboratory analyst, forensic engineer and forensic expert. In New York, there are several institutions that holds the authority to give certificates. The candidate must hold at least 2-3 years or more experience before applying for certification exam.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Forensic_Scientist_in_New_York">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time frame for becoming forensic scientist in New York consists of a 4-years bachelor’s degree, a 2-3 years associate/ master degree program, 3-4 months of internship along with 5-6 months for the job hunt. The time limit may increase or decrease accordingly with the applicant.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_forensic_scientist_make_in_New_York">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST MAKE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average salary for a forensic scientist in New York is about $58,703 per year, whereas it goes upto 71,480 a year, respectively. The increase or decrease in the wages depends on the working hours and position.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_for_Forensic_Scientists_in_New_York">
                                <h2>CAREER OUTLOOK FOR A FORENSIC SCIENTIST IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that the job opportunities for forensic scientists may increase upto 17-19% in New York in between 2016-26. Whereas another survey predicts that it may go upto 20% with 110 new job offers in next ten years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-forensic-scientist-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FORENSIC SCIENTIST SCHOOLS IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/new-york/" className="btn btn-dark">Forensic Scientists Schools in New York</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}