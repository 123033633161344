import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import './GetSchoolsByState.css';

export const GetSchoolsByState = () => {
    const navigate = useNavigate();
    const parameter = useParams();
    parameter.parameter = parameter.parameter.replace(/-/g, ' ').trim();
    ////console.log(parameter.parameter);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    const [progress, setProgress] = useState(50);
    const [schools, setschools] = useState([]);
    const [schoolserr, setschoolserr] = useState(0);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    const [count, setcount] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=" + parameter.parameter + "&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                if (response.data.length == 0 && schools.length == 0) {
                    //window.location.href = '/'
                    setProgress(100)
                    setschoolserr(1)
                    navigate('/')
                } else {
                    setschools(pre => [...pre, ...response.data])
                    setlength(response.data.length)
                    setProgress(100)
                    setcount((c) => c + 1)
                }
            })
            .catch(err => {
                //////console.log(err);
                setProgress(100)
                setschoolserr(1)
            })
    }, [pages]);
    ////console.log(schools);
    ////console.log(length);

    useEffect(() => {
        if (schools.length > 0) {
            if (length > 0) {
                setpages((c) => c + 1)
            }
        }
    }, [count]);

    const [value, setvalue] = useState("");
    const [sfound, setfound] = useState(0);
    const [uniname, setuniname] = useState([]);
    useEffect(() => {
        if (value != "") {
            axios.get(Base_API_URL + "/api/Home/GetSearchUniData?Name=" + value)
                .then(response => {
                    if (response.data.length > 0) {
                        setuniname(response.data)
                    } else {
                        setfound(1)
                    }
                })
                .catch(err => {
                    setfound(1)
                })
        } else {
            setfound(2)
        }
    }, [value])
    // //console.log(value)
    // //console.log(sfound)
    // //console.log(uniname)

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>Top Schools In {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} {year} | {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Schools Ranking</title>
                    <meta name='description' content={`Discover the top officially recognized Schools in ` + parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); }) + ` (United States) ranked by the ` + year + `  School Ranking.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                {
                    schoolserr == 1 ? <div className='font-weight-bold text-center'><h4>Oop! 404 Page Not Found</h4></div> :
                        <>
                            <div className="container bg-dark text-white font-weight-bold">
                                <div className="row p-2" style={{ fontSize: '150%' }}>
                                    TOP SCHOOLS IN {parameter.parameter.toUpperCase()}
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="container p-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                                        <br />
                                        <div className="container border p-4 d-lg-block d-none" style={{ backgroundColor: 'whitesmoke' }}>
                                            <h5 className="font-weight-bold">Search by States</h5>
                                            <hr />
                                            <div className="row">
                                                <ul id="states">
                                                    <li><NavLink to={`/alabama/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Alabama</NavLink></li>
                                                    <li><NavLink to={`/alaska/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Alaska</NavLink></li>
                                                    <li><NavLink to={`/arizona/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Arizona</NavLink></li>
                                                    <li><NavLink to={`/arkansas/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Arkansas</NavLink></li>
                                                    <li><NavLink to={`/california/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>California</NavLink></li>
                                                    <li><NavLink to={`/colorado/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Colorado</NavLink></li>
                                                    <li><NavLink to={`/connecticut/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Connecticut</NavLink></li>
                                                    <li><NavLink to={`/delaware/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Delaware</NavLink></li>
                                                    <li><NavLink to={`/district-of-columbia/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>District of Columbia</NavLink></li>
                                                    <li><NavLink to={`/florida/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Florida</NavLink></li>
                                                    <li><NavLink to={`/georgia/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Georgia</NavLink></li>
                                                    <li><NavLink to={`/hawaii/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Hawaii</NavLink></li>
                                                    <li><NavLink to={`/idaho/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Idaho</NavLink></li>
                                                    <li><NavLink to={`/illinois/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Illinois</NavLink></li>
                                                    <li><NavLink to={`/indiana/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Indiana</NavLink></li>
                                                    <li><NavLink to={`/iowa/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Iowa</NavLink></li>
                                                    <li><NavLink to={`/kansas/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Kansas</NavLink></li>
                                                    <li><NavLink to={`/kentucky/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Kentucky</NavLink></li>
                                                    <li><NavLink to={`/louisiana/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Louisiana</NavLink></li>
                                                    <li><NavLink to={`/maine/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Maine</NavLink></li>
                                                    <li><NavLink to={`/maryland/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Maryland</NavLink></li>
                                                    <li><NavLink to={`/massachusetts/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Massachusetts</NavLink></li>
                                                    <li><NavLink to={`/michigan/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Michigan</NavLink></li>
                                                    <li><NavLink to={`/minnesota/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Minnesota</NavLink></li>
                                                    <li><NavLink to={`/mississippi/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Mississippi</NavLink></li>
                                                    <li><NavLink to={`/missouri/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Missouri</NavLink></li>
                                                    <li><NavLink to={`/montana/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Montana</NavLink></li>
                                                    <li><NavLink to={`/nebraska/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Nebraska</NavLink></li>
                                                    <li><NavLink to={`/nevada/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Nevada</NavLink></li>
                                                    <li><NavLink to={`/new-hampshire/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>New Hampshire</NavLink></li>
                                                    <li><NavLink to={`/new-jersey/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>New Jersey</NavLink></li>
                                                    <li><NavLink to={`/new-mexico/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>New Mexico</NavLink></li>
                                                    <li><NavLink to={`/new-york/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>New York</NavLink></li>
                                                    <li><NavLink to={`/north-carolina/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>North Carolina</NavLink></li>
                                                    <li><NavLink to={`/north-dakota/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>North Dakota</NavLink></li>
                                                    <li><NavLink to={`/ohio/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Ohio</NavLink></li>
                                                    <li><NavLink to={`/oklahoma/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Oklahoma</NavLink></li>
                                                    <li><NavLink to={`/oregon/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Oregon</NavLink></li>
                                                    <li><NavLink to={`/pennsylvania/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Pennsylvania</NavLink></li>
                                                    <li><NavLink to={`/rhode-island/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Rhode Island</NavLink></li>
                                                    <li><NavLink to={`/south-carolina/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>South Carolina</NavLink></li>
                                                    <li><NavLink to={`/south-dakota/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>South Dakota</NavLink></li>
                                                    <li><NavLink to={`/tel/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Tel</NavLink></li>
                                                    <li><NavLink to={`/tennessee/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Tennessee</NavLink></li>
                                                    <li><NavLink to={`/texas/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Texas</NavLink></li>
                                                    <li><NavLink to={`/utah/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Utah</NavLink></li>
                                                    <li><NavLink to={`/vermont/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Vermont</NavLink></li>
                                                    <li><NavLink to={`/virginia/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Virginia</NavLink></li>
                                                    <li><NavLink to={`/washington/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Washington</NavLink></li>
                                                    <li><NavLink to={`/west-virginia/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>West Virginia</NavLink></li>
                                                    <li><NavLink to={`/wisconsin/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Wisconsin</NavLink></li>
                                                    <li><NavLink to={`/wyoming/`} onClick={() => { setpages(1); setschools([]); setProgress(50) }}>Wyoming</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="container mt-md-0 mt-1 p-0">
                                            <div className="row justify-content-center border p-md-4 pt-3 pb-3 ml-0 mr-0" style={{ backgroundColor: 'whitesmoke' }}>
                                                <div className="col-12">
                                                    <input className="form-control" onKeyUp={(e) => { setvalue(e.target.value); setfound(0) }} style={{ width: '100%' }} placeholder="Search University...." />
                                                    {
                                                        sfound != 2 &&
                                                        <ul id="schools_list" className="border p-0 text-left">
                                                            {
                                                                sfound != 1 ?
                                                                    uniname.map((u =>
                                                                        <li><NavLink to={`/schools/` + u.uniName.replace(/\s/g, "-").toLowerCase()} className='btn btn-secondary w-100 border-0 btnuni m-1'>{u.uniName}</NavLink></li>
                                                                    ))
                                                                    : <li><button className='btn m-1 w-100 btn-secondary border-0' disabled>No School Found</button></li>
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="container" id="div1">
                                            {
                                                schools.length > 0 ?
                                                    schools.map((a =>
                                                        <NavLink to={`/schools/` + a.uniName.replace(/\s/g, "-").toLowerCase()}>
                                                            <div className='row'>
                                                                <div className="col-12 sec_count">
                                                                    <div className="card mb-3" style={{ width: '100%', backgroundColor: 'whitesmoke', fontFamily: 'Times New Roman' }}>
                                                                        <div className="row no-gutters">
                                                                            <div className="col-5 col-md-2 text-center my-auto ml-md-0">
                                                                                <img src={a.image} alt={a.uniName} style={{ width: '100%', cursor: 'pointer', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} />
                                                                            </div>
                                                                            <div className="col-7 col-md-7 mt-auto mb-auto">
                                                                                <div className="card-body text-md-left text-center p-md-2 p-1">
                                                                                    <a className="card-title mb-0 font-weight-bold text-md-left text-center" style={{ cursor: 'pointer', fontSize: '95%', lineHeight: '10%', textDecoration: 'none', color: 'cornflowerblue' }}>{a.uniName}</a>
                                                                                    <a><p className="card-text mb-0 text-md-left text-center" style={{ cursor: 'pointer', fontSize: '15px', color: 'black' }}><b>Rank: </b><small className="text-muted">{a.rank}</small></p></a>
                                                                                    <a><p className="card-text text-md-left text-center" style={{ cursor: 'pointer' }}><FaMapMarkerAlt style={{ color: 'red' }} /> <small className="text-muted">{a.address}</small ></p ></a>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-3 mt-auto mb-auto text-right">
                                                                                <NavLink to="/forms" className="btn btn-dark font-weight-bold" style={{ width: '100%', color: 'white', borderRadius: '30px' }}>Request Info</NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    ))
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </>
                }
                <br />
                <br />
            </div>
        </>
    )
}