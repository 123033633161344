import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import './Index.css';
import img1 from '../images/1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';
import img5 from '../images/5.jpg';

export const Index = () => {
    window.scrollTo(0,0)
    let d = new Date();
    let year = d.getFullYear().toString();

    window.history.pushState({}, null, '/');

    const renderCarousal = () => {
        return (
            <Carousel data-bs-theme="dark" controls={false} indicators={false}>
                <Carousel.Item>
                    <img className="d-block w-100" src={img1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img2} alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img3} alt="Third slide" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img4} alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={img5} alt="Fifth slide" />
                </Carousel.Item>
            </Carousel>
        )
    }

    return (
        <>
            {/* <LoadingBar color='blue' height={5} progress={100} /> */}
            <div className='container'>
                <Helmet>
                    <title>Find Your Dream Career | StepstoBecomea.com - {year}</title>
                    <meta name="description" content="It is everyone’s ultimate dream to work the job of his desire and earn handsome wages while doing it so that one can enjoy the wonders of life. Everyone can work a job to make both ends meet, but it takes only a dedicated individual to make a career out of it. Pursuing a career"></meta>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOME
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {renderCarousal()}
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify" style={{ fontWeight: '600' }}>
                                It is everyone’s ultimate dream to work the job of his desire and earn handsome wages while doing it so that one can enjoy the wonders of life. Everyone can work a job to make both ends meet, but it takes only a dedicated individual to make a career out of it. Pursuing a career is not a smooth sailing because if you want to excel in your career then you need to have a passion for what you do. In fact, passion is the very meter that differentiates a mere job from a career!
                                We can help you in finding your passion so that you can make a career out of it and change your life. We provide you with comprehensive guides about how to choose a profession. Our ultimate focus is on the following points:
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="steps_area1">
                                <li>We educate you by mentioning the major requirements for a specific profession</li>
                                <br />
                                <li>We describe the step by step procedure of entering the specific profession</li>
                                <br />
                                <li>We have clearly mentioned the tests and trainings required for specific profession</li>
                                <br />
                                <li>We also shed light on how to work hard enough to raise ranks and secure promotions in the profession</li>
                                <br />
                                <li>We provide clear information about the time duration to complete the required degree, training or internship for the specific profession</li>
                                <br />
                                <li>We have disclosed the latest average median per annum salary of the specific profession using the data provided by the United States Bureau of Labor Statistics</li>
                                <br />
                                <li>We have also highlighted some of the states that pay better wages to the professionals in comparison to other states of United States.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-justify" style={{ fontWeight: '600' }}>
                                We have discussed the comprehensive guides about professions from a lot of different niches. Each article provides the reader with
                                all of the basic and pivotal information about pursuing a specific profession and excelling in it.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-md-4 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Arts & Humanities</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/animal-cop/`}>HOW TO BECOME AN ANIMAL COPE</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/business-analyst/`}>HOW TO BECOME A BUSINESS ANALYST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/chef/`}>HOW TO BECOME A CHEF</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/hedge-fund-manager/`}>HOW TO BECOME A HEDGE FUND MANAGER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/librarian/`}>HOW TO BECOME A LIBRARIAN</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/loan-officer/`}>HOW TO BECOME A LOAN OFFICER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/paralegal/`}>HOW TO BECOME A PARALEGAL</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/park-ranger/`}>HOW TO BECOME A PARK RANGER</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-4 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Medical</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/cna/`}>HOW TO BECOME A CNA</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/cosmetologist/`}>HOW TO BECOME A COSMETOLOGIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/medical-assistant/`}>HOW TO BECOME A MEDICAL ASSISTANT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/midwife/`}>HOW TO BECOME A MIDWIFE</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/nurse/`}>HOW TO BECOME A NURSE</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/nurse-anesthetista/`}>HOW TO BECOME A NURSE ANESTHETISTA</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/phlebotomist/`}>HOW TO BECOME A PHLEBOTOMIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/pharmacist/`}>HOW TO BECOME A PHARMACIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/physician-assistant/`}>HOW TO BECOME A PHYSICIAN ASSISTANT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/registered-nurse/`}>HOW TO BECOME A REGISTERED NURSE</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/surgeon/`}>HOW TO BECOME A SURGEON</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/pastoral-counselor/`}>HOW TO BECOME A PASTORAL COUNSELOR</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-4 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Education</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/diesel-mechanic/`}>HOW TO BECOME A DIESEL MECHANIC</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/electrical-engineer/`}>HOW TO BECOME A ELECTRICAL ENGINEER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/software-developer/`}>HOW TO BECOME A SOFTWARE DEVELOPER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/guide-to-become-software-engineer/`}>HOW TO BECOME A SOFTWARE ENGINEER</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Public Service/ Legal</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/border-patrol-officer/`}>HOW TO BECOME A BORDER PATROL OFFICER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/criminologist/`}>HOW TO BECOME A CRIMINOLOGIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/court-reporter/`}>HOW TO BECOME A COURT REPORTER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/cia-agent/`}>HOW TO BECOME A CIA AGENT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/crime-scene-investigator/`}>HOW TO BECOME A CRIME SCENE INVESTIGATOR</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/cosmetologist/`}>HOW TO BECOME A COSMETOLOGIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/detective/`}>HOW TO BECOME A DETECTIVE</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/data-analyst/`}>HOW TO BECOME A DATA ANALYST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/fbi-agent/`}>HOW TO BECOME A FBI AGENT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/forensic-scientist/`}>HOW TO BECOME A FORENSIC SCIENTIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/parole-officer/`}>HOW TO BECOME A PAROLE OFFICER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/police-officer/`}>HOW TO BECOME A POLICE OFFICER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/private-investigator/`}>HOW TO BECOME A PRIVATE INVESTIGETOR</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/real-estate-agent/`}>HOW TO BECOME A REAL ESTATE AGENT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/realtor/`}>HOW TO BECOME A REALTOR</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/sheriff/`}>HOW TO BECOME A SHERIFF</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Science/ Tech</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/forensic-scientist/`}>HOW TO BECOME A FORENSIC SCIENTIST</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/cpa/`}>HOW TO BECOME A CPA</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/detective/`}>HOW TO BECOME A DETECTIVE</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 mb-md-0 mb-2">
                            <div className="card" style={{ width: '100%', borderRadius: '10px', backgroundColor: '#F69534', height: '340px' }}>
                                <h4 className="card-title pt-2 pl-3" style={{ color: 'white', fontWeight: '600' }}>Vocational</h4>
                                <ul style={{ backgroundColor: '#293C3C', color: 'white' }} className="steps_area2">
                                    <li><NavLink to={`/statistician/`}>HOW TO BECOME A STATISTICIAN</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/truck-driver/`}>HOW TO BECOME A TRUCK DRIVER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/welder/`}>HOW TO BECOME A WELDER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/writer/`}>HOW TO BECOME A WRITER</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/accountant/`}>HOW TO BECOME A ACCOUNTANT</NavLink></li>
                                    <hr />
                                    <li><NavLink to={`/actuary/`}>HOW TO BECOME A ACTUARY</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">HEALTH AND MEDICINE</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Does the thought of saving lives appeal to you? Do you want to become a messiah for the people
                        in need of your medical assistance and expertise? Then join us in the journey to help you understand
                        how one becomes a doctor, a physician, a surgeon and an anesthesiologist.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">EDUCATION</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you want to change the future of our country by teaching the youth today? Does the thought of
                        teaching in a prestigious school, college or university sounds like your dream job? We have the
                        perfect tips for you to channel your passion in education into something productive.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">BUSINESS</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you envision yourself as a CEO of a successful company in the near future? Do you imagine that
                        you will be the biggest tycoon of Wall Street someday? If yes, then join us to help you educate
                        the process of becoming a businessman.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">ENGINEERING</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Does the thought of constructing new and exciting things motivate you? Is becoming an electrical,
                        mechanical, civil or aeronautical engineer your passion? Then let us guide you to use your
                        energies in the best possible way to channel your passion.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">ART AND ARTISTS</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Is there an artist, an actor or actress, a writer or a painter hidden inside you? Can you envision
                        yourself winning an Oscar, Emmy or a Pulitzer in the future? If the answer is affirmative, then
                        follow our direction to reach your ultimate goal.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">LAW ENFORCEMENT</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you want to serve your country in the best way possible by becoming a law enforcement agent? Do
                        you want to become a police officer, an army officer or CIA agent in the near future? Then follow
                        our comprehensive guides to secure your dream job.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">SCIENCE AND TECHNOLOGY</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you want to unravel the secrets of the universe? Does the thought of becoming a chemist, physicist
                        or a scientist sound like a dream coming true? If yes, then help us dictate you the process of making
                        a career out of your passion.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">VOCATIONAL</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you think you have the set of skills to become a barber, mechanic, welder, diesel mechanic and technician
                        or a builder? Then let us educate you how to enter the field and widen the range of your expertise.
                    </p>
                    <br />
                    <div className="row heading_area">
                        <h4 className="font-weight-bold">AUTOMOBILE DRIVERS</h4>
                    </div>
                    <p style={{ fontWeight: '600' }}>
                        Do you think you have what it takes to become a commercial or a professional truck driver? Do you think you can
                        transport goods by driving long routes? Then you need to let us help you to achieve your dream job very easily.
                        The best career is the one from which you do not have to take a vacation. Hence, you should be very careful to
                        find the job that is made for your temperament, personality and specific set of skills.
                    </p>
                </div>
                <br />
            </div>
        </>
    )
}