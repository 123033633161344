import React from 'react'
import { NavLink } from "react-router-dom";
import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp";

export default function Footer() {
    const d = new Date();
    let year = d.getFullYear().toString();

    let top = {
        display: 'none',
        position: 'fixed',
        bottom: '70px',
        right: '30px',
        zIndex: '99',
        fontSize: '18px',
        backgroundColor: '#343A40',
        color: 'white',
        cursor: 'pointer',
        padding: '15px 20px',
        borderRadius: '50px',
    }

    let footer = {
        bottom: 0,
        width: '100%',
        whiteSpace: 'nowrap',
        lineHeight: '60px',
        backgroundColor: '#F7F7F7'
    }

    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
        if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
            document.getElementById("top").style.display = "block";
        } else {
            document.getElementById("top").style.display = "none";
        }
    }

    function scrollTop() {
        window.scrollTo(0, 0)
    }

    return (
        <>
            <button id='top' onClick={scrollTop} title="Go To Top" style={top}><FaArrowUp/></button>
            <div className="container-fluid pt-5" style={{ backgroundColor: '#F7F7F7' }}>
                <div className="row justify-content-center">
                    <div className="col-md-3 col-11">
                        <ul style={{ listStyleType: 'none', padding: '0' }} className="lst">
                            <h5 style={{ fontWeight: '640' }}>Arts & Humanities</h5>
                            <hr />
                            <li><NavLink to={`/cia-agent/`}>How to Become a CIA agent</NavLink></li>
                            <hr />
                            <li><NavLink to={`/crime-scene-investigator/`}>How to Become a Crime Scene Investigator</NavLink></li>
                            <hr />
                            <li><NavLink to={`/criminologist/`}>How to Become a Criminologist</NavLink></li>
                            <hr />
                            <li><NavLink to={`/detective/`}>How to Become a Detective</NavLink></li>
                            <hr />
                            <li><NavLink to={`/fbi-agent/`}>How to become a FBI Agent</NavLink></li>
                            <hr />
                        </ul>
                    </div>
                    <div className="col-md-3 col-11 mt-md-0 mt-4">
                        <ul style={{ listStyleType: 'none', padding: '0' }} className="lst">
                            <h5 style={{ fontWeight: '640' }}>Medical</h5>
                            <hr />
                            <li><NavLink to={`/court-reporter/`}>How to Become a Court Reporter</NavLink></li>
                            <hr />
                            <li><NavLink to={`/crime-scene-investigator/`}>How to Become a Crime Scene Investigator</NavLink></li>
                            <hr />
                            <li><NavLink to={`/forensic-scientist/`}>How to Become a Forensic Scientist</NavLink></li>
                            <hr />
                            <li><NavLink to={`/police-officer/`}>How to become a Police Officer</NavLink></li>
                            <hr />
                            <li><NavLink to={`/private-investigator/`}>How to become a Private Investigator</NavLink></li>
                            <hr />
                        </ul>
                    </div>
                    <div className="col-md-3 col-11 mt-md-0 mt-4">
                        <ul style={{ listStyleType: 'none', padding: '0' }} className="lst">
                            <h5 style={{ fontWeight: '640' }}>Public Service/Legal</h5>
                            <hr />
                            <li><NavLink to={`/animal-cop/`}>How to Become a Animal Cop</NavLink></li>
                            <hr />
                            <li><NavLink to={`/border-patrol-officer/`}>How to become a Border Patrol Officer</NavLink></li>
                            <hr />
                            <li><NavLink to={`/business-analyst/`}>How to Become a Business Analyst</NavLink></li>
                            <hr />
                            <li><NavLink to={`/chef/`}>How to Become a Chef</NavLink></li>
                            <hr />
                            <li><NavLink to={`/data-analyst/`}>How to become a Data Analyst</NavLink></li>
                            <hr />
                        </ul>
                    </div>
                </div>
                <br />
            </div>

            <footer className="footer text-muted" style={footer}>
                <div className="container text-center">
                    <small className="font-weight-bold">CopyRights &copy; {year}, All Rights Reserved</small>
                </div>
            </footer>
        </>
    )
}
