import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Cosmetologist.css';
import cosmetologist from '../images/cosmetologist-12.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Cosmetologist = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Cosmetologist - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A COSMETOLOGIST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">What Are The Duties Of A Cosmetologist?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Cosmetologist?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Cosmetologist?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Cosmetologist?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Cosmetologist Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>WHAT ARE THE DUTIES OF A COSMETOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There has been major development in the field of cosmetology over the last few years. A cosmetologist carries out various duties; here is a list of duties that a cosmetologist usually carries out. A cosmetologist usually:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Shampoo and then stylishly cut as well as style the client’s hair</li>
                                        <br />
                                        <li>Color and add highlights in the client’s hair</li>
                                        <br />
                                        <li>Give manicure as well as pedicures</li>
                                        <br />
                                        <li>Do different types of makeups</li>
                                        <br />
                                        <li>Provide clients with different skin treatments</li>
                                        <br />
                                        <li>Give facials as well as provide massage treatments to the clients</li>
                                        <br />
                                        <li>Teach clients how to use their cosmetics</li>
                                        <br />
                                        <li>Decorate nails with nail paints</li>
                                        <br />
                                        <li>Apply the acrylic nails and decorate them</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A COSMETOLOGIST</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={cosmetologist} alt="STEPS TO BECOME A COSMETOLOGIST" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A COSMETOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here are some of the requirements that every individual aiming to become a cosmetologist has to fulfill. Any candidate aiming to become a professional cosmetologist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 16 years of age</li>
                                        <br />
                                        <li>Have a High School Diploma of GED</li>
                                        <br />
                                        <li>Have good interactive skills</li>
                                        <br />
                                        <li>Should be creative and think of new things outside the box</li>
                                        <br />
                                        <li>Must be fit enough to stay on the feet for long periods</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A COSMETOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to know more about the process of becoming a cosmetologist then here is the step by step guide that can aid you.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Basic Education Requirements</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every candidate must be at least 16 years old, but the age requirement may vary according to the law of the particular state. The candidate must have at least a High School Diploma or any equivalent degree to enroll in the cosmetology school.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Graduate From A Cosmetology School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The course duration of a cosmetology school is around nine to fifteen months, but it may vary from state to state according to their specific laws. The cost of attending a cosmetology course is around $10,000 to $20,000. The price may vary depending on the location of the school and the services provided by the school. The type of course and number of credit hours also plays an important role in the tuition fee.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Obtain License And Specialize In A Cosmetology Field</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully completing the cosmetology course from a cosmetology school, you must prepare to pass the cosmetology test. The cosmetology test includes a written test as well as a practical test which focuses on hair and makeup skills as well as skin treatments. After passing the cosmetology test, you will get your license and can openly practice as a cosmetologist.
                                        <br />
                                        If you want to cater to the clients of a specific niche then you can also specialize in a particular cosmetology field. You can become a beautician, a hairstyle, a nail technician and can pursue a career as a spa manager and many others office jobs as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Look For A Job Or Open Your Own Business</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully getting the cosmetology license, you can apply for job or as an intern at various beauty shops and makeup parlors to learn the ropes of the job. Here you will actually get the chance to practically implement the skills that you have learnt in the cosmetology school. When you have polished your cosmetology skills, you can get promoted to a higher position which easily provides better wages. You can also put your skills to a better use by opening your own business as a cosmetologist, catering to all sorts of clients or the clients of your specialized niche only.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A COSMETOLOGIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        You can enroll in the cosmetology school after doing High School Degree. In addition to that, some states allow high school students to take extra credit hours in cosmetology as well. The course to become a cosmetologist usually spans over nine to fifteen months. But generally it takes around 1,000 to 2,100 training hours, averagely 1,600 training hours, to become a professional cosmetologist in United States. The time duration of becoming a cosmetologist primarily varies from one another because of the individual laws and regulation of the states of United States.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A COSMETOLOGIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Cosmetology is a vast field that includes barbers, makeup artists as well as manicurist/pedicurist. All these cosmetologists cater to different needs of clients; hence their average yearly incomes are different from each other. The data provided by United States Bureau of Labor Statistics state that a professional manicurist/pedicurist averagely earns around $23,230 per year. On the other hand a professional makeup artist usually earns
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}