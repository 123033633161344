import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Firefighter_in_california.css';
import Firecalifornia from '../images/Firecalifornia.png';
import { NavLink } from 'react-router-dom';

export const Firefighter_in_california = () => {
    debugger
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetStatesByCity?state=california")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Firefighter in California - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FIREFIGHTER IN CALIFORNIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Responsibilities Of A Firefighter In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Firefighters Of California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Firefighter In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long It Takes To Become A Firefighter In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Firefighter Make In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Career Outlook For Firefighters In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l7">Cities</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l8">FireFighter Schools in California</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Firefighters in California are the ones who respond to fires, emergencies and other accidents.
                                        If you’re interested in becoming a firefighter in California, all the fundamental knowledge you may need is given below:
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>RESPONSIBILITIES OF A FIREFIGHTER IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Responding to the variety of accident calls</li>
                                        <br />
                                        <li>Provide safety to the lives and properties of the citizens</li>
                                        <br />
                                        <li>Extinguish fire, water-flooding, biological and chemical hazards</li>
                                        <br />
                                        <li>EHandle medical emergencies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A FIREFIGHTER IN CALIFORNIA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Firecalifornia} alt="HOW TO BECOME A FIREFIGHTER IN CALIFORNIA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A FIREFIGHTERS OF CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>At least age of 21 at the time of appointment</li>
                                        <br />
                                        <li>A high-school diploma/ GED certificate</li>
                                        <br />
                                        <li>A valid California’s driving license</li>
                                        <br />
                                        <li>No criminal record</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A FIREFIGHTER IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Fulfill The Minimum Eligibility Criteria</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a firefighter in California, you must fulfill the Minimum Eligibility Criteria that includes at least an age of 18, no criminal record, valid California’ driving license and a high-school diploma or a GED certificate.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Pass The Written Examination</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once you are eligible enough to be an applicant, you apply for the written examination in which your analytical skills, comprehension reading, communication skills, spatial orientation, observation, memory and personality traits are tested. In California you must score 80% or more to pass.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Qualify The Candidate Physical Ability Test</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        This test is to determine whether the candidate holds the physical ability that is required to be a firefighter. It usually involve 8 different minor tasks that test your physical ability on different levels. The results come either as a PASS/FAIL.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Enroll Of Firefighting Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Once the applicant clear both physical and written exam, they’re supposed to choose an academy in California of their choice to complete the training. A few institutions of California that gives training for all the posts in the firefighting department are City of Oceanside Fire Department- Training Center, CAL Fire Academy, RIO Honda College Fire Academy and Wildland Fire Training and Conference Center.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5: Obtain Formal Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Getting a formal educational degree is not necessary in California but if you’re willing or interested to do so, then you can get a 2-Year Associate Degree program or you can do a 4-Year bachelor’s degree program in Fire science.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 6: Obtain Medical Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        A firefighter’s job requires to handle emergency medical condition hence it is necessary for the Firefighters of California of at least hold the elementary EMT ( Emergency Medical Technician), ECC ( Emergency Cardiovascular Care) and CPR ( Cardio-Pulmonary Resuscitation) certification.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG IT TAKES TO BECOME A FIREFIGHTER IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        After accomplishing the criteria to be eligible and passing both written and physical exam, the training begins, which is usually of about 27-30 weeks (6-7 months). Including the inceptive period of interview and appointing, it would take probably 9-12 months to become a firefighter in California.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A FIREFIGHTER MAKE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In 2018, it’s recorded that an average firefighter earns about $49,620 per year. The highest salary of firefighters in California was recorded around $74,700 per year.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>CAREER OUTLOOK FOR FIREFIGHTERS IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It’s predicted that in between 2018-28, the employment opportunities for the firefighters of California would increase by the rate of 7-9%.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/firefighter-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FIREFIGHTER SCHOOLS IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/california/" className="btn btn-dark">FireFighters Schools in California</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}