import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Nurse_anesthetista.css';
import Nurse_Anesthetist from '../images/Nurse-Anesthetist.png';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Nurse_anesthetista = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Nurse Anesthetista - Salary, Qualifications, Skills & Reviews  : A Step-by-Step Guide</title>
                    <meta name='description' content={`Considering a career in nursing anesthetista? In this guide, we share the steps to becoming a nurse anesthetista, including education and licensure. Learn how to become a nurse anesthetista today!`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A NURSE ANESTHETISTA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Nurse Anesthetist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Nurse Anesthetist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Nurse Anesthetist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Nurse Anesthetist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Nurse Anesthetist Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A NURSE ANESTHETIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Certified Registered Nurse Anesthetist (CRNA) is one of the most integral members of the healthcare industry as they assist the anesthesiologist in providing the anesthesia and keep a keen eye on the condition of the patient as well. Here is the quick list of all of the major duties that every nurse anesthetist is expected to carry out on the job. A Certified Registered Nurse Anesthetist (CRNA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Administer anesthesia to the patients about to undergo surgery or any invasive medical procedure that requires anesthesia</li>
                                        <br />
                                        <li>Discuss the procedure and effects of anesthesia with the patients</li>
                                        <br />
                                        <li>Take medical history of the patients about drug abuse, allergies and illnesses before administrating anesthesia on them</li>
                                        <br />
                                        <li>Provide epidural to cause blockage of spine and spinal nerves at specific locations</li>
                                        <br />
                                        <li>Keep an eye on the condition of the patients as the anesthesia is administered and during the surgery</li>
                                        <br />
                                        <li>Keep monitoring the patient until the anesthesia wears off and the patient wakes up</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A NURSE ANESTHETISTA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Nurse_Anesthetist} alt="STEPS TO BECOME A NURSE ANESTHETISTA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A NURSE ANESTHETIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a Certified Registered Nurse Anesthetist (CRNA) is no easy feat as there are so many specific requirements to meet in order to become a CRNA. A Certified Registered Nurse Anesthetist (CRNA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 21 years of age</li>
                                        <br />
                                        <li>Must have secured GED with excellent grades in science subjects</li>
                                        <br />
                                        <li>Must have completed the two years perquisites in the form of diploma or associate degree</li>
                                        <br />
                                        <li>Must have a Bachelor’s degree in Nursing</li>
                                        <br />
                                        <li>Must have a CGPA of 3.0 and above in the Bachelor’s degree</li>
                                        <br />
                                        <li>Obtain the nursing license from the National institution</li>
                                        <br />
                                        <li>Must have at least one year experience in the field</li>
                                        <br />
                                        <li>Pursue a Master’s degree in Nurse Anesthesiology</li>
                                        <br />
                                        <li>Must shadow a senior CRNA for some time</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A NURSE ANESTHETIST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If your dream is to become a Certified Registered Nurse Anesthetist (CRNA) then we are here for your aid. We have compiled a step by step guide to help you understand the process of becoming a Certified Registered Nurse Anesthetist (CRNA).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to secure GED or High School Diploma with excellent grades in science in order to fulfill the prerequisites. One must do a diploma or a secure an associate degree in the related field before pursuing the Bachelors of Science in Nursing (BSN).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Secure License And Work In The Field</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After graduating from the nursing school, one needs to acquire the license of a Registered Nurse from the National Council Licensing Exam for Registered Nurses (NCLEX-RN). Then one should secure a job as a Registered Nurse and work in the hospital or clinic for at least one year. If one wants to pursue a Master’s degree in anesthesia then one has to complete the compulsory rotations in ICY, Coronary Care Unit as well as in Emergency Ward and keep up the RN license updated. It is also advised to shadow a senor CRNA for some time as well so that one familiarizes with the duties of a CRNA before applying to the Anesthesia School.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Join The CRNA School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After meeting all the above mentioned requirements, one can finally join the Anesthesia School to become a CRNA by securing the Master’s degree. It takes nearly two to three years to graduate from the Anesthesia School. Some state laws require the freshly graduated CRNSs successfully clear the National Certification Examination and to earn a CRNA license as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Pursue Career As A CRNA</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the degree and securing the CRNA license one can finally joins the job as a CRNA and work the dream job. One can join a hospital, clinic or open the private practice as a CRNA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A NURSE ANESTHETIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes years four years to earn the degree of Bachelors of Science in Nursing (BSN). Then one needs to secure the RN license followed by one year mandatory field work as a RN in various medical niches like ICU. Afterwards, it takes two to three years to graduate from the CRNA School with the Master’s Degree. Hence, we can say that one becomes a Certified Registered Nurse Anesthetist (CRNA) in USA in nearly 7 to 8 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A NURSE ANESTHETIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Certified Registered Nurse Anesthetist (CRNA) earns probably $157,000 per year as per the data of United States Bureau of Labor Statistics. The states which provides best pays to CRNA are Montana, Wyoming, Nevada and California.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}