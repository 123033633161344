import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";

import './Forensic_scientist.css';
import img11 from '../images/11.jpg';
import map from '../images/_usamap.png';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#duties_of_a_Forensic_scientist">Duties Of A Forensic Scientist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#requirements_to_become_a_Forensic_scientist">Requirements To Become A Forensic Scientist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_scientist">Steps To Become A Forensic Scientist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Forensic_scientist">How Long Does It Take To Become A Forensic Scientist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Forensic_scientist_usually_earn">How Much Does A Forensic Scientist Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_Forensic_scientist">
                                <h2>DUTIES OF A FORENSIC SCIENTIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A Forensic Scientist analyzes and investigates the criminal evidence by employing the use of various scientific tools and techniques. If you want to know what general duties a forensic scientist usually carries out then here is a quick inventory of the duties of forensic scientist. A forensic scientist usually:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Do post mortem analysis of the body</li>
                                        <br />
                                        <li>Collects evidence from crime scene</li>
                                        <br />
                                        <li>Take pictures and make videos of the crime scene</li>
                                        <br />
                                        <li>Prevents crime scene contamination</li>
                                        <br />
                                        <li>Identify important clues and record them</li>
                                        <br />
                                        <li>Make notes of the status quo of the crime scene</li>
                                        <br />
                                        <li>Analyze the blood spatter patterns</li>
                                        <br />
                                        <li>Investigate and find the trace of materials like drugs in body fluids like blood and urine</li>
                                        <br />
                                        <li>Testify in court trials and hearings</li>
                                        <br />
                                        <li>Provide paperwork for their investigation and results</li>
                                        <br />
                                        <li>Explain events by reconstructing them using the evidences</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A FORENSIC SCIENTIST</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img11} alt="STEPS TO BECOME A FORENSIC SCIENTIST" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_Forensic_scientist">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are not familiar with the requirements that every candidate aiming to become a forensic scientist must fulfill then relax. Here is a list of a few requirements that everyone thinking of becoming a forensic scientist must meet. A forensic scientist:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Be at least 18 years of age</li>
                                        <br />
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Must have a bachelor’s degree in forensic science or other related field</li>
                                        <br />
                                        <li>Must have a minimum three year experience in the laboratory as a forensic scientist</li>
                                        <br />
                                        <li>Should have grip on blood spatter analysis</li>
                                        <br />
                                        <li>Should have a strong heart to become the first hand witness of serious and major crime scenes like murder crime scenes.</li>
                                        <br />
                                        <li>Must have a valid Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Must be available to work long, unusual hours even on public holidays</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    <div className="col-md-7">
                                        <div className="row pt-2 pb-2 pl-3">
                                            {
                                                states != null && states.length > 0 &&
                                                states.map((item, key) => {
                                                    return (
                                                        item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                            <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/how-to-become-a-forensic-scientist-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                            : <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-5 mt-auto mb-auto">
                                        <img src={map} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_scientist">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are thinking of pursuing your career as a forensic scientist in the future then here is a step by step guide to help you understand the process of becoming a forensic scientist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        First of all, one must pass High School with ideal grades in science subjects like Biology, Chemistry, Physics as well as Statistics. Then one must secure a bachelor’s degree in forensic science, biology, chemistry or other related fields. It is extremely important to choose the right degree program as only a few degrees allow one to become a forensic scientist.
                                        Bachelor of Science in Genetic Engineering with a concentration in Forensic Science, Bachelor of Science in Forensic Chemistry, and Bachelor of Science in Forensic Biology are few of the degrees that one can pursue.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Apprenticeship</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally secured the degree one should look for internship or apprenticeship opportunities under the supervision of a senior forensic scientist. This will help the new graduate to learn how to practically apply the skills and techniques which one learnt in college. After completing the internship one can apply for the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Get The Professional Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally completed the degree, one has to acquire the professional certification by The Forensic Specialties Accreditation Board, founded with support from the American Academy of Forensic Sciences. The certification includes written as well as practical tests which one has to pass in order to pursue his career.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Look For A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has completed the undergraduate degree as well as acquired the professional certification, one can definitely apply for a job in police station and other law enforcement agencies.
                                        If one wants his job application to stand out from the rest of the applications, one can also pursue a graduate degree or a Master’s degree or a Doctorate in forensic science as well. The advanced degree will help in acquiring a good job with better wages as well. In addition to that, having an advanced degree in forensic science can surely help you to jump ranks as well.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Forensic_scientist">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One enters the college to pursue the bachelor’s degree in forensic science after completing the High School Diploma or GED. It takes four years to graduate from the college with the forensic degree. Afterwards it takes one year to complete the apprenticeship under the senior forensic scientist. Hence, it takes around 5 years to become a forensic scientist in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Forensic_scientist_usually_earn">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average per annum pay of a professional Forensic scientist is $58,230 according to the facts and figures provided by the United States Bureau of Labor Statistics.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Suggestions">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/criminologist/">Steps to become a Criminologist</NavLink></li>
                                        <li><NavLink to="/court-reporter/">Steps to become a Court Reporter</NavLink></li>
                                        <li><NavLink to="/cia-agent/">Steps to become a CIA AGENT</NavLink></li>
                                        <li><NavLink to="/crime-scene-investigator/">Steps to become a Crime Scene Investigator</NavLink></li>
                                        <li><NavLink to="/cpa/">Steps to become a CPA</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}