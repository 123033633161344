import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Park_ranger.css';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Park_ranger = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Park Ranger - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PARK RANGER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Park Ranger</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Park Ranger</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Park Ranger</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Park Ranger</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Park Ranger Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PARK RANGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Park Ranger is as officer that basically work outdoors, in national parks or conservatories for the protection of the forest and the wildlife living in the forests. Every park ranger has to carry out a number of different sorts of duties when one assumes office. So, we have an inventory of some of the general duties of a park ranger given below. A Park Ranger:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Protect and ensure safety of the park and the visitors of the park</li>
                                        <br />
                                        <li>Regularly patrol around their areas especially the areas frequented by visitors like hiking or biking trails and various campsites</li>
                                        <br />
                                        <li>Lead tours acting as the tour guide and answer any questions related to the park</li>
                                        <br />
                                        <li>Guide the visitors about the fire safety rules and remind them again when they are setting up fires near the campsite</li>
                                        <br />
                                        <li>Conduct search and rescue activities for the people visiting the park</li>
                                        <br />
                                        <li>Teach major safety methods to the visitors and encourage them to take safety measures while hiking or camping in the park</li>
                                        <br />
                                        <li>Collect entrance fee for entry into the park</li>
                                        <br />
                                        <li>Educate the visitors about keeping the park free from litter and try to minimize every sort of pollution in park</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PARK RANGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One has to meet a number of specific requirements of one really wants to secure the post of a Park Ranger in USA. A Park Ranger:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 21 years old</li>
                                        <br />
                                        <li>Must have secured an associate degree or a Bachelor’s degree</li>
                                        <br />
                                        <li>Must fill the online job application at the official job site USAJOBS website</li>
                                        <br />
                                        <li>Pass all the required tests for the job that includes the written test, physical examination, background check and drug test</li>
                                        <br />
                                        <li>Must successfully clear the Physical Efficiency Battery (PEB)</li>
                                        <br />
                                        <li>Successfully complete the Park Ranger training</li>
                                        <br />
                                        <li>Must feel comfortable staying outdoors for extended periods of time without basic amenities</li>
                                        <br />
                                        <li>Must have an innate love for nature and animals</li>
                                        <br />
                                        <li>Must be able to work long hours in park</li>
                                        <br />
                                        <li>Must have excellent public dealing skills and good demeanor</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PARK RANGER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Park ranger is indeed one of the very competitive jobs in America. If you really wonder how one assumes the office of a Park Ranger in USA then we have assembled a step by step guide for you to follow.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        First and foremost, one must have an associate or a Bachelor’s degree in environmental sciences, zoology, botany, geology, ecology, sociology and other niches. But it is better to go for the Bachelor’s degree for better job perspectives in future.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Apply For The Job And Complete The Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the education, one should apply for the job by filling an online application form at the official site. Then one has to appear in the written exam, clear the Physical Efficiency Battery (PEB), pass the polygraph test and go through thorough background check. When one finally clears all the test, one gets accepted into the Park Ranger training program in Grand Canyon National Park in Arizona. The rangers are also given training at the NPS training facility in Harpers Ferry, West Virginia
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Give The Examination And Start The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Afterwards, one has to give the Group VI Law Enforcement and Investigation examination to be able to earn the legal right to start working as a park ranger. Then, one finally start the job as an official park ranger and work for promotion.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PARK RANGER??</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It probably takes 2 years to complete an associate degree and 4 years to acquire the Bachelor’s degree in environmental sciences, zoology, botany, geology and other niches. After that, one needs to apply for the job and then pass all the entrance level tests; written, physical, background check and polygraph test. After that, one has to undergo Park Rangers training for a few weeks and then successfully clear the Law Enforcement examination. So, it is safe to say that in USA it takes almost 3 to 5 years for someone to pursue the career of a Park Ranger
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PARK RANGER? USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average yearly income of a Forester is around $ 61,340 according to the statistics provided by United States Bureau of Labor Statistics. But most sources estimates the average yearly income of a Park Ranger specifically to be somewhere around $40,000. Some of the most financially rewarding American States for Park Rangers are California, Texas and Alaska.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/actuary/">Steps to become a Actuary</NavLink></li>
                                        <li><NavLink to="/truck-driver/">Steps to become a Truck Driver</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}