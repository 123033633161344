import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Pastoral_counselor.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Pastoral_counselor = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Pastoral Counselor - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PASTORAL COUNSELOR
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Pastoral Counselor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Pastoral Counselor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Pastoral Counselor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Pastoral Counselor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Pastoral Counselor Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PASTORAL COUNSELOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The pastoral counselor is a religious figure like a priest, imam or guru that provide therapy to others by representing the views of one’s religion. When working on the job, a pastoral counselor diligently carries his duty by making sure to provide one with best possible therapy. We have compiled a quick inventory of the major duties that a pastoral counselor carries out on the job. A pastoral counselor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Provide spiritual as well as psychological guidance to the people in need</li>
                                        <br />
                                        <li>Listen to the troubles and sorrows of people</li>
                                        <br />
                                        <li>Shed light on the main reasons why someone is encountering a problem</li>
                                        <br />
                                        <li>Suggest remedies for the problems presented to them by the troubled people</li>
                                        <br />
                                        <li>Guide people about combating drug and alcohol addiction</li>
                                        <br />
                                        <li>Counsel people about grief management</li>
                                        <br />
                                        <li>Help the parishioner in coping with the demise of a loved one</li>
                                        <br />
                                        <li>Instruct brides and grooms about their marital duties outlined by the religion</li>
                                        <br />
                                        <li>Encourage married couples to resolve their issues in a peaceful and civil way</li>
                                        <br />
                                        <li>Guide people about the correct way to resolve their issues and disputes</li>
                                        <br />
                                        <li>Help the troubled parishioner to form a connection with the Almighty</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PASTORAL COUNSELOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Nowadays, a number of people from different states want to become a pastoral counselor. That is why, there are many requirements which every candidate has to meet in the best possible way to secure the pastoral counselor career. A pastoral counselor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years of age</li>
                                        <br />
                                        <li>Must have acquired a Bachelor’s degree in psychology, sociology, behavioral sciences or other fields</li>
                                        <br />
                                        <li>Should also pursue a Master’s degree in order to do proper marriage and family therapy as a counselor</li>
                                        <br />
                                        <li>Must enroll in the internship program and successfully complete it</li>
                                        <br />
                                        <li>Have to secure license by clearing the examination conducted by the national institutions</li>
                                        <br />
                                        <li>Should acquire certification for therapy</li>
                                        <br />
                                        <li>Must be very patient and listen to the problems of people with empathy</li>
                                        <br />
                                        <li>Must be passionate for the job</li>
                                        <br />
                                        <li>Must be assertive so that once can help the people who need to be guided to the straight path again</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PASTORAL COUNSELOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        We have decided to facilitate aspiring candidates by providing them with the step by step guide outlining the major steps of becoming a pastoral counselor in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The first thing every candidate has to do is to secure a Bachelor’s degree in behavioral sciences, psychology, sociology and other fields which have extra credit hours of therapy and counselling. If one wants to become a higher counsellor for matters like marriage and family then it is advisable that one secures a Master’s degree.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Complete The Internship Program And Find A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the degree, one should enroll as an intern in a church, clinic or therapy center to work closely with the people under the guidance of a skilled pastoral counsellor. After completing internship, one should look for a job as a pastoral counselor to gain the required in field experience to apply for the license.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Secure License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After fulfilling all the prerequisites, one is eligible to apply for the license of pastoral counsellor by successfully clearing the National Counselor Examination (NCE). In addition to that, one could also give the National Clinical Mental Health Counseling Examination (NCMHCE) and secure the pastoral counsellor license.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Secure Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One could also acquire national certification by applying in National Board of Certified Counselors (NBCC). American Association of Pastoral Counselors also facilitate the counsellors by providing them with pastoral counsellor certification.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PASTORAL COUNSELOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Firstly, one has to secure a 4 year Bachelor’s degree in psychology or behavioral sciences. Then if one is pursuing a Master’s degree then it will take 2 more years to acquire it. Then one has to join an internship program for a few months in order to be eligible to take the examination for license. It takes a few months to prepare for the license examination and then successfully pass it to obtain the license. So, we can conclude that the process of becoming a pastoral counselor in USA spans over 5-7 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PASTORAL COUNSELOR USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The annual mean income of a pastoral counselor is estimated to be $ 50,090 according to the data provided by United States Bureau of Labor Statistics. California, New York and Massachusetts are the American states that reward pastoral counselors with best salary packages.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}