import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Detective.css';
import img9 from '../images/9.jpg';
import map from '../images/_usamap.png';
import { NavLink } from 'react-router-dom';
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";

export const Detective = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Detective - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DETECTIVE
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href={`#duties_of_a_detective`}>Duties Of A Detective</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#requirements_to_become_a_detective`}>Requirements To Become A Detective</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#states`}>States</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#steps_to_become_a_detective`}>Steps To Become A Detective</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_long_does_it_take_to_become_a_detective`}>How Long Does It Take To Become A Detective</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_much_does_a_Detective_usually_earn`}>How Much Does A Detective Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="duties_of_a_detective">
                                <h2>DUTIES OF A DETECTIVE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A detective is a professional investigator that investigates the cases assigned to him and collects evidences for the case. If you are intrigued by the job description of a detective’s job then here is a quick inventory of the duties a detective carries out. Generally, a detective:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Investigate the cases</li>
                                        <br />
                                        <li>Interview the suspects and witnesses of the case</li>
                                        <br />
                                        <li>Examine previous records to collect evidences</li>
                                        <br />
                                        <li>Safeguard the witnesses of a case</li>
                                        <br />
                                        <li>Observe the potential suspects of a case</li>
                                        <br />
                                        <li>Take an active part in raids</li>
                                        <br />
                                        <li>Arrest criminals and potential criminals</li>
                                        <br />
                                        <li>Can work long, unconventional hours</li>
                                        <br />
                                        <li>Should be available round the clock to respond to an emergency</li>
                                        <br />
                                        <li>File paperwork about a case</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A DETECTIVE</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img9} alt="STEPS TO BECOME A FISH AND GAME DETECTIVE" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_detective">
                                <h2>REQUIREMENTS TO BECOME A DETECTIVE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have a passion for becoming a detective then here is list of requirement that every candidate for the job of detective has to meet. Every detective:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be 21 years of age</li>
                                        <br />
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Must have an associate or bachelor’s degree</li>
                                        <br />
                                        <li>Must have a Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Must be in excellent physical shape</li>
                                        <br />
                                        <li>Should know how to operate computer</li>
                                        <br />
                                        <li>Must have excellent physical strength and stamina</li>
                                        <br />
                                        <li>Must know how to use polygraph, surveillance, fingerprinting equipment</li>
                                        <br />
                                        <li>Should know how to enter and look up information in the databases</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="states">
                                <h2>SELECT STATES</h2>
                            </div>
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    <div className="col-md-7">
                                        <div className="row pt-2 pb-2 pl-3">
                                            {
                                                states != null && states.length > 0 &&
                                                states.map((item, key) => {
                                                    return (
                                                        item.state.replace(/ /g, '-').toLowerCase() == 'california' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'florida' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'new york' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'pennsylvania' ||
                                                            item.state.replace(/ /g, '-').toLowerCase() == 'texas' ?
                                                            <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/how-to-become-a-detective-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                            : <div className="col-md-4 col-6 pl-0 pb-2" key={key}>
                                                                <span><FaCircle style={{ fontSize: '40%' }} /> </span><NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state.replace('District of Columbia', 'D.o.C')}</NavLink>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-5 mt-auto mb-auto">
                                        <img src={map} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_detective">
                                <h2>STEPS TO BECOME A DETECTIVE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The process of becoming a detective is neither that complex nor that long. Here is the step by step guide to help you better understand how one becomes a detective.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        Most of the detectives actually start their careers as police officers. The candidate for detective job must be at least 21 years of age to become a detective. In addition to that, he must be a permanent citizen of United States of America. He must have a High School Diploma, GED or any equivalent degree in order to get into police academy.
                                        Many people join the police academy straight up after graduating from High School, but it is important for a detective to have a college degree in crime related fields. This includes degrees in fields like criminal law, criminology, human relations, judicial function, forensic science, and criminal procedure.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Complete The Police Academy Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        One has to pass extensive written tests, physical tests, background check, polygraph test, drug tests and other psychological tests to get into police academy. The training ranges from 16 to 26 weeks, mostly it takes six to eight months to complete the police academy training.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Join The Police Precinct</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After graduating from the police academy one joins the respective precinct and gets assigned to a senior partner. The senior partner teaches the ropes of the job to the young graduate and helps him adjust to the new job regime. During his job as a police officer one should work hard and try to solve as much cases correctly as possible. One should build a strong reputation on the basis of his intelligence, hard work and dedication to the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Then Work Hard For Promotion</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        A police officer with at least three years gets promoted to the position of detective. That is why, one must keep his track record clean so that his chances of getting promoted to the post of detective increases. One can also upscale his application by learning a foreign language like Spanish as well as French. Learning a new language or a new skill can definitely play an important part in getting promotion to detective.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_detective">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DETECTIVE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Any candidate first needs to do an associate degree of two years or a bachelor’s degree of four years and then join police academy for six months to finally become a police officer. Then he has to serve as a police officer for three years to finally get promoted to the post of detective. So, it takes around six to eight years to become a detective in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_Detective_usually_earn">
                                <h2>HOW MUCH DOES A DETECTIVE USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics state that a private detective in United States averagely earns $50,700 per year. On the other hand, a police detective or a criminal investigator averagely earns $79,970 per year. The average pay for both private and government detective may differ when compared to the pay of different states due to their respective laws and regulations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}