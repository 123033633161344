import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Border_patrol_officer.css';
import Borderpatrolofficer from '../images/Borderpatrolofficer.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';


export const Border_patrol_officer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Border Patrol Officer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A BORDER PATROL OFFICER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Border Patrol Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Border Patrol Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Border Patrol Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Border Patrol Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Border Patrol Officer Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A BORDER PATROL OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The border patrol officer is a <NavLink to="/cia-agent/">law enforcement</NavLink> officer that has taken the pledge to safeguard the borders of USA against every potential foreign threat. A border patrol officer carries out a diverse set of duties. A border patrol officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Safeguards the borders of the country</li>
                                        <br />
                                        <li>Act as the first line of defense against any potential foreign threat</li>
                                        <br />
                                        <li>Inspect the people, vehicle as well as cargo entering the country via the border</li>
                                        <br />
                                        <li>Stop people from illegally entering the country</li>
                                        <br />
                                        <li>Screen any sort of goods or items entering the country</li>
                                        <br />
                                        <li>Seize illegal arms, weapons and drugs and capture the people involved in the smuggling</li>
                                        <br />
                                        <li>Provide agricultural protection to country by stopping agricultural pests entering the country</li>
                                        <br />
                                        <li>Enforce immigration and emigration laws at the border</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A BORDER PATROL OFFICER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Borderpatrolofficer} alt="STEPS TO BECOME A BORDER PATROL OFFICER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A BORDER PATROL OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are a specific set of requirements that every candidate thinking of pursuing a career as a border patrol officer has to fulfill. A border patrol officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have the permanent nationality of USA</li>
                                        <br />
                                        <li>Must be at least 18/21 years or maximum 37/40 years of age at the time to apply</li>
                                        <br />
                                        <li>Must have a valid Commercial Driver’s License</li>
                                        <br />
                                        <li>Must have eligibility to carry a firearm</li>
                                        <br />
                                        <li>Should have a bachelor’s or Master’s degree</li>
                                        <br />
                                        <li>Have lived in United States of America for at least three to five years constantly before applying for the job</li>
                                        <br />
                                        <li>Must have no criminal record</li>
                                        <br />
                                        <li>Must have a 20/20 vision</li>
                                        <br />
                                        <li>Must pass the written and physical test</li>
                                        <br />
                                        <li>Must know a foreign language like Spanish</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A BORDER PATROL OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The process of becoming a border patrol officer is very systematic and by the book. Here is the step by step guide to help you comprehend how one becomes a border patrol officer in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One has to be a permanent United States citizen, have a high school diploma or GED and at least 18/21 years old to become eligible to apply for the job. Then one must secure a bachelor’s degree in criminal justice, <a href="#">police administration</a> and operations, police science, behavioral science, homeland security and related fields.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Take The CBP Border Patrol Entrance Exam</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the Bachelor’s degree, one should apply for the job if there is an open position available. Then one should take the CBP Border Patrol Entrance Exam at the premise most convenient for one. One should then submit the resume containing all the necessary information about one. Then a candidate has to pass the physical test, background check. In addition to that, a candidate has to pass the polygraph test and the panel interview as well.
                                        When one successfully clears all these steps one secures the job and moves on to the training phase. The candidates applying for GL- 9 level with a few years of law enforcement experience will not have to give the entrance exam.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Training At CBP Border Patrol Academy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for the newly selected candidates to attend the 55 day training at CBP Border Patrol Academy in the premises of Federal Law Enforcement Training Centers network. The other option is 19 weeks of training at the Border Patrol Academy in Artesia, New Mexico. If the candidate is not familiar with or less proficient in Spanish then he has to attend 8 more weeks of training sessions. Then one joins the job and train with his seniors at the job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A BORDER PATROL OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes four years to secure a Bachelor’s degree in criminal justice, police, police science and other similar niches. Then one needs to do have at least one year experience at some law enforcement firm to apply for the border control position. Then the candidate has to do 55 day training at CBP Border Patrol Academy or 19 weeks of training at the Border Patrol Academy in Artesia, New Mexico. Those who are not that proficient in Spanish also have to undergo additional 8 weeks of sessions. So, it takes around 6 to 7years to become a border patrol officer in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A BORDER PATROL OFFICER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        According to the facts and figures issued by United States Bureau of Labor Statistics, a border patrol officer has the mean average of $56,810 per year. New Jersey, California, New York and Alaska are the states that pay the most handsome salaries to the border control officers.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/criminologist/">Steps to become a Criminologist</NavLink></li>
                                        <li><NavLink to="/court-reporter/">Steps to become a Court Reporter</NavLink></li>
                                        <li><NavLink to="/cia-agent/">Steps to become a CIA Agent</NavLink></li>
                                        <li><NavLink to="/crime-scene-investigator/">Steps to become a Crime Scene Investigator</NavLink></li>
                                        <li><NavLink to="/cpa/">Steps to become a CPA</NavLink></li>
                                        <li><NavLink to="/customs-agent/">Steps to become a Customs Agent</NavLink></li>
                                        <li><NavLink to="/detective/">Steps to become a Detective</NavLink></li>
                                        <li><NavLink to="/fbi-agent/">Steps to become a FBI Agent</NavLink></li>
                                        <li><NavLink to="/fish-and-game-warden/">Steps to become a Fish And Game Warden</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}