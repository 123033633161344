import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import { Index } from './Components/Index';
import { Cia_agent } from './Components/Cia_agent';
import { Cia_agent_in_other_states } from './Components/Cia_agent_in_other_states';
import { Crime_scene_investigator } from './Components/Crime_scene_investigator';
import { Criminologist } from './Components/Criminologist';
import { Detective } from './Components/Detective';
import { Detective_in_texas } from './Components/Detective_in_texas';
import { Detective_in_california } from './Components/Detective_in_california';
import { Detective_in_florida } from './Components/Detective_in_florida';
import { Detective_in_pennsylvania } from './Components/Detective_in_pennsylvania';
import { Detective_in_new_york } from './Components/Detective_in_new_york';
import { Detective_in_other_states } from './Components/Detective_in_other_states';
import { Animal_cop } from './Components/Animal_cop';
import { Business_analyst } from './Components/Business_analyst';
import { Customs_agent } from './Components/Customs_agent';
import { Chef } from './Components/Chef';
import { Hedge_fund_manager } from './Components/Hedge_fund_manager';
import { Librarian } from './Components/Librarian';
import { Loan_officer } from './Components/Loan_officer';
import { Paralegal } from './Components/Paralegal';
import { Park_ranger } from './Components/Park_ranger';
import { Diesel_mechanic } from './Components/Diesel_mechanic';
import { Electrical_engineer } from './Components/Electrical_engineer';
import { Software_developer } from './Components/Software_developer';
import { Guide_to_software_engineer } from './Components/Guide_to_software_engineer';
import { Cna } from './Components/Cna';
import { Medical_assistant } from './Components/Medical_assistant';
import { Midwife } from './Components/Midwife';
import { Midwife_in_california } from './Components/Midwife_in_california';
import { Midwife_in_florida } from './Components/Midwife_in_florida';
import { Midwife_in_new_york } from './Components/Midwife_in_new_york';
import { Midwife_in_pennsylvania } from './Components/Midwife_in_pennsylvania';
import { Midwife_in_texas } from './Components/Midwife_in_texas';
import { Midwife_in_other_states } from './Components/Midwife_in_other_states';
import { Nurse } from './Components/Nurse';
import { Nurse_anesthetista } from './Components/Nurse_anesthetista';
import { Nurse_practitioner } from './Components/Nurse_practitioner';
import { Phlebotomist } from './Components/Phlebotomist';
import { Pharmacist } from './Components/Pharmacist';
import { Physician_assistant } from './Components/Physician_assistant';
import { Registered_nurse } from './Components/Registered_nurse';
import { Surgeon } from './Components/Surgeon';
import { Pastoral_counselor } from './Components/Pastoral_counselor';
import { Border_patrol_officer } from './Components/Border_patrol_officer';
import { Court_reporter } from './Components/Court_reporter';
import { Cosmetologist } from './Components/Cosmetologist';
import { Data_analyst } from './Components/Data_analyst';
import { Fbi_agent } from './Components/Fbi_agent';
import { Financial_advisor } from './Components/Financial_advisor';
import { Financial_analyst } from './Components/Financial_analyst';
import { Parole_officer } from './Components/Parole_officer';
import { Police_officer } from './Components/Police_officer';
import { Police_officer_in_california } from './Components/Police_officer_in_california';
import { Police_officer_in_florida } from './Components/Police_officer_in_florida';
import { Police_officer_in_pennsylvania } from './Components/Police_officer_in_pennsylvania';
import { Police_officer_in_new_york } from './Components/Police_officer_in_new_york';
import { Police_officer_in_texas } from './Components/Police_officer_in_texas';
import { Police_officer_in_other_states } from './Components/Police_officer_in_other_states';
import { Private_investigator } from './Components/Private_investigator';
import { Real_estate_agent } from './Components/Real_estate_agent';
import { Realtor } from './Components/Realtor';
import { Sheriff } from './Components/Sheriff';
import { Sheriff_in_texas } from './Components/Sheriff_in_texas';
import { Sheriff_in_california } from './Components/Sheriff_in_california';
import { Sheriff_in_florida } from './Components/Sheriff_in_florida';
import { Sheriff_in_new_york } from './Components/Sheriff_in_new_york';
import { Sheriff_in_pennsylvania } from './Components/Sheriff_in_pennsylvania';
import { Sheriff_in_other_states } from './Components/Sheriff_in_other_states';
import { Firefighter } from './Components/Firefighter';
import { Firefighter_in_texas } from './Components/Firefighter_in_texas';
import { Firefighter_in_california } from './Components/Firefighter_in_california';
import { Firefighter_in_florida } from './Components/Firefighter_in_florida';
import { Firefighter_in_new_york } from './Components/Firefighter_in_new_york';
import { Firefighter_in_pennsylvania } from './Components/Firefighter_in_pennsylvania';
import { Firefighter_in_other_states } from './Components/Firefighter_in_other_states';
import { Cpa } from './Components/Cpa';
import { Forensic_scientist } from './Components/Forensic_scientist';
import { Forensic_scientist_in_california } from './Components/Forensic_scientist_in_california';
import { Forensic_scientist_in_florida } from './Components/Forensic_scientist_in_florida';
import { Forensic_scientist_in_new_york } from './Components/Forensic_scientist_in_new_york';
import { Forensic_scientist_in_pennsylvania } from './Components/Forensic_scientist_in_pennsylvania';
import { Forensic_scientist_in_texas } from './Components/Forensic_scientist_in_texas';
import { Forensic_scientist_in_other_states } from './Components/Forensic_scientist_in_other_states';
import { Accountant } from './Components/Accountant';
import { Actuary } from './Components/Actuary';
import { Fish_and_game_warden } from './Components/Fish_and_game_warden';
import { Statistician } from './Components/Statistician';
import { Truck_driver } from './Components/Truck_driver';
import { Truck_driver_in_california } from './Components/Truck_driver_in_california';
import { Writer } from './Components/Writer';
import { Welder } from './Components/Welder';
import { Schools } from './Components/Schools';
import { GetSchoolsByState } from './Components/GetSchoolsByState';
import { Search } from './Components/Search';
import { SchoolDetails } from './Components/SchoolDetails';
import { Forms } from './Components/Forms';
import Footer from './Components/Footer';
import _404 from './Components/_404';

function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path='*' element={<Index />} />
        <Route path="/cia-agent/" element={<Cia_agent />} />
        <Route path="/how-to-become-a-cia-agent-in-:parameter/" element={<Cia_agent_in_other_states />} />
        <Route path="/crime-scene-investigator/" element={<Crime_scene_investigator />} />
        <Route path="/criminologist/" element={<Criminologist />} />
        <Route path="/detective/" element={<Detective />} />
        <Route path="/how-to-become-a-detective-in-texas/" element={<Detective_in_texas />} />
        <Route path="/how-to-become-a-detective-in-california/" element={<Detective_in_california />} />
        <Route path="/how-to-become-a-detective-in-florida/" element={<Detective_in_florida />} />
        <Route path="/how-to-become-a-detective-in-pennsylvania/" element={<Detective_in_pennsylvania />} />
        <Route path="/how-to-become-a-detective-in-new-york/" element={<Detective_in_new_york />} />
        <Route path="/how-to-become-a-detective-in-:parameter/" element={<Detective_in_other_states />} />
        <Route path="/animal-cop/" element={<Animal_cop />} />
        <Route path="/business-analyst/" element={<Business_analyst />} />
        <Route path="/customs-agent/" element={<Customs_agent />} />
        <Route path="/chef/" element={<Chef />} />
        <Route path="/hedge-fund-manager/" element={<Hedge_fund_manager />} />
        <Route path="/librarian/" element={<Librarian />} />
        <Route path="/loan-officer/" element={<Loan_officer />} />
        <Route path="/paralegal/" element={<Paralegal />} />
        <Route path="/park-ranger/" element={<Park_ranger />} />
        <Route path="/diesel-mechanic/" element={<Diesel_mechanic />} />
        <Route path="/electrical-engineer/" element={<Electrical_engineer />} />
        <Route path="/software-developer/" element={<Software_developer />} />
        <Route path="/guide-to-become-software-engineer/" element={<Guide_to_software_engineer />} />
        <Route path="/cna/" element={<Cna />} />
        <Route path="/medical-assistant/" element={<Medical_assistant />} />
        <Route path="/midwife/" element={<Midwife />} />
        <Route path="/how-to-become-a-midwife-in-california/" element={<Midwife_in_california />} />
        <Route path="/how-to-become-a-midwife-in-florida/" element={<Midwife_in_florida />} />
        <Route path="/how-to-become-a-midwife-in-new-york/" element={<Midwife_in_new_york />} />
        <Route path="/how-to-become-a-midwife-in-pennsylvania/" element={<Midwife_in_pennsylvania />} />
        <Route path="/how-to-become-a-midwife-in-texas/" element={<Midwife_in_texas />} />
        <Route path="/how-to-become-a-midwife-in-:parameter/" element={<Midwife_in_other_states />} />
        <Route path="/nurse/" element={<Nurse />} />
        <Route path="/nurse-anesthetista/" element={<Nurse_anesthetista />} />
        <Route path="/nurse-practitioner/" element={<Nurse_practitioner />} />
        <Route path="/phlebotomist/" element={<Phlebotomist />} />
        <Route path="/pharmacist/" element={<Pharmacist />} />
        <Route path="/physician-assistant/" element={<Physician_assistant />} />
        <Route path="/registered-nurse/" element={<Registered_nurse />} />
        <Route path="/surgeon/" element={<Surgeon />} />
        <Route path="/pastoral-counselor/" element={<Pastoral_counselor />} />
        <Route path="/border-patrol-officer/" element={<Border_patrol_officer />} />
        <Route path="/court-reporter/" element={<Court_reporter />} />
        <Route path="/cosmetologist/" element={<Cosmetologist />} />
        <Route path="/data-analyst/" element={<Data_analyst />} />
        <Route path="/fbi-agent/" element={<Fbi_agent />} />
        <Route path="/financial-advisor/" element={<Financial_advisor />} />
        <Route path="/financial-analyst/" element={<Financial_analyst />} />
        <Route path="/parole-officer/" element={<Parole_officer />} />
        <Route path="/police-officer/" element={<Police_officer />} />
        <Route path="/how-to-become-a-police-officer-in-california/" element={<Police_officer_in_california />} />
        <Route path="/how-to-become-a-police-officer-in-florida/" element={<Police_officer_in_florida />} />
        <Route path="/how-to-become-a-police-officer-in-pennsylvania/" element={<Police_officer_in_pennsylvania />} />
        <Route path="/how-to-become-a-police-officer-in-new-york/" element={<Police_officer_in_new_york />} />
        <Route path="/how-to-become-a-police-officer-in-texas/" element={<Police_officer_in_texas />} />
        <Route path="/how-to-become-a-police-officer-in-:parameter/" element={<Police_officer_in_other_states />} />
        <Route path="/private-investigator/" element={<Private_investigator />} />
        <Route path="/real-estate-agent/" element={<Real_estate_agent />} />
        <Route path="/realtor/" element={<Realtor />} />
        <Route path="/sheriff/" element={<Sheriff />} />
        <Route path="/how-to-become-a-sheriff-in-texas/" element={<Sheriff_in_texas />} />
        <Route path="/how-to-become-a-sheriff-in-california/" element={<Sheriff_in_california />} />
        <Route path="/how-to-become-a-sheriff-in-florida/" element={<Sheriff_in_florida />} />
        <Route path="/how-to-become-a-sheriff-in-new-york/" element={<Sheriff_in_new_york />} />
        <Route path="/how-to-become-a-sheriff-in-pennsylvania/" element={<Sheriff_in_pennsylvania />} />
        <Route path="/how-to-become-a-sheriff-in-:parameter/" element={<Sheriff_in_other_states />} />
        <Route path="/firefighter/" element={<Firefighter />} />
        <Route path="/firefighter-in-texas/" element={<Firefighter_in_texas />} />
        <Route path="/firefighter-in-california/" element={<Firefighter_in_california />} />
        <Route path="/firefighter-in-florida/" element={<Firefighter_in_florida />} />
        <Route path="/firefighter-in-new-york/" element={<Firefighter_in_new_york />} />
        <Route path="/firefighter-in-pennsylvania/" element={<Firefighter_in_pennsylvania />} />
        <Route path="/firefighter-in-:parameter/" element={<Firefighter_in_other_states />} />
        <Route path="/cpa/" element={<Cpa />} />
        <Route path="/forensic-scientist/" element={<Forensic_scientist />} />
        <Route path="/how-to-become-a-forensic-scientist-in-california/" element={<Forensic_scientist_in_california />} />
        <Route path="/how-to-become-a-forensic-scientist-in-florida/" element={<Forensic_scientist_in_florida />} />
        <Route path="/how-to-become-a-forensic-scientist-in-new-york/" element={<Forensic_scientist_in_new_york />} />
        <Route path="/how-to-become-a-forensic-scientist-in-pennsylvania/" element={<Forensic_scientist_in_pennsylvania />} />
        <Route path="/how-to-become-a-forensic-scientist-in-texas/" element={<Forensic_scientist_in_texas />} />
        <Route path="/how-to-become-a-forensic-scientist-in-:parameter/" element={<Forensic_scientist_in_other_states />} />
        <Route path="/accountant/" element={<Accountant />} />
        <Route path="/actuary/" element={<Actuary />} />
        <Route path="/fish-and-game-warden/" element={<Fish_and_game_warden />} />
        <Route path="/statistician/" element={<Statistician />} />
        <Route path="/truck-driver/" element={<Truck_driver />} />
        <Route path="/how-to-become-a-truck-driver-in-california/" element={<Truck_driver_in_california />} />
        <Route path="/writer/" element={<Writer />} />
        <Route path="/welder/" element={<Welder />} />
        <Route path="/schools/" element={<Schools />} />
        <Route path="/:parameter/" element={<GetSchoolsByState />} />
        <Route path="/search/:parameter/" element={<Search />} />
        <Route path="/schools/:parameter" element={<SchoolDetails />} />
        <Route path="/forms" element={<Forms />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
