import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Financial_advisor.css';
import financial_aadvisor from '../images/financial_aadvisor.png';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Financial_advisor = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Financial Advisor - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FINANCIAL ADVISOR
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Financial Advisor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Financial Advisor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Financial Advisor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Financial Advisor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Financial Advisor Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A FINANCIAL ADVISOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The financial advisor work in the business field and suggest the clients with the best possible ways to spend their money and pocket profits. The financial advisor has to deal a plethora of different clients, asking for the professional advice, whilst doing the everyday job. We have mentioned some of the duties that a typical financial advisor is required to carry out in the inventory below. The financial advisor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Advise their clients regarding matters that could affect the financial status of the clients</li>
                                        <br />
                                        <li>Suggests ways to invest and spend capital in new business ventures</li>
                                        <br />
                                        <li>Provide advice regarding which insurance policy to take as well as the amount of premium and duration of the policy</li>
                                        <br />
                                        <li>Formulate new strategies that could reduce the cost of production</li>
                                        <br />
                                        <li>Suggest different methods to ensure that financial goals of a client are getting fulfilled</li>
                                        <br />
                                        <li>Oversee the accounts and financial transactions of the clients</li>
                                        <br />
                                        <li>Suggest ways to cut back on extra expenditures and save capital</li>
                                        <br />
                                        <li>Propose to look into businesses which could be great investments by generating maximum profits</li>
                                        <br />
                                        <li>Submit a detailed written report to the clients regarding the financial advice</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A FINANCIAL ADVISOR</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={financial_aadvisor} alt="STEPS TO BECOME A FINANCIAL ADVISOR" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A FINANCIAL ADVISOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Everyone thinking of becoming a financial advisor in the future has to meet some specific and necessary requirements in order to facilitate the process of becoming a financial advisor. The financial advisor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have secured a Bachelor’s degree primarily in finance with extra credits for mathematics, accounting and economics</li>
                                        <br />
                                        <li>Should have pursued a MBA or Master of Science in Finance for getting preference when it comes to acquiring higher positions</li>
                                        <br />
                                        <li>Need to obtain work experience by working in the field as a financial advisor for at least 3 years</li>
                                        <br />
                                        <li>Can also acquire license of financial advisor</li>
                                        <br />
                                        <li>Must successfully clear the Series 7 licensing exam as per the rules and regulations of Financial Industry Regulatory Authority (FINRA)</li>
                                        <br />
                                        <li>Should acquire official certification of Certified Financial Planner (CFP) provided by the Certified Financial Planner Board of Standards</li>
                                        <br />
                                        <li>Should have basic knowledge regarding operation of financial software</li>
                                        <br />
                                        <li>Must have command on mathematics, accounting as well economics and marketing strategies</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A FINANCIAL ADVISOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the special step by step guide in which we have highlighted all the main steps that one needs to carry out in order to secure the dream job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for every financial advisor to have a Bachelor’s in finance having extra credits of mathematics, accounting and economics in order to obtain an entry level job. But those who are aiming to secure management posts need to have a MBA or Master of Science in Finance as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Work Experience And License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One should start working as a financial advisor after securing the degree as one needs to be working as a financial advisor for at least three years in order to become eligible to take the official certification examination. One can also acquire the license of financial advisor by successfully passing the Series 7 licensing exam according to the laws of Financial Industry Regulatory Authority (FINRA).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Certification Process</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully completing the cosmetology course from a cosmetology school, you must prepare to pass the cosmetology test. The cosmetology test includes a written test as well as a practical test which focuses on hair and makeup skills as well as skin treatments. After passing the cosmetology test, you will get your license and can openly practice as a cosmetologist.
                                        <br />
                                        When one has finally completed all the prerequisites, one is eligible to appear in the certification examinations, followed by an ethics test. When one finally clears these two tests one finally secures the official certification of Certified Financial Planner (CFP) provided by the Certified Financial Planner Board of Standards. The certification opens a number of new doors and opportunities for the financial advisor.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FINANCIAL ADVISOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        When easily secures a Bachelor’s degree in finance USA in 4 years then one has to acquire an entry level job and work for at least 3 years as a financial advisor in order to become eligible for the certification. Then one has to successfully clear the certification examination to obtain the official certification of Certified Financial Planner (CFP). Some candidates aiming for senior financial advisor position also pursue MBA or Master of Science in Finance of 2 years as well as acquire license of financial advisor. So, we can conclude, one takes 7 to 9 years to officially become a financial advisor in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A FINANCIAL ADVISOR USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average annual pay of a financial advisor as per the data made public by United States Bureau of Labor Statistics is $88,890. The most financially rewarding American States for financial advisors to work in are New York, California and Connecticut.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}