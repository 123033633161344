import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Private_investigator.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Private_investigator = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Private Investigator - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PRIVATE INVESTIGATOR
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Private Investigator</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Private Investigator</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Private Investigator</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Private Investigator</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Private Investigator Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PRIVATE INVESTIGATOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The private investigator is privately hired by individuals to collect evidences and find clues in order to find missing items or missing people. The private investigator has a lot of important chores to do when one is carrying out private investigations for the employer. Here is a list of duties that a private investigator usually carries out. A private investigator:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Carry out detailed investigations for the employer</li>
                                        <br />
                                        <li>Verify the attributes provided by the clients to the company</li>
                                        <br />
                                        <li>Do background checks</li>
                                        <br />
                                        <li>Safeguard very important people (VIPs)</li>
                                        <br />
                                        <li>Collect evidences and clues in order to solve cases</li>
                                        <br />
                                        <li>Try to locate missing items</li>
                                        <br />
                                        <li>Make efforts to find locations of lost people</li>
                                        <br />
                                        <li>Provide security for transportation of valuable items</li>
                                        <br />
                                        <li>Carry out surveillance work</li>
                                        <br />
                                        <li>Try to find important legal and financial documentation</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PRIVATE INVESTIGATOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Pursuing the career of a private investigator is no smooth sailing. One obviously has to meet a number of particular requirements in order to become a Private Investigator in America. A private investigator
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must have an associate or a Bachelor’s degree</li>
                                        <br />
                                        <li>Must have a clean criminal background with no felony, traffic violation, drug abuse or sexual assault charge</li>
                                        <br />
                                        <li>Must be physically fit and have perfect health</li>
                                        <br />
                                        <li>Must have in field experience in law enforcement</li>
                                        <br />
                                        <li>Must have a valid Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Obtain a private investigator license</li>
                                        <br />
                                        <li>Secure the license to carry a concealed weapon</li>
                                        <br />
                                        <li>Must have some knowledge about computers and technology in order to access databases and find documents online</li>
                                        <br />
                                        <li>Must be ready to work long hours in unusual places</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PRIVATE INVESTIGATOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        We have compiled a guide that outlines the step by step process of pursing the career of private investigator in America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Formal education is not necessary for becoming a private investigator but if one wants to have a proper private investigator license or acquire certification then having a college degree is necessary. One can pursue as associate or a Bachelor’s degree in criminology, criminal justice, law or other similar fields.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Complete The Prerequisites And Acquire Private Investigator License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the education, one should do short computer courses, including online courses, to enhance the technical skill set. In addition to that, one should also enroll in a fitness program to remain physically fit. One could also learn some self-defense techniques to protect oneself in case of any threat. Furthermore, one must have a valid CDL as well as the license to carry concealed weapon which is part of the prerequisites for the private investigator license.
                                        One is advised to enroll in an internship program for a few months to learn from the senior private investigators how private investigators actually get the job done! After, completing all these necessary prerequisites one can apply for the private investigator license which is issued by the Department of Public Safety.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Secure A Job As A Private Investigator</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After securing the license, one can apply in a private investigator agency for the job. One can secure the job after getting a clearance on all the submitted documentation, private investigator license as well as background check. It is compulsory to do on the job training when one joins the agency as a private investigator.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Earn Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One can also secure certification from National Association of Legal Investigators if one wants to permanently work as a private investigator in department of defense. It increases the employment opportunities for the investigators and provide them with better paying jobs
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PRIVATE INVESTIGATOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One can secure an associate degree in 2 years while it takes 4 years to obtain a Bachelor’s degree in criminology, criminal justice or in any other similar field. Then one has to obtain the private investigator license and license of carrying concealed weapon from the respective state. Then one can also pursue private investigator certification for promotion at higher post. One also have to do on the job training once one gets hired as a private investigator. Hence, we can say it is safe to assume that one becomes private investigator in USA in 3-5 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PRIVATE INVESTIGATOR USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics has stated that an average private investigator ears approximately $50,090 per year. The American states that reward their private investigators with best wage rates are Connecticut, Massachusetts and Virginia.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}