import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Accountant.css';
import accountant from '../images/accountant.png';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Accountant = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become an Accountant - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`Learn about the variety of available accounting careers. Research duties, skills, required education, and average salaries.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME AN ACCOUNTANT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">What Are The Duties Of An Accountant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become An Accountant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become An Accountant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Secure A Job Ad Work For Certification Of Accountant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Long Does It Take To Become An Accountant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">How Much Does An Accountant Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>WHAT ARE THE DUTIES OF AN ACCOUNTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An accountant has the primary responsibility to keep the financial records and transactions receipts in order as well as to prepare documentations like money trails for audits and taxation. Working on the clock, an accountant carries out a various different financial duties related to the business field. An accountant
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Keep record of all sorts of financial transactions that occur in the company</li>
                                        <br />
                                        <li>Maintain records of all the sales and purchases made during the year</li>
                                        <br />
                                        <li>Calculate the net profits after subtracting the expenditures from the total amount</li>
                                        <br />
                                        <li>Find ways to minimize the effect of financial laws on the profits earned by the company</li>
                                        <br />
                                        <li>Calculate the taxes during taxation season</li>
                                        <br />
                                        <li>Provide financial statements to clients</li>
                                        <br />
                                        <li>Suggest ways to counter financial roadblocks for smooth business dealings</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME AN ACCOUNTANT</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={accountant} alt="HOW TO BECOME AN ACCOUNTANT" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME AN ACCOUNTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In the world of economics and finance, an accountant holds an eminent position. That is why, there are many requirements that every candidate for the post of an accountant has to meet. An accountant
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have secured a Bachelor’s degree in accounting</li>
                                        <br />
                                        <li>Must be confident and have excellent communication skills to deal clients</li>
                                        <br />
                                        <li>Must have exceptional knowledge of math and statistics for accurate calculations</li>
                                        <br />
                                        <li>Must have excellent command over the financial rules and laws</li>
                                        <br />
                                        <li>Should have basic knowledge about Microsoft Excel and QuickBooks for data entry and calculations</li>
                                        <br />
                                        <li>Do internship as an assistant accountant to learn the basics of the field</li>
                                        <br />
                                        <li>Might also secure Master’s degree in accounting for better incentives at securing the license</li>
                                        <br />
                                        <li>Pass the CPA exam to acquire the License of accountant</li>
                                        <br />
                                        <li>Secure certification with the credentials most suited to one’s skill set</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME AN ACCOUNTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The journey of becoming an accountant is very by the book, hence we have assembled a step by step guide to help you comprehend how one pursues the career as an accountant in USA
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every candidate for the post of accountant has to have a Bachelor’s degree in accounting for entry level job. One could also have a degree in math, statistics, business, finance or economics but have to complete extra credit hours in accounts.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Internship</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is better to enter an internship program in some well-known accounting firm to practically apply the knowledge learned in the college to good use. One should serve as an intern for a few months to properly learn the correct way to file receipts, calculate taxes and do audits.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Secure A Master’s Degree And Give CPA Examination</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        If one wants to earn the license of accountant than one has to clear all 4 examinations in the fields which are given below
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Audit and Attestation</li>
                                        <br />
                                        <li>Financial Accounting and Reporting</li>
                                        <br />
                                        <li>Regulations</li>
                                        <br />
                                        <li>Business environments and concepts</li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        But one has to complete 150 credit hours in order to be eligible to undertake the Certified Public Accountant (CPA) examination. That is whythe candidates decide to pursue Master’s degree in accounting to fulfill the credit hours prerequisite for the CPA exam.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>SECURE A JOB AD WORK FOR CERTIFICATION OF ACCOUNTANT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        When one has successfully, passed all 4 examinations of CPA exam, one must work as a CPA in a company for at least 2 years before one is eligible for accountant certification. One could secure one of the following certifications
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>CISA—Certified Information Systems Auditor</li>
                                        <br />
                                        <li>CFA—Chartered Financial Analyst</li>
                                        <br />
                                        <li>CIA—Certified Internal Auditor</li>
                                        <br />
                                        <li>CMA—Certified Management Accountant</li>
                                        <br />
                                        <li>CPP—Certified Payroll Professional</li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After becoming a certified accountant one can easily get promoted to a high paying job in a better company.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW LONG DOES IT TAKE TO BECOME AN ACCOUNTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes 4 years to complete a Bachelor’s degree in accounting. Then one may pursue internship as an assistant accountant for a few months. Some might also pursue a Master’s degree in accounting for greater exposure in field. Furthermore, one also has to pass a CPA exam and then gain work experience of 2 years to secure the certification of accountant for better employment opportunities. Hence, we can estimate that one becomes an accountant in USA in nearly 8-9years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>HOW MUCH DOES AN ACCOUNTANT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics state the average pay of an accountant to be around $ 70, 500 per year. Some of the American states that facilitate their accountants with the best pay rates are New York, California and Massachusetts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}