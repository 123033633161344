import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Data_analyst.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Data_analyst = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Data Analyst - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A DATA ANALYST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Data Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Data Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Data Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Data Analyst</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Data Analyst Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A DATA ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The data analyst is a pivotal member of the business niche that assembles and carry out different mathematical calculations as well as statistical analyses to acquire results. The data analyst performs a number of different duties on job which most often comprises sorting, solving and compartmentalizing data into various folders. A data analyst
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Has major responsibilities of managing the master data</li>
                                        <br />
                                        <li>Create new data and update it from time to time</li>
                                        <br />
                                        <li>Make sure to delete the miscalculated and unnecessary data</li>
                                        <br />
                                        <li>Use formula and algorithms to arrange and categorize different data</li>
                                        <br />
                                        <li>Perform different algebraic functions in order to calculate desired results</li>
                                        <br />
                                        <li>Categorize plethora of raw data into various categories depending on the importance and priority given to the data</li>
                                        <br />
                                        <li>Try to visualize the written data into bar graphs, histograms or pie charts</li>
                                        <br />
                                        <li>Find patterns in the data and try to find the reasons behind the pattern</li>
                                        <br />
                                        <li>Draws conclusions after reviewing the data</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A DATA ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have a love for numbers and calculations the data analysis is definitely one of the most best suited jobs for you. We have made an inventory of the general requirements that everyone aiming to become a data analyst has to meet. A data analyst:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a 4 years Bachelor’s degree in math, statistics and other fields</li>
                                        <br />
                                        <li>Must have a Master’s degree or a Doctorate for high end jobs</li>
                                        <br />
                                        <li>Must be proficient at mathematics, statistics as well as economics</li>
                                        <br />
                                        <li>Must be familiar with all major operations of Microsoft Excel</li>
                                        <br />
                                        <li>Must know how to operate tools of data analysis like SAS, Oracle Visual Analyzer, Microsoft Power BI, Cognos, Tableau and many others</li>
                                        <br />
                                        <li>Must know how to digitally apply the use of different mathematical equations, algorithms and algebraic functions</li>
                                        <br />
                                        <li>Must be familiar with various computing languages like R, Python, C++, Java, MATLAB, PHP</li>
                                        <br />
                                        <li>Must do an internship under a skilled data analyst</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A DATA ANALYST</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to know the pathway to take in order to become a data analyst then we are here for your aid. We have compiled a step by step guide for you to follow in order to know how one gets to lead the career of a data analyst.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important to have a Bachelor’s degree in mathematics, statistics, finance, economics, computer sciences and related niches to acquire entry level jobs. In addition to that, the candidates must keep a high cGPA, above 3.3, in Bachelor’s degree. If one wants to get a job at a reputable organization then one is required to have a Master’s or a PhD in the aforementioned fields as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Learn Computing Languages And The Way To Operate Various Data Analysis Tools</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important for a data analyst to have a command on famous as well as essential computing languages like R, Python, C++, Java, MATLAB, PHP. Furthermore, one also needs to be familiar with the way of operations of various computing tools used in data analysis. One needs to proficient in operating Microsoft Excel as well as other tools like v SAS, Oracle Visual Analyzer, Microsoft Power BI, Cognos, Tableau and many others.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Work As An Intern And Then Acquire A Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally completed all these steps, one should do an internship under the leadership of a skilled data analyst for 1-2 years to brush the skills and to acquire the infield experience to land a nice job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A DATA ANALYST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time duration to successfully secure a Bachelor’s degree in mathematics, statistics, finance or economics is 4 years. Then one has to be proficient in various important programming languages as well as various computing tools that a data analyst needs for the job. After that, one needs to undergo an internship program for 1-2 years. Some jobs may require the candidate to have a Masters or a Doctorate in the desired fields as well in order to secure a better job. So, in short, we can say it takes 5 to 7 years for someone to become a data analyst in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A DATA ANALYST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average per year income of a data analyst is around $83,390 as per stated by the United States Bureau of Labor Statistics. Some of the American states that pay the best wages to data analysts are Charlotte, San Francisco and New York.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}