import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Welder.css';
import welder from '../images/Welder.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Welder = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Welder - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A WELDER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Welder</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Welder</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Welder</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Welder</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Welder Usually Earn</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A WELDER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Welders are craftsmen that have special sets of skills for joining two different metallic parts. Here is a list of the duties that a welder in United Sates carries out. The welders usually:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Work with different metals like brass, iron, steel, aluminum, copper and many others</li>
                                        <br />
                                        <li>Use flame to melt and weld different parts</li>
                                        <br />
                                        <li>Create new designs by joining different types of metals in different ways</li>
                                        <br />
                                        <li>Join different metal parts to build new things</li>
                                        <br />
                                        <li>Repair damaged metal parts and rejoin them together</li>
                                        <br />
                                        <li>Focus on the intensity of flame for the best welding of metals</li>
                                        <br />
                                        <li>Check the items after final weld to ensure the strength and durability of the newly made linkage</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A WELDER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={welder} alt="STEPS TO BECOME A WELDER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A WELDER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are aiming to pursue a career as a professional welder then here is a list of requirements that you will have to fulfill. Generally, a welder:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18 years of age</li>
                                        <br />
                                        <li>Must have graduated high school with a High School Diploma or GED</li>
                                        <br />
                                        <li>Should have a Welding Diploma or Associate Degree in Welding</li>
                                        <br />
                                        <li>Must have excellent hand to eye coordination</li>
                                        <br />
                                        <li>Must know how to operate high flame machinery</li>
                                        <br />
                                        <li>Must have basic knowledge about different metals and their specific characteristics</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A WELDER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to know about the step by step process to join the industry as a professional welder then here is a step by step guide for you to follow.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Basic Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Any candidate thinking of becoming a welder must be at least 18 years old. The candidate must have graduated the high school with GED, High School Diploma or any degree equivalent to it.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Graduate With A Welding Diploma Or An Associate Degree</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After securing the High School Diploma, one should browse through different welding schools for either a Welding Engineering bachelor’s degree or an Associate Welding Degree. You can get information about the welding institutions by using the searchable database of American Welding Society (AWS). The most prestigious welding institutions include Tulsa Welding School, the Hobart Institute of Welding Technology and Lincoln Welding School.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Join The Apprenticeship And Hone Your Skill</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the bachelor’s or the associate degree one should look for apprenticeship opportunities. When a fresh graduate becomes the apprentice of a professional welder, he basically learns how to put the skills he has learnt in welding school to practical use. Many apprenticeship programs even provide job opportunities to the apprentices.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Get Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        According to the American Welding Society, a welder has to take a written exam and a welding test, every six months to a year, depending on the rules and regulations of the state. In order to pursue the career of a professional welder, it is extremely important to have the welding certification. That is why, in order to get better paying jobs at nice places one has to have the certification provided by the American Welding Society.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5: Look For Best Employment Opportunities</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally received the certification, one can easily apply for job in better paying companies. For getting a job, one has to provide the resume with attached copies of educational degrees and welding certification. In addition to that, one also has to pass the drug test before securing the job according to the laws of the states.
                                        One can get hired at different factories, shipping docks and automobile companies. In order to earn better wages, one needs to work hard and keep the welding certification updated every six months or a year. Furthermore, one can also do short courses in welding to keep up with the newly introduced welding techniques.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A WELDER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One enters the welding school after completing the High School Diploma. The duration of Welding Technology Associate Degree is two years while that of Welding Engineering bachelor’s degree is four years. The duration of apprenticeship is three years and then one prepares to pass the certification test. So, it takes nearly five to eight years to become a professional welder in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A WELDER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A professional welder averagely earns around $41,380 per year according to the facts and figures provided by the United States Bureau of Labor Statistics. The states that provide the best paying rate to welders in United States of America are New Jersey, Alaska and California.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/animal-cop/">Steps To Become A Animal Cop</NavLink></li>
                                        <li><NavLink to="/business-analyst/">Steps to become a Business Analyst</NavLink></li>
                                        <li><NavLink to="/firefighter/">Steps to become a Firefighter</NavLink></li>
                                        <li><NavLink to="/chef/">Steps To Become A Chef</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}