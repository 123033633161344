import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Medical_assistant.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Medical_assistant = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Medical Assistant - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`To become a medical assistant, you must earn a certificate or associate degree in medical assisting. Courses teach key skills like taking vital signs.`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MEDICAL ASSISTANT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Medical Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Medical Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Medical Assistant</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Medical Assistant?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Medical Assistant Usually Earn?</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A MEDICAL ASSISTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Medical Assistant is a career that is attracting a lot of young people all over the United States of America. In fact, it is one of the fastest growing career paths according to the United States Bureau of Labor Statistics. If you want to know more about the duties of a medical assistant then here is a list of the duties of a medical assistant. A medical assistant is required to:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Help and assist the patients</li>
                                        <br />
                                        <li>Guide them with the medications</li>
                                        <br />
                                        <li>Make inventory of all the medical supplies</li>
                                        <br />
                                        <li>Arrange all the equipment that the doctor may need for a patient’s check up</li>
                                        <br />
                                        <li>Take down medical history of the patients</li>
                                        <br />
                                        <li>Schedule surgeries by verifying it with the surgeon and the surgeon</li>
                                        <br />
                                        <li>Prepare patient for doctor’s examination by taking the weight, temperature and blood pressure of the patient beforehand</li>
                                        <br />
                                        <li>Complete the paperwork about the patient’s diagnosis</li>
                                        <br />
                                        <li>Must keep the private information about the patient confidential</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A MEDICAL ASSISTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are aiming to pursue medical assistance as a career then here is a list of general requirements that every medical assistant must meet. A medical assistant:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be above 18 years of age</li>
                                        <br />
                                        <li>Must have High School Diploma or GED but a college graduate is preferred for the job</li>
                                        <br />
                                        <li>Must have a Medical Assistant Diploma or a Medical Assistant Associate Degree.</li>
                                        <br />
                                        <li>Must be quick and responsible</li>
                                        <br />
                                        <li>Must be able to stomach the sight of blood and body fluids</li>
                                        <br />
                                        <li>Must be familiar with medical terms</li>
                                        <br />
                                        <li>Must know how to operate different medical equipment</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A MEDICAL ASSISTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is a step by step guide to help you understand the process of becoming a medical assistant. The steps are as follows:
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Any individual aiming to become a professional medical assistant must have at least the High School Diploma or GED. Afterwards, you should apply in a medical assistant school to get either Medical Assistant Diploma/Certificate or a Medical Assistant Associate Degree.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Obtain The Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the diploma or the degree one should work hard to get the medical assistant certificate. This certificate plays a pivotal role when it comes to securing the entry level job in the field. There are a number of different organizations in United States that provide the Medical Assistant Certificate by taking their own tests. American Medical Technologists (AMT), National Healthcareer Association (NHA) and American Institution of Medical Assistants (AIMT) are some of the institutes that provide the Medical Assistant Certificate. Each organization has their different test and provides their own certificate when one passes the test.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Apply For The Medical Assistant Job And Work Hard For Promotion</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is extremely important for a fresh medical assistant to work under the supervision of a doctor. The doctor basically trains the medical assistant and teaches him the ropes of the field. The doctor also helps the medical assistant to become familiar with the operation of different medical machinery. The medical assistant also learns about the medical terminologies and the names of various medical equipment and medicines.
                                        Most hospitals prefer to hire medical assistants with at least one year experience under the supervision of a certified doctor. They also prefer those medical assistant that are familiar with the medical machinery and know how to operate it. The doctors often choose the medical assistants that fit their personal criteria and are compatible with their work regime.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A MEDICAL ASSISTANT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One can also pursue his career as a medical assistant right after completing High School Diploma by training under a certified doctor. But, it is preferable to get a proper medical assistant degree if one wants to serve in a proper hospital.
                                        The time duration to graduate with the degree may be different for different states due to different laws. It takes around nine months to a year to get the Medical Assistant Certificate/Diploma but it takes around two years to get the Medical Assistant Associate degree.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A MEDICAL ASSISTANT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The figures provided by United States Bureau of Labor Statistics state that a medical assistant averagely earns around $33,610 per year. Some states may offer more money to the medical assistant depending on the wage rate of the state. The medical assistants with more experience and skills often end up earning better wages than the rest of the medical assistants.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}