import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Statistician.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Statistician = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Statistician - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A STATISTICIAN
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Statistician</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Statistician</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Step By Step Guide To Become A Statistician</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Time Duration Required To Become A Statistician</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Average Annual Earnings Of A Statistician</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you think you can pursue the career of a Statistician in America then you need to secure Bachelor’s and Master’s degree, meet some specific requirements and then successfully undergo training programs to finally enter the field.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <table className="table table-striped border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>Minimum Age Requirement</td>
                                                <td>At least must be 18/21 years old</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Education Criteria</td>
                                                <td>Bachelor’s Degree but preferable Master’s Degree for executive jobs</td>
                                            </tr>
                                            <tr>
                                                <td>Preferred Major</td>
                                                <td>Statistics or Mathematics</td>
                                            </tr>
                                            <tr>
                                                <td>Experience Required to Secure Entry Level Job</td>
                                                <td>2 to 5 years work experience</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Age Requirement</td>
                                                <td>At least must be 18/21 years old</td>
                                            </tr>
                                            <tr>
                                                <td>Duration of Training Required</td>
                                                <td>A few months of training provided on the job</td>
                                            </tr>
                                            <tr>
                                                <td>Other Requirements</td>
                                                <td>Have excellent analytical skills, Know the functions of Calculus, Algebra and Probability, Possess advanced knowledge about statistical formulae, theorems and algorithms</td>
                                            </tr>
                                            <tr>
                                                <td>Median salary per annum (BLS)</td>
                                                <td>$88,190</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A STATISTICIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Statistician is an analytical expert that uses different statistical techniques, formulae, theorems and algorithms to sort through different data and derive results using the data. The statistician spends a lot of time calculating different trends and deriving results while doing the job. Some of the major general duties of a statistician are mentioned below. The Statistician:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Firstly select the problem or the anomaly that needs to be analyzed</li>
                                        <br />
                                        <li>Pinpoint all the raw data which one needs to solve the problem</li>
                                        <br />
                                        <li>Select the files, databases and websites that contain the data</li>
                                        <br />
                                        <li>Select the statistical tools which one will need to obtain the required data from the whole file</li>
                                        <br />
                                        <li>Obtain all the raw data that is required and double check to ensure that no important data is left out</li>
                                        <br />
                                        <li>Make sure that the data is accurate and has passed the data quality checks</li>
                                        <br />
                                        <li>Run the data through different statistical software and use different statistical tools and algorithms to obtain results</li>
                                        <br />
                                        <li>Identify trends present in the processed data</li>
                                        <br />
                                        <li>Predict how these trends will affect the business</li>
                                        <br />
                                        <li>Prepare a detailed report regarding the trends and their effects in a comprehensive way that is comprehensible for the stakeholders associated with the business</li>
                                        <br />
                                        <li>Present the findings in form of a systematic presentation and also incorporate the use of bar graphs, histograms and pie charts to portray the statistics in a better way</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A STATISTICIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are a set of specific requirements that one need to fulfil in order to work as a statistician in United States of America. The Statistician:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have secured a Bachelor’s degree in statistics or mathematics</li>
                                        <br />
                                        <li>Must have a Master’s or Doctorate degree in the aforementioned fields for acquiring better jobs</li>
                                        <br />
                                        <li>Must have a command on both applied and theoretical statistical techniques</li>
                                        <br />
                                        <li>Must be familiar with the applications of calculus, algebra and probability in statistical analysis</li>
                                        <br />
                                        <li>Must know how to test a proposed hypothesis and then sum it up in a statistical summary</li>
                                        <br />
                                        <li>Must be well familiar with R and SAS languages</li>
                                        <br />
                                        <li>Must have a command on programming languages which includes languages like Python, C/C++, Java, Perl</li>
                                        <br />
                                        <li>Must know the basics regarding operation of databases important to statistical analysis like NoSQL</li>
                                        <br />
                                        <li>Secure certification from American Statistical Association</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEP BY STEP GUIDE TO BECOME A STATISTICIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        For your convenience, we have compiled a step by step guide to help you find out the right way to become a statistician in US.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        First of all, one needs to study statistics, mathematics and preferably take AP classes in Calculus and Algebra during High School. One needs to secure good grades in the given subjects so that one could become eligible to pursue a college degree in the given subjects. It is compulsory for every statistician to have a Bachelor’s degree in statistics or mathematics to apply for entry level job. But if one is aiming for a job in a high end company or work an executive job then one needs to secure a Master’s degree or a Doctorate in statistics.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Acquire A Job And Learn Statistical Techniques</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One should learn R and SAS languages as well as programming languages which includes languages like Python, C/C++, Java, Perl while completing the college degree. One could also participates in statistics competitions like Kaggle, DrivenData, and KDnuggets in order to test and compare the statistical skill set with other statisticians.
                                        It is beneficial because due to these steps one could easily acquire an entry level job after completing the degree. Otherwise one would have to undergo an internship program or apply for a job and then receive on the job training which covers all the basics about applying statistical techniques using statistics software in order to analyze data.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Secure Certification From American Statistical Association</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to have at least five year work experience to work as a professional statistician in the field. That also makes one eligible to apply for the statistician certification provided by the American Statistical Association. The entry-level GStat Certification does not require much experience and one could easily secure it after completing the graduate degree.
                                        <br />
                                        But the PStat Certification is for professional statisticians only as one needs to submit documentations of work experience, samples of statistical analysis as well as letter of recommendation provided by the company of the statistician to finally secure the PStat certification.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>TIME DURATION REQUIRED TO BECOME A STATISTICIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First and foremost, one needs to invest 4 years in securing a Bachelor’s degree in mathematics and statistics. Furthermore, one needs to study for two more years to acquire the degree of Master’s in the same field. After that one needs to have secured at least five year experience working in the statistics field in order to apply for the certification provided by the American Statistical Association. So, in conclusion, we can state that after high school one needs to invest 10 to 12 more years to finally become a Statistician in US.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>AVERAGE ANNUAL EARNINGS OF A STATISTICIAN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        An average Statistician in America earns the annual wage of approximately $88,190 as stated by the United States Bureau of Labor Statistics. The states of New Jersey, District of Columbia and Maryland are the American states that offer best financial incentives to the Statisticians in US.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}