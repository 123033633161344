import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Fish_and_game_warden.css';
import fish_and_game_warden from '../images/Fish-and-Game-Warden.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Fish_and_game_warden = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Fish and Game Warden - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FISH AND GAME WARDEN
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Fish And Game Warden</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Fish And Game Warden</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Fish And Game Warden</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Fish And Game Warden</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Fish And Game Warden Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A FISH AND GAME WARDEN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Fish and game warden are the law enforcement officers that are responsible for protecting and guarding the wildlife. A fish and game warden usually carries out a lot of different on job duties. Generally, a fish and game warden:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Enforce laws regarding wildlife protection</li>
                                        <br />
                                        <li>Monitor the conditions of wildlife living in his specific area</li>
                                        <br />
                                        <li>Take notice of the human behavior in areas designated for wildlife</li>
                                        <br />
                                        <li>Make sure that the people hunting in the area are professional and licensed hunters</li>
                                        <br />
                                        <li>Ensure that the hunters are not doing excessive hunting of the wildlife</li>
                                        <br />
                                        <li>Provide protection to the endangered species</li>
                                        <br />
                                        <li>Make sure that no one is hunting and disturbing the habitat of endangered species</li>
                                        <br />
                                        <li>Organize search parties for people getting lost in the area</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A FISH AND GAME WARDEN</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={fish_and_game_warden} alt="STEPS TO BECOME A FISH AND GAME WARDEN" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A FISH AND GAME WARDEN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here are a set of requirements that every person aiming to become a fish and game warden must fulfill. A fish and game warden:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must have a 20/20 vision</li>
                                        <br />
                                        <li>Must possess a valid Commercial Driver’s License</li>
                                        <br />
                                        <li>Must have a clean background with no felony, drug abuse or traffic violation charge</li>
                                        <br />
                                        <li>Must have an associate or bachelor’s degree</li>
                                        <br />
                                        <li>Complete training from the academy</li>
                                        <br />
                                        <li>Must be physically fit</li>
                                        <br />
                                        <li>Can live in wilderness for long periods</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A FISH AND GAME WARDEN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is a step by step guide to facilitate you in learning how one becomes a fish and game warden in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Basic Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every person thinking of pursuing a career as a fish and game warden must be a permanent United States Citizen and must be of age 18/21 at least and 36 years old at maximum. In addition to that, one must have a High School Diploma or GED at least. The one must have an associate or bachelor’s degree in Zoology, Wilderness survival, Environmental science, Park or wildlife management, Criminal justice or in related fields.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Meet The Requirements And Pass The Test</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally completed the educational degree one must try to fulfill the rest of the requirements. One must obtain a Commercial Driver’s License and possess a 20/20 vision. Then one must focus all the energies to become physically fit to pass the physical test. One also needs to study the given syllabus to successfully clear the written examination. It is extremely important for a fish and game warden to have a clean criminal record with no felony, drug abuse or traffic violation charge.
                                        <br />
                                        Some states also ensure that the candidate has no involvement in any sort of domestic violence and sexual abuse case. They also take a morality test in which they test the moral values and ethics of a candidate as well. When one successfully passes all the tests and receives the certification then one is eligible to join the academy to train for the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Secure The Job And Then Complete Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The training in the academy takes nearly 44 weeks or six to eight months. During the training one works alongside experienced officials and learns how to do the office work as well as the field job. During the training one learns about how to protect animals from poachers and how to conserve endangered species. After completing the training one formally joins the job and work as a team with other fish and game wardens. One can also get specialized training in boat operations or aircraft piloting for better employment opportunities
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FISH AND GAME WARDEN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes two years to complete as associate degree while it takes four years to complete a bachelor’s degree in Zoology, Wilderness survival Environmental science, Park or wildlife management, Criminal justice or in related fields. It takes nearly 44 weeks or six to eight months to complete the fish and game warden training. Hence, it takes around 3 to 5 years to pursue a career a professional fish and game warden in United States of America
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A FISH AND GAME WARDEN USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        According to the facts and figures provided by the United States Bureau of Labor Statistics a fish and game warden generally earns around $59,260 per year. Texas, New York, California and North Carolina are some of the states that provide maximum employment opportunities to Fish and game wardens.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}