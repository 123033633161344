import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Phlebotomist.css';
import phelebotomist from '../images/phelebotomist.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Phlebotomist = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Phlebotomist - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PHLEBOTOMIST
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Phlebotomist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Phlebotomist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Phlebotomist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Secure A Job And Work Hard To Excel</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Long Does It Take To Become A Phlebotomist</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">How Much Does A Phlebotomist Usually Earns</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PHLEBOTOMIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A phlebotomist is a medical technician that has expertise in drawing blood from the patients to use it as a sample to run diagnostic tests and aid in recognizing the disease. A phlebotomist carries out a number of different duties. A phlebotomist usually:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Take blood samples from the patients</li>
                                        <br />
                                        <li>Draw the blood for diagnostics of the patient’s disease</li>
                                        <br />
                                        <li>Compare the blood types and take the blood for donations</li>
                                        <br />
                                        <li>Draw the blood for research purposes</li>
                                        <br />
                                        <li>Arrange, catalogue and make an inventory of all the blood samples</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A PHLEBOTOMIST</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={phelebotomist} alt="STEPS TO BECOME A PHLEBOTOMIST" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PHLEBOTOMIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are thinking of choosing your career path as a phlebotomist then here is a list of requirements that every phlebotomist must fulfill. A phlebotomist must be:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>At least 18 years of age</li>
                                        <br />
                                        <li>Have at least High School Diploma or GED or any degree equivalent to it</li>
                                        <br />
                                        <li>Have the associate degree from a phlebotomy school</li>
                                        <br />
                                        <li>Able to remain unaffected by the sight of blood</li>
                                        <br />
                                        <li>Have great hand and eye coordination to work with the needles</li>
                                        <br />
                                        <li>Well aware of the human anatomy</li>
                                        <br />
                                        <li>Well familiar with the medical terms and the medical jargon</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PHLEBOTOMIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to be well aware of the journey it takes to become a phlebotomist then here is a step by step guide for your aid.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Basic Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One must be at least 18 years of age and have a High School Diploma or GED or any degree equivalent to it in order to enroll in a phlebotomy school to become a phlebotomist.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Enroll In A Phlebotomy School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In the phlebotomy school the teachers teach the young students; how to draw blood, how to find the blood vessels as well as the proper way to use the medical equipment. In addition to that, they also teach them the medical jargon as well as familiarize them with the proper way to catalogue, record and store the blood samples.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Get License And Certification For Phlebotomy</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully graduating from the phlebotomy school, one must prepare to pass the state test to get the license of phlebotomy. The test includes a written test and a practical test where the blood drawing skills of the candidate are reviewed. When you pass the examination, you finally become a licensed phlebotomist and can apply for a job. But if you want to broaden the choice of jobs then you need to secure certification for a national institute.
                                        American Medical Technologists (AMT), American Society for Clinical Pathology (ASCP) and National Healthcareer Organization (NHA) are some of the prominent national organizations that provide the certification for phlebotomy. They have their own phlebotomy tests which a candidate has to pass to get the certification of that specific institute.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>SECURE A JOB AND WORK HARD TO EXCEL</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        You can apply for a phlebotomy job after graduating from phlebotomy school and acquiring the state license. But the chances of securing a stable and high paying phlebotomy job increases after acquiring the certification from a national institute. A phlebotomist can work at a hospital, clinic or a laboratory where there is high demand of drawing blood for diagnostics, donations, research or some other purposes.

                                        After acquiring the license as well as the certification from a renowned institute one must indulge oneself in attending workshops and seminars to stay in touch with the latest developments. One should also attend seminars and blood camps to know about the latest blood drawing techniques and to learn from the senior and experienced phlebotomists.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PHLEBOTOMIST?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One must have at least a High School Diploma or GED to enroll in the phlebotomy school. The course offered by the phlebotomy school is about 4 to 8 months long, depending on the rules and regulations of the state where you live. After graduating from the phlebotomy school, one has to pass the written as well as the practical test to get the Phlebotomy License. Afterwards, if one wants to get certification from a national institute then one has to take their special test which will require time as well.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>HOW MUCH DOES A PHLEBOTOMIST USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A phlebotomist usually ears around $34,480 per annum according to the statistics of the United States Bureau of Labor Statistics. The wages rates are different for each state but the state of California pays highest wages to the phlebotomists in whole of the United States of America.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}