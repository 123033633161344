import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Cna.css';
import cna from '../images/Cna.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Cna = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a CNA - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CNA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Certified Nursing Assistant (CNA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Certified Nursing Assistant (CNA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Certified Nursing Assistant (CNA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Certified Nursing Assistant (CNA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Certified Nursing Assistant (CNA) Usually Earn?</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Suggestions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A CERTIFIED NURSING ASSISTANT (CNA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Certified Nursing Assistant (CNA) is the person that takes scare of the patient and overall keeps an eye on the well-being of the patient. One can find Certified Nursing Assistant (CNA) doing multiple important jobs in many health institutions. Here is a general inventory of the duties that every Certified Nursing Assistant (CNA) is expected to carry out. A Certified Nursing Assistant (CNA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Take vital readings of a patient like blood pressure, body weight, blood pressure</li>
                                        <br />
                                        <li>Note down all these vital readings and present them to the doctor checking the patient</li>
                                        <br />
                                        <li>Take previous medical history of the patient</li>
                                        <br />
                                        <li>Question the patient about the symptoms and make an inventory of them</li>
                                        <br />
                                        <li>Aid the physically disabled patients in basic tasks like turning side, sitting upright and getting in wheelchair</li>
                                        <br />
                                        <li>Help patients in bathing, dressing, combing hair and other tasks of personal maintenance and hygiene</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CNA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={cna} alt="STEPS TO BECOME A CNA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A CERTIFIED NURSING ASSISTANT (CNA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are some specific requirements to become a Certified Nursing Assistant (CNA), some of them are mentioned below. A Certified Nursing Assistant (CNA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18 years old but some states allow 16 or 17 years to become a Certified Nursing Assistant (CNA) as well</li>
                                        <br />
                                        <li>Must have a High School Diploma or GED</li>
                                        <br />
                                        <li>Must have CNA training from an accredited medical institution or a nursing school</li>
                                        <br />
                                        <li>Pass the Certified Nursing Assistant (CNA) certification test</li>
                                        <br />
                                        <li>Have empathy for patients and behave kindly with physically or mentally disabled patients</li>
                                        <br />
                                        <li>Should be able to stomach the sight of open wounds, blood and bodily fluids</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A CERTIFIED NURSING ASSISTANT (CNA)</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are thinking of becoming a Certified Nursing Assistant (CNA) in near future then help us guide you to achieve your dream. Here is the step by step guide to teach you how to become a Certified Nursing Assistant (CNA).
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education And CNA Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One needs to have at least a High School Diploma or GED to enroll in a Certified Nursing Assistant (CNA) training program. One can enroll in an accredited medical institution or a nursing school to get the required training. It nearly takes 4 to 12 weeks or sometimes nearly six months to complete the Certified Nursing Assistant (CNA) training. The training must have at least 75 hours of classroom and also include basic practical clinical training as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Take Exam For Certified Nursing Assistant Certificate</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully completing the training, a candidate should strive to get the certificate in order to practice as a CNA. Mostly, it is advised that one should work at least for four months as an intern in order to know more about the field. It surely helps in passing the practical examination to get the certification.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Apply For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally secured the Certified Nursing Assistant (CNA) certification one should look for a job opening. One can work in a medical institution, private clinic, hospital or in medical dispensaries as a Certified Nursing Assistant (CNA).
                                        In addition to that, one could also work as hands on patient caregiver by living in the same home as the patient. It is the duty of that nurse to perform basic task for the invalid patient like bathing, dressing and combing the hair. The Certified Nursing Assistant (CNA) also helps the patient in moving around the house and doing basic chores. Furthermore, the nurse also takes the patient’s vitals daily and makes detailed notes of the vitals and the symptoms of the patient to be presented to the doctor during the doctor’s visit.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CERTIFIED NURSING ASSISTANT (CNA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One enrolls in a rushing school to get the Certified Nursing Assistant (CNA) training after completing the High School Diploma. Afterwards, it takes nearly takes 4 to 12 weeks or nearly 6 months to get the training. Furthermore, one has to pass the written and practical exam to get the CNA Certification. So it almost takes one year to pursue a career as Certified Nursing Assistant (CNA) in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A CERTIFIED NURSING ASSISTANT (CNA) USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Certified Nursing Assistant (CNA) usually secures the median pay of approximately $28,530 per year as per the data provided by the United States Bureau of Labor Statistics. Alaska, Connecticut and California are some of the states that provide best wage rate to the Certified Nursing Assistant (CNA).
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/cosmetologist/">Steps to become a Cosmetologist</NavLink></li>
                                        <li><NavLink to="/medical-assistant/">Steps to become a Medical Assistant</NavLink></li>
                                        <li><NavLink to="/midwife/">Steps to become a Midwife</NavLink></li>
                                        <li><NavLink to="/nurse/">Steps to become a Nurse</NavLink></li>
                                        <li><NavLink to="/nurse-anesthetista/">Steps to become a Nurse Anesthetist</NavLink></li>
                                        <li><NavLink to="/nurse-practitioner/">Steps to become a Nurse Practitioner</NavLink></li>
                                        <li><NavLink to="/pharmacist/">Steps to become a Pharmacist</NavLink></li>
                                        <li><NavLink to="/phlebotomist/">Steps to become a Phlebotomist</NavLink></li>
                                        <li><NavLink to="/registered-nurse/">Steps to become a Registered Nurse</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}