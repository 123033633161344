import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Nurse.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Nurse = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Nurse - Salary, Qualifications, Skills & Reviews  : A Step-by-Step Guide</title>
                    <meta name='description' content={`Considering a career in nursing? In this guide, we share the steps to becoming a nurse, including education and licensure. Learn how to become a nurse today!`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A NURSE
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Nurse</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Nurse Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The nurses are medical personnel that work hand in hand with the doctors as well as the surgeons to care for the patients. A nurse carries out a wide range of a number of different types of duties while on the clock during the job. A nurse:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Provides assistance to the physician or surgeon</li>
                                        <br />
                                        <li>Takes care of the injuries of the patients</li>
                                        <br />
                                        <li>Takes vitals of the patients like the temperature, body weight, blood pressure and temperature of the patient</li>
                                        <br />
                                        <li>Take medical history of the patients</li>
                                        <br />
                                        <li>Inquire about the allergies that a patient could have</li>
                                        <br />
                                        <li>Present the aforementioned information to the doctor so that the doctor can make better diagnosis</li>
                                        <br />
                                        <li>Makes recovery charts of the patients under one’s care</li>
                                        <br />
                                        <li>Notes down the symptoms as well as severity of the patients’ injuries</li>
                                        <br />
                                        <li>Teaches the patients how to take the medicine</li>
                                        <br />
                                        <li>Injects IVs as well as administer injections to the patients</li>
                                        <br />
                                        <li>Take blood samples from the patients for various diagnostic tests</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are some specified requirements that is mandatory for every nursing candidate to fulfil in order to pursue a career as a nurse. A nurse:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 21 years of age</li>
                                        <br />
                                        <li>Must complete the prerequisites like excellent grades in Sciences, English, Statistics and other subjects in GED</li>
                                        <br />
                                        <li>Must have at least a diploma or an associate degree in Nursing</li>
                                        <br />
                                        <li>Must have a Bachelor’s degree in Nursing in order to pursue specialization</li>
                                        <br />
                                        <li>Must be quick on feet and can work long strenuous hours</li>
                                        <br />
                                        <li>Must be welcoming and able to make the patient feel at ease</li>
                                        <br />
                                        <li>Must be physically fit at job so that one does not infect the patients one is taking care of</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A NURSE</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are imagining your future working as a nurse and saving lives of others then let us guide you how to fulfil your dream. Here is the detailed step by step process of how one becomes a nurse in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The first thing that a student must do is to fulfill all the prerequisites that are required by the accredited nursing schools and colleges like high grades in biology, chemistry, psychology, physiology and other related niches. Then one can either pursue a diploma, as associate degree or a bachelor’s degree in nursing. All these degrees provide you the opportunity to apply for the license of nurse.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Acquire License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One can easily apply for the license of nursing after getting a diploma, an associate or a Bachelor’s degree in nursing and can apply for the job. But if one wants to work in a respected hospital or want to open a separate practice then it is highly advisable to choose Bachelors of Science in Nursing (BSN) or convert your associate nursing degree into it by taking extra credit hours classes.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Look For The Niche Most Suitable For You And Specialize In It</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has acquired the official license then one should definitely work as a nurse in a hospital or a clinic to gain experience of the field. One should at least work a year or two in nearly every type of medical ward in the hospital or clinic before choosing the field of specialization for the Master’s degree. It will help one to make the most practical decision based on knowledge, experience as well as ease of mind.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A NURSE?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes a year to complete a diploma in nursing, two years to complete the associate degree probably four years to complete a Bachelor’s degree in nursing. Then one has to apply for the nursing license in order to practice as a nurse. If one wants to do specialization in a field then one also has to secure the Master’s degree of 2 to 3 years and then license of that specialized field. Sometimes, one also has to purse the PhD degree in specific niches as well to secure better jobs. So, we can conclude that it nearly takes 2 to 8 years to pursue a career as a nurse in the United States.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A NURSE USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics state that a licensed practical nurse (LPN) averagely earns the wages of $ 46,420 per year. Some of the states that provide best paying rates to their licensed practical nurses are California, District of Columbia and Delaware.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}