import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Midwife_in_pennsylvania.css';
import { NavLink } from 'react-router-dom';

export const Midwife_in_pennsylvania = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=pennsylvania")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=pennsylvania&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Midwife in Pennsylvania - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`To become a Midwife in Pennsylvania, one must first get their certification.  Find Pennsylvania Midwifery schools and programs here.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MIDWIFE IN PENNSYLVANIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Responsibilities Of A Midwife In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Midwife In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Midwife In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Midwife In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Midwife Make In Pennsylvania</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Career Outlook For Midwife In Pennsylvania</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In Pennsylvania, Midwives are the healthcare professionals who offers basic healthcare advice, patient and parenting counselling, assistance during deliveries and Cesarean birth, gynaecological care and after birth care to babies and mothers. They also provide neonatal care, postpartum care, care/counselling after termination or miscarriage and handle labor emergencies.
                                        The essentials to know about this profession are given below:
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>RESPONSIBILITIES OF A MIDWIFE IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Assist women during pregnancy, labor and delivery</li>
                                        <br />
                                        <li>Monitoring of pregnancy and foetus</li>
                                        <br />
                                        <li>Assessing healthcare issues</li>
                                        <br />
                                        <li>Provide care for new born infants</li>
                                        <br />
                                        <li>Administration of medications</li>
                                        <br />
                                        <li>Support during gynaecological exams</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A MIDWIFE IN PENNSYLVANIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row heading_area">
                                    <h4>Personal requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Able to handle critical situations</li>
                                        <br />
                                        <li>Able to handle emergencies</li>
                                        <br />
                                        <li>Able to cope up with stressful conditions</li>
                                        <br />
                                        <li>Patient and compassionate</li>
                                        <br />
                                        <li>Holds good observational skills</li>
                                        <br />
                                        <li>Able to assess and diagnose health care issue</li>
                                    </ul>
                                </div>
                                <br />
                                <div className="row heading_area">
                                    <h4>Educational requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A bachelor’s degree program</li>
                                        <br />
                                        <li>A master’s in midwifery</li>
                                        <br />
                                        <li>A license in Midwifery</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A MIDWIFE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The first step towards this profession is to complete educational requirements. In Pennsylvania, a lot of institutes offer a 4-years bachelor’s degree program in nursing with subjects such as pathology, anatomy, pregnancy care, embryology and pharmacology. This 4-years degree aids the candidate in developing skills such as competency, handling of emergencies, complication management and assessment and diagnosis of healthcare issues.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Enroll In A Midwifery School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Many institutes in Pennsylvania offer a 3-years midwifery training for the eligible candidates. In these 3-years, the candidate gets hands-on experience and training for labor emergencies, childcare, neonatal and postpartum care, pregnancy care and women care. After completing this training, the candidate becomes eligible to survive in any midwifery practice.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Obtain A Midwifery License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The Penned State Board of Nursing is authorized to issue license to midwives in Pennsylvania. The candidate can also apply for certificates through American Midwifery Certification Board or National Certification Corporation. After completing the midwifery training, the candidate can apply for license as well as certifications.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A MIDWIFE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes an estimate of almost 8 years to become a midwife in Pennsylvania. It includes the time required for bachelor’s degree (4-years), master’s in midwifery (3-years) and the time required to obtain license and a firm job (almost an year). The time limit can vary from person-to-person.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A MIDWIFE MAKE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A licensed midwife makes an average of around $108,623 per year, in Pennsylvania. However, it was recorded that in 2019, the average salaries usually are in between $99,879 to $123,088, respectively. The yearly salaries may vary depending upon the expertise, working hours and number of deliveries assisted.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>CAREER OUTLOOK FOR THE MIDWIFE IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is predicted that in between 2018-28, the job opportunities for the midwives in Pennsylvania may rise at the rate of 21%. Whereas, another report states that in coming ten years, the opportunities may increase upto 19-26%.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-midwife-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>MIDWIFE SCHOOLS IN PENNSYLVANIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/pennsylvania/" className="btn btn-dark">Midwife Schools in Pennsylvania</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}