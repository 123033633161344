import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Paralegal.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Paralegal = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Paralegal - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PARALEGAL
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Paralegal</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Paralegal</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Paralegal</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Paralegal</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Paralegal Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A PARALEGAL</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The paralegal, often called a legal assistant, deals in legal matters, provides legal advice, prepare legal documentations and usually works as an assistant of the lawyer. The routine of a paralegal is very hectic due to the plethora of law related duties that every paralegal is required to perform. Here are some of the law related duties that a paralegal often performs during the job. A paralegal:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Assist lawyers in legal dealings</li>
                                        <br />
                                        <li>Hold meetings with clients, police officials and other lawyers and attorneys</li>
                                        <br />
                                        <li>Draft legal paperwork that includes wills, contracts , property documents and other important legal documents</li>
                                        <br />
                                        <li>Make sure that the briefcase of the lawyer is properly arranged and have the required documents for the meetings or court hearings</li>
                                        <br />
                                        <li>Keep the legal database functional as well as up to date</li>
                                        <br />
                                        <li>File all the necessary details required for a court hearing</li>
                                        <br />
                                        <li>Make a list of the evidences, detailed reports of the witness testimonies and proofs which a lawyer has to present before the court</li>
                                        <br />
                                        <li>Complete all the necessary paperwork required for the case</li>
                                        <br />
                                        <li>File important documents to the court clerk like pleading documents</li>
                                        <br />
                                        <li>Make a record of all the proceedings of the case hearings and the decisions made by the judge and jury</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PARALEGAL</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Lawyers and paralegals have grown very much in demand over the years that is why, every candidate for the post is required to fulfill a number of requirements. A paralegal:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must have an associate degree in law</li>
                                        <br />
                                        <li>Have an approved paralegal certification</li>
                                        <br />
                                        <li>Must know about the major laws regarding the area of interest</li>
                                        <br />
                                        <li>Must be well aware of the correct way to file a case</li>
                                        <br />
                                        <li>Must be familiar with the way how to structure affidavits, court pleadings, will and other major legal documents</li>
                                        <br />
                                        <li>Must know how to operate Microsoft word, Excel and Outlook</li>
                                        <br />
                                        <li>Must be confident as well as assertive in public dealings</li>
                                        <br />
                                        <li>Must be able to work long arduous hours buried in paperwork</li>
                                        <br />
                                        <li>Must be quick witted and able to survive in high pressure environment</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PARALEGAL</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        We have outlined the process of becoming a paralegal in the step by step guide below to facilitate the aspiring paralegals-to-be.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        The National Federation of Paralegal Associations has stated two routes for the candidates to take in order to acquire the education for entry level job. Every candidate has to either secure an associate degree or a Bachelor’s degree in paralegal studies from the accredited colleges for the entry level job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Complete The Prerequisites</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the education one should enroll in a short course, or online program to learn how to operate Microsoft Office applications and file data. In addition to that, one should also do a short course about paralegal work or do an internship under a senior paralegal or lawyer to learn the duties of the paralegal.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Secure Paralegal Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing all these step, one should look for a job opening and apply as a paralegal. One must work as a paralegal for at least one year as per the prerequisite for obtaining the certification. After one has acquired at least one year in field experience, one is eligible to sit in the written certification examination. In order to secure certification for CORE Registered Paralegal (CRP) credential (CRP), a candidate is required to successfully clear the Paralegal CORE Competency Exam (PCCE). When one has secured the certification one gets promoted to a higher post or become a paralegal of a more influential lawyer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PARALEGAL?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one has to pass with an associate degree of 2 years or a Bachelor’s degree of 4 years in paralegal studies. One is also advised to enroll in an online course program to hone the skills necessary for every paralegal. Then one has to gain one year work experience and then pass a written examination in order to secure the paralegal certification. So, we can conclude that for one to practice as a paralegal in USA one has to go through the arduous process that spans over 3 to 5 years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PARALEGAL USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau for Labor Statistics has issued that a paralegal in USA averagely earns $50,940 in the whole year. The American states that provides best financial incentives to the paralegals are District of Columbia, Alaska and California.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}