import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Real_estate_agent.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Real_estate_agent = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Real Estate Agent - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A REAL ESTATE AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Real Estate Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Real Estate Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Real Estate Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Real Estate Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Real Estate Agent Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A REAL ESTATE AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The real estate agent provides assistance to the potential buyers as well as sellers to buy or sell the property at the price that is best suited to both parties. The real estate agent often have to tour a number of properties and attend meetings with the employers to seal the deals. The real estate agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Help people in buying and selling properties</li>
                                        <br />
                                        <li>Discuss the commission rate with both parties</li>
                                        <br />
                                        <li>Conduct meetings with buyers and note down their requirements</li>
                                        <br />
                                        <li>Suggest properties to the buyers that have the specifications they want and are within their budget</li>
                                        <br />
                                        <li>Conduct tours of the property for the potential buyers</li>
                                        <br />
                                        <li>Meet with the sellers and discuss the requirements of the buyers</li>
                                        <br />
                                        <li>Determine the price of the property by comparing it with the market rate of that time</li>
                                        <br />
                                        <li>Arrange a mutual meeting of both sellers and buyers and negotiate on a suitable deal that is suitable to both parties</li>
                                        <br />
                                        <li>Do necessary paperwork to finalize the transfer of power</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A REAL ESTATE AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There is an increase in demand in sale and purchase of property so the demand for the real estate agent is increasing as well. So, in order to meet the demand of the real estate agent one has to meet a number of requirements to become a real estate agent. The real estate agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must be a permanent resident of United States of America</li>
                                        <br />
                                        <li>Must have completed the pre licensing course as per required by each state laws</li>
                                        <br />
                                        <li>Should secure some in field experience working as the real estate agent</li>
                                        <br />
                                        <li>Have to acquire license of the real estate agent by passing the examination</li>
                                        <br />
                                        <li>Must know how to operate Microsoft Office in order to perform data entry</li>
                                        <br />
                                        <li>Must be cordial and friendly with the clients</li>
                                        <br />
                                        <li>Must have excellent communication skills and should be able to close a deal with the clients</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A REAL ESTATE AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you love to buy and sell houses and have the wit to convince the clients to seal a deal then you should definitely take help from the step by step guide given below and become a real estate agent!
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One must be at least 18/21 years old and must have permanent residency in USA to become eligible for the job. In addition to that, one must have successfully passed the High School and have secured a diploma or GED.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Complete The Pre Licensing Course</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing High School, one needs to complete to pre licensing course. It is compulsory successfully complete the course in order to become eligible to give the licensing exam. The course has different outlines and credit hours for different states depending on the laws of the particular state, one can take those courses in community college, real estate school or online as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Obtain The License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the pre licensing course, one is eligible to take the real estate licensing examination. The examination is taken digitally, in a computerized form, and is usually consisted of two basic sections which are separately scored. Section one deals with the real estate laws and specifications that are followed in the whole nation. While section two test the knowledge of the candidate about the laws and regulation regarding real estate of the specific state from where the candidate is giving the examination. One needs to pass both sections to secure the certification or retake the whole examination again in case of failure.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A REAL ESTATE AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One first of all needs to secure a High School Diploma then one has to complete the pre licensing course. It has different credit hours for different states like State of California requires 135 hours while State of New York requires 75 hours only. After that, one has to clear the licensing examination to become a licensed real estate agent in USA. So, you can expect to become a professional real estate agent in USA in around 6 months to a year after securing the High School Diploma.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A REAL ESTATE AGENT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics has estimated that an average real estate agent earns probably $ 50,300 per year. One can definitely earn better wages by working as a real estate agent in American States like New York, Wyoming and North Dakota.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}