import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Forensic_scientist_in_california.css';
import { NavLink } from 'react-router-dom';

export const Forensic_scientist_in_california = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=california")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Forensic Scientist In California - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A FORENSIC SCIENTIST IN CALIFORNIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Forensic_Scientist_in_California">Responsibilities Of A Forensic Scientist In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Forensic_Scientist_in_California">Requirements To Become A Forensic Scientist In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Forensic_Scientist_in_California">Steps To Become A Forensic Scientist In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_does_it_take_to_become_a_Forensic_Scientist_in_California">How Long Does It Take To Become A Forensic Scientist In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Forensic_Scientist_make_in_California">How Much Does A Forensic Scientist Make In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_for_a_Forensic_Scientist_in_California">Career Outlook For A Forensic Scientist In California</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A Forensic Scientist in California is an official who collect the physical evidence, analyses them and make legal statements during criminal investigation. Some of the Forensic Scientists go to crimes scenes to gather evidence whereas the other ones perform analytical tests on the collected evidence.
                                        <br />
                                        The necessities that must be acknowledged before opting it as a profession in California is given here:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Forensic_Scientist_in_California">
                                <h2>RESPONSIBILITIES OF A FORENSIC SCIENTIST IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Identification of physical evidence</li>
                                        <br />
                                        <li>Collection of physical evidence</li>
                                        <br />
                                        <li>Analysis of physical evidence</li>
                                        <br />
                                        <li>Communication with the law enforcing officials</li>
                                        <br />
                                        <li>Make legal statements against criminals for the court cases.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Forensic_Scientist_in_California">
                                <h2>REQUIREMENTS TO BECOME A FORENSIC SCIENTIST IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have a US citizenship</li>
                                        <br />
                                        <li>Must have a Clear criminal record</li>
                                        <br />
                                        <li>Must pass a drug test</li>
                                        <br />
                                        <li>Must hold strong Logical and analytical skills</li>
                                        <br />
                                        <li>Must hold a bachelor’s degree program</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Forensic_Scientist_in_California">
                                <h2>STEPS TO BECOME A FORENSIC SCIENTIST IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is mandatory to hold a Bachelor’s degree in order to become a Forensic Scientist in California. The degree program must be in forensic science, chemistry, biology or physical anthropology. The 4-year degree program helps the candidate to develop analytical skills, logical reasoning and qualitative analysis.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Get Experience For The Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Getting experience before applying for job as an official is very important. Once the candidate has completed the bachelor’s degree program, they ought to look for an internship to get experience and guidance. In California, the college or university’s placement office may helps the candidates in finding internships.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Consider A Master’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The bachelor’s degree program is the minimum educational requirement but a candidate can apply for a master’s degree program in subjects like microscopy, toxicology and criminology. In California, for higher positions the higher educational qualifications can play a vital role.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Find A Job In California</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In California, forensic scientists can find jobs in the county or state’s crime labs or may contact The California Department of Justice. To get a job, the candidate must pass an extensive background check and a drug test.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Consider Getting Certified</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is not mandatory but if the candidate is interested, they can get their certification through The American College of Forensic Examiners International or by various state institutions of California.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_does_it_take_to_become_a_Forensic_Scientist_in_California">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A FORENSIC SCIENTIST IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes 4-year to complete a bachelor’s degree program and an optional 2-3 years Master degree program to complete the educational requirements to become a Forensic Scientist in California. After that it may take around 4-5 months to get appointed with a firm job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Forensic_Scientist_make_in_California">
                                <h2>HOW MUCH DOES A FORENSIC SCIENTIST MAKE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Average salary for a Forensic Scientist in California was recorded around $62,625 per year. The average wages were recorded around $41.90 per hour in California which is 52% higher than the national average wages.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_for_a_Forensic_Scientist_in_California">
                                <h2>CAREER OUTLOOK FOR A FORENSIC SCIENTIST IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that the opportunities for Forensic scientists may increase at the rate of 17% during 2018-28. In California, the existing number of forensic scientists is 7,594.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-forensic-scientist-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>FORENSIC SCIENTIST SCHOOLS IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/california/" className="btn btn-dark">Forensic Scientists Schools in California</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}