import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import './Animal_cop.css';
import animal_cop from '../images/animal_cop.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Animal_cop = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <div className="container">
            <LoadingBar color='blue' height={5} progress={progress} />
            <Helmet>
                <title>How To Become an Animal Cop - {year}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <br />
            <div className="container bg-dark text-white font-weight-bold">
                <div className="row p-2" style={{ fontSize: '150%' }}>
                    HOW TO BECOME A ANIMAL COP
                </div>
            </div>
            <br />
            <br />
            <div className="container p-0">
                <div className="row">
                    <div className="col-md-4">
                        <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                        <br />
                        <div className="container sticky-top">
                            <div className="row">
                                <ul className="steps_area3">
                                    <li><a onClick={(e) => removeDefault(e)} href={`#duties_of_an_animal_cop`}>Duties Of An Animal Cop</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#requirements_to_become_an_animal_cop`}>Requirements To Become An Animal Cop</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#STATES`}>STATES</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#steps_to_become_an_animal_cop`}>Steps To Become An Animal Cop</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#how_long_does_it_take_to_become_an_animal_cop`}>How Long Does It Take To Become An Animal Cop</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#how_much_does_an_animal_cop_usually_earn`}>How Much Does An Animal Cop Usually Earn</a></li>
                                    <br />
                                    <li><a onClick={(e) => removeDefault(e)} href={`#suggestions`}>Suggestions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row heading_area" id="duties_of_an_animal_cop">
                            <h2>DUTIES OF AN ANIMAL COP?</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: '600' }}>
                                    An animal cop is a law enforcement officer that works for the protection as well as the welfare of the animals. An animal cop carries out a range of different duties when on job. Here are some of the duties of an animal cop; an animal cop:
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <ul className="steps_area1">
                                    <li>Captures dangerous as well as stray animals</li>
                                    <br />
                                    <li>Take notice of animal cruelty and investigate the case</li>
                                    <br />
                                    <li>Rescue injured and trapped animals</li>
                                    <br />
                                    <li>Take care of domestic as well as wild animals</li>
                                    <br />
                                    <li>Provide care and attention to animals under their care</li>
                                    <br />
                                    <li>Enforce laws that are part of the animal control program</li>
                                    <br />
                                    <li>Provide testimony in court cases that involves animals</li>
                                    <br />
                                    <li>Write reports of the cases and incidents</li>
                                    <br />
                                    <li>Arrest people suspected of extreme animal cruelty</li>
                                    <br />
                                    <li>Respond and take action of emergency calls of people coming in contact with wild animals</li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                    <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A ANIMAL COP</h5>
                                </div>
                                <div className="col-md-5 p-0">
                                    <img src={animal_cop} alt="STEPS TO BECOME A ANIMAL COP" style={{ width: '100%' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row heading_area" id="requirements_to_become_an_animal_cop">
                            <h2>REQUIREMENTS TO BECOME AN ANIMAL COP?</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: '600' }}>
                                    There are a set of requirements that every candidate for the job of an animal cop has to fulfill. An animal cop:
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <ul className="steps_area1">
                                    <li>Must be a permanent citizen of United States of America</li>
                                    <br />
                                    <li>Must be 18 years of age</li>
                                    <br />
                                    <li>Must have a High School Diploma or GED</li>
                                    <br />
                                    <li>Should have an associate degree or bachelor’s degree in Animal sciences, veterinary sciences, law enforcement or other related fields.</li>
                                    <br />
                                    <li>Should have an affiliation with animals and enjoy working with them.</li>
                                    <br />
                                    <li>Knows how to handle and take care of different animals</li>
                                    <br />
                                    <li>Knows how to comfort animals that have become victim of animal cruelty</li>
                                    <br />
                                    <li>Must successfully pass the drug test</li>
                                    <br />
                                    <li>Have a Commercial Driver’s License (CDL)</li>
                                    <br />
                                    <li>Must know the details about Animal Control Procedures</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row heading_area" id="STATES">
                            <h2>SELECT STATES</h2>
                        </div>
                        <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                            <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                {
                                    states != null && states.length > 0 ?
                                        states.map((item, key) => {
                                            return (
                                                <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                    <a>{item.state}</a>
                                                </div>
                                            )
                                        })
                                        : ""
                                }
                            </div>
                        </div>
                        <div className="row heading_area" id="steps_to_become_an_animal_cop">
                            <h2>STEPS TO BECOME AN ANIMAL COP?</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: '600' }}>
                                    Here is a step by step guide to help you understand the process of becoming an animal cop in a comprehensive way.
                                </p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                <h5>Step 1:Education Of The Candidate</h5>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: `500` }}>
                                    Any person thinking of becoming an animal cop must be at least 18 years old. Furthermore, every candidate should have at least a High School Diploma, GED, or any degree equivalent to it. It is preferred if the candidate has an associate degree or a bachelor’s degree in Animal sciences, veterinary sciences, law enforcement or other related fields.
                                </p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                <h5>Step 2:Complete Accredited Animal Control Training Programs To Get Certification</h5>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: `500` }}>
                                    One can apply for the job after completing the education and can also receive on the job training as well. But it is preferable to get the certification provided by National Animal Control Association (NACA). National Animal Control Association (NACA) offer many accredited animal control training programs.
                                    Some states may offer their own respective certificate program, but primarily it is the duty of National Animal Control Association (NACA) to provide the certification. The certification program has an extensive course outline that covers all important fields like, animal protection laws and ethics, animal diseases and injuries and welfare laws. In addition to that, it also includes ways to handle animal cruelty, captured animals, exotic and wild animals and many more,
                                </p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                <h5>Step 3:Apply For The Job</h5>
                            </div>
                        </div>
                        <br />
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: `500` }}>
                                    When one has finally got the certification from National Animal Control Association (NACA), one can apply for a job. One can apply in zoos, animal shelter homes, aquariums, farms, stables and a number of different other places where animals are an integral part of the institution.
                                    One has to be at least 18/21 years old, must have a High School Diploma, an associate or bachelor’s degree and certification by National Animal Control Association (NACA) to apply for the job. It is compulsory for every candidate to have a Commercial Driver’s License (CDL) as well. Background check of every candidate is compulsory that is why one must have a clean record with no felony, drug abuse and other criminal charges.
                                    When one finally fulfill the requirements one finally secures the job as an Animal cop in United States of America.
                                </p>
                            </div>
                        </div>
                        <div className="row heading_area" id="how_long_does_it_take_to_become_an_animal_cop">
                            <h2>HOW LONG DOES IT TAKE TO BECOME AN ANIMAL COP?</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: '600' }}>
                                    It takes two years to complete an associate degree while it takes four years to complete a bachelor’s degree in Animal sciences, veterinary sciences, law enforcement or other related fields. Then it takes around 10 weeks to complete accredited animal control training programs which provides certification by National Animal Control Association (NACA). So it takes 1 to 4 years to become an animal cop in United States of America.
                                </p>
                            </div>
                        </div>
                        <div className="row heading_area" id="how_much_does_an_animal_cop_usually_earn">
                            <h2>HOW MUCH DOES AN ANIMAL COP USUALLY EARN?</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <p className="text-justify" style={{ fontWeight: '600' }}>
                                    The facts and figures provided by the United States Bureau of Labor Statistics state that an animal cop averagely earns $36,330 per year.
                                </p>
                            </div>
                        </div>
                        <div className="row heading_area" id="suggestions">
                            <h2>SUGGESTIONS</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                <ul className="profession_with_states">
                                    <li><NavLink to={`/business-analyst/`}>Steps To Become A Business Analyst</NavLink></li>
                                    <li><NavLink to={`/chef/`}>Steps to become a Chef</NavLink></li>
                                    <li><NavLink to={`/firefighter/`}>Steps to become a Firefighter</NavLink></li>
                                    <li><NavLink to={`/welder/`}>Steps To Become A Welder</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}