import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Parole_officer.css';
import Parole_Officer from '../images/Parole-Officer.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Parole_officer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Parole Officer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A PAROLE OFFICER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">What Are The Duties Of A Parole Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Parole Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Parole Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Parole Officer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Parole Officer Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>WHAT ARE THE DUTIES OF A PAROLE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A parole officer is a law enforcement specialist who works closely with the convicts that were previously incarcerated but are now released from the prison on parole. The duties that a parole officer usually carries out are mentioned in the list below. A parole officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Works closely with the convicts released on paroles</li>
                                        <br />
                                        <li>Helps them to rejoin the community</li>
                                        <br />
                                        <li>Make sure that the condition of the paroles are getting fulfilled</li>
                                        <br />
                                        <li>Prevents the convicts on parole to commit another crime</li>
                                        <br />
                                        <li>Helps the convicts to get a job</li>
                                        <br />
                                        <li>Admit the convicts into drug and alcohol rehabilitation centers</li>
                                        <br />
                                        <li>Encourage convicts to continue with their education</li>
                                        <br />
                                        <li>Attend parole hearings</li>
                                        <br />
                                        <li>Make a report on the parole proceedings and developments made</li>
                                        <br />
                                        <li>Regularly interact with the friends, family and people in the social circle of the convict</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A PAROLE OFFICER</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={Parole_Officer} alt="STEPS TO BECOME A PAROLE OFFICER" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A PAROLE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to know what it takes to become a parole officer then here is a list of requirements every candidate must fulfill. A parole officer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 21 years of age</li>
                                        <br />
                                        <li>Must have an associate, bachelor’s or master’s degree in criminal justice, social work, sociology and other similar niches</li>
                                        <br />
                                        <li>Must have a valid Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Should do an internship</li>
                                        <br />
                                        <li>Successfully pass the written, physiological and physical exam</li>
                                        <br />
                                        <li>Must have a clean record</li>
                                        <br />
                                        <li>Must pass the drug test</li>
                                        <br />
                                        <li>Must have no affiliation with any gang, mafia or cartel</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A PAROLE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to become a parole officer then here is the step by step for you to better understand the process of becoming a parole officer.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One must be 21 years old, have a High school Diploma as well as an associate, bachelor’s or a Master’s degree in criminal justice, social work, sociology and other similar niches for the job of a parole officer.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass Government Exam And The Background Check</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Every candidate has to pass the Government Exam; written, physical and drug tests to become a Parole Officer. It is compulsory for every candidate to undergo a thorough background check before finally securing the job. The background check includes verifying the law enforcement records, association with any sort of gang and any drug, traffic violation and felony charges. When one successfully pass the background check and the government exam one gets hired as a Parole Officer and enters the training program.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Successfully Complete The Training Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The training program lasts for 4 to 6 weeks and the newly hired officers are paid during that time as well. During the training program one works with a senior parole officer as his intern to closely observe and learn the duties of a parole officer. In the program firearms practice, physical training and other exercises are practiced to boost the stamina of the officer. The officer is also required to attend workshops and seminars regarding his field.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Securing An Employment Opportunity And Work For Success</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the degree, training program/internship one must secure an employment opportunity as a full time parole officer with cases of his own. Many parole officers in government sectors handle nearly 100 different cases at the same time. If one wants to work for a promotions one must secure certification from a nationally accredited institute. In addition to that one can also pursue a Master’s or a Doctorate degree in criminal justice, social work, sociology and other similar niches for better jobs.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A PAROLE OFFICER?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one must have a High school Diploma to apply for an undergraduate degree in criminal justice, social work, sociology and other similar niches. It takes two years to secure the associate degree while it takes four years to secure a bachelor’s degree in criminal justice, social work, sociology and other similar niches. Then one has to prepare and pass the written, physical and drug tests and submit to a background check before securing the job. It then takes 4 to 6 weeks to complete the training program. So depending on the degree, it takes 3 to 8 years to become a Parole Officer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A PAROLE OFFICER USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The facts and figures provided by Untied States Bureau of Labor Statistics state that an average parole officer earns around $54,080 per year.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}