import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Customs_agent.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Customs_agent = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Customs Agent - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CUSTOMS AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Customs Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Customs Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Customs Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Customs Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Customs Agent Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A CUSTOMS AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A customs agent serves the country by examining the passengers and the goods that are shipped into or out of the country. A customs agent usually carries out following duties mentioned in the inventory. A customs agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Assist lawyers in legal dealings</li>
                                        <br />
                                        <li>Enforce laws regarding goods entering or leaving the premises</li>
                                        <br />
                                        <li>Investigate all the items that enter or exit the country</li>
                                        <br />
                                        <li>Thoroughly checks the people that are coming in and going out of the country</li>
                                        <br />
                                        <li>Make an inventory of the goods that are screened by them</li>
                                        <br />
                                        <li>Take appropriate steps to avoid the smuggling of goods by checking all the necessary paperwork for it</li>
                                        <br />
                                        <li>Make sure that no sensitive material like illegal armory or drugs are hidden in the luggage of the passengers</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A CUSTOMS AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Becoming a customs agent is no easy feat as every candidate aiming to become has to meet a few requirements. Here is a quick list of the requirements that one has to meet in order to become a customs agent in USA. A customs agent
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have nationality of United States</li>
                                        <br />
                                        <li>Must be at least 21 years old and maximum 40 years old when applying for the job</li>
                                        <br />
                                        <li>Must have permanent residence in USA for at least three years before applying for the job</li>
                                        <br />
                                        <li>Must have a bachelor’s degree in criminal justice or other related fields</li>
                                        <br />
                                        <li>Must have a Commercial Driver’s License (CDL)</li>
                                        <br />
                                        <li>Must have a clean criminal background</li>
                                        <br />
                                        <li>Must not have any sort of involvement with any gang or mafia</li>
                                        <br />
                                        <li>Have to clear the entrance exam with flying colors</li>
                                        <br />
                                        <li>Must pass all the written, physical as well as medical test</li>
                                        <br />
                                        <li>Have to successfully clear the ethics and polygraph test</li>
                                        <br />
                                        <li>Must complete the 15 to 21 weeks training</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A CUSTOMS AGENT</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        We have provided you with the step by step guide to explain the process of becoming a customs agent in a comprehensive way.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        It is mandatory for every candidate to have a Bachelor’s degree in criminal justice, criminology or other niches related to the niche of law enforcement. In addition to that, one can also learn a foreign language like Spanish or do internships to broaden the horizon as well.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Pass The Entrance Exam</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the degree, one should look for a job opening as a customs agent on the official government website. Apply for the job in the location most convenient for the applicant and then one should prepare to study for the entrance exam. One has to fulfil all the aforementioned requirements and pass the written and physical tests as well as clear the background check and polygraph test to be accepted into the training program.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        The candidates are required to undergo 15 to 21 weeks of training at the Federal Law Enforcement Training Center depending on the laws of the respective states. The training focuses on teaching the customs agent how to carry out their duties including the firearms practice. Some states that have borders with foreign countries like Mexico have made it mandatory for the customs agent to learn Spanish as well which is part of their extended training.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4: Join The Job And Work For The Promotion</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        After completing the degree one finally joins the job as a customs agent and then one should work hard and pursue for promotion as well after a few years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CUSTOMS AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        A customs agent is required to have a Bachelor’s degree in criminal justice or in some other niches related to it. Then one has to prepare for the entrance exam where one has to pass the written, physical and polygraph tests as well as go through a thorough background check. When one successfully clear all these tests then one undergoes 15 to 21 weeks of training for a customs agent. In some states the candidates also have to put in extra 8 to 10 weeks in order to learn Spanish. So in short, it takes probably 5 to 6 years to become a customs agent in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A CUSTOMS AGENT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that a Customs agent in United States averagely earns around $70,530 according to the reports of United States Bureau of Labor Statistics. Among the states of United States District of Columbia, Maryland, Virginia and Massachusetts are the states that provide best salaries to the customs agent.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}