import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Midwife_in_new_york.css';
import { NavLink } from 'react-router-dom';

export const Midwife_in_new_york = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=new york")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=new york&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Midwife in New York - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`To become a Midwife in New York, one must first get their certification.  Find New York Midwifery schools and programs here.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MIDWIFE IN NEW YORK
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Responsibilities Of A Midwife In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Midwife In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Midwife In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Midwife In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Midwife Make In New York</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l6">Career Outlook For Midwife In New York</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In New York City, licensed midwives works in collaboration with physicians to provide care and support for the women during pregnancy, childbirth, neonatal and postpartum conditions and gynaecological examinations. Midwives are preferred by women for the prenatal, neonatal and postpartum care as well as the assistance of midwives for pregnancy monitoring, assistance of childbirth and care of newborn baby is highly demanded.
                                        The step-to-step information required to opt this career is given below:
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>RESPONSIBILITIES OF A MIDWIFE IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Monitoring of pregnancy</li>
                                        <br />
                                        <li>Assessing healthcare requirements</li>
                                        <br />
                                        <li>Assistance during childbirth</li>
                                        <br />
                                        <li>Provide support and care during and after pregnancy</li>
                                        <br />
                                        <li>Provide care and counselling for patients with termination, neonatal death and other complications</li>
                                        <br />
                                        <li>Provide family plans</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A MIDWIFE IN NEW YORK</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row heading_area">
                                    <h4>Personal requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Good communication skills</li>
                                        <br />
                                        <li>Adaptive and caring</li>
                                        <br />
                                        <li>Able to handle critical situations</li>
                                        <br />
                                        <li>Able to work under stress</li>
                                        <br />
                                        <li>Able to cope up with emergencies</li>
                                        <br />
                                        <li>Compassionate and patient</li>
                                    </ul>
                                </div>
                                <br />
                                <div className="row heading_area">
                                    <h4>Educational requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A bachelor’s degree program in nursing</li>
                                        <br />
                                        <li>A master’s degree in midwifery</li>
                                        <br />
                                        <li>A license in midwifery</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A MIDWIFE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To fulfill the credentials, the candidate must hold a bachelor’s degree program in nursing by any institution offering that in New York. This 4-years degree assists the candidate in subjects such as pathology, physiology, anatomy, pharmacology and healthcare assessment. It enables the candidate to survive in any various areas of healthcare practice.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Enroll In A Midwifery School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In New York City, there are many Midwifery schools that offer the midwifery training. Once enrolled, this 3-years training aids the candidate in getting hands-on experience and training in health assessment, critical thinking, handling of complications, neonatal care, postpartum care, pregnancy care, childbirth care and handling of normal as well as high risk pregnancy.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Obtain A Midwifery License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for a midwife to hold license as only a licensed midwife is allowed to practice in New York City. The license is issued by the Midwifery Unit of New York Education Department or American College Of Nurse-Midwives authorized by American Midwifery Certification Board (AMCB). After an exam, the license is issued making the candidate eligible to practice as a midwife.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A MIDWIFE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time-limit to become a midwife in New York is usually around 8 years. This limit includes a 4-years bachelor’s degree, a 3-years midwifery training and an year to obtain license and a job. The time limit may vary because of the undetermined time required for the acquiring of license and placement of job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A MIDWIFE MAKE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In New York City, an average midwife makes an yearly income of around $131,697, respectively. Whereas the yearly income usually falls in between $121,096 to $149,235 for the midwives in New York City. Income typically varies due to working hours, experience, certifications and proficiency.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>CAREER OUTLOOK FOR MIDWIFE IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is stated by BLS that the employment opportunities for midwives in New York may increase at the rate of 19% during 2016-26. However another report predicts that these opportunities may increase upto 21-27% in next ten years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-midwife-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>MIDWIFE SCHOOLS IN NEW YORK</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/new-york/" className="btn btn-dark">Midwife Schools in New York</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}