import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Cia_agent.css';
import img6 from '../images/6.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Cia_agent = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a CIA Agent - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CIA AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href={`#what_are_the_duties_of_a_CIA_agent`}>What Are The Duties Of A CIA Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#requirements_to_become_a_CIA_agent`}>Requirements To Become A CIA Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#STATES`}>States</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#steps_to_become_a_CIA_agent`}>Steps To Become A CIA Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_long_does_it_take_to_become_a_CIA_agent`}>How Long Does It Take To Become A CIA Agent</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href={`#how_much_does_a_CIA_agent_usually_earn`}>How Much Does A CIA Agent Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="what_are_the_duties_of_a_CIA_agent">
                                <h2>WHAT ARE THE DUTIES OF A CIA AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Does the thought of being a spy excite you then the job of CIA agent is definitely for you! The officer working for the Central Intelligence Agent (CIA) usually carries out covert espionage missions for the government. Here is the quick inventory of the duties that a CIA agent usually carries out. A CIA agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Gather and verify the intelligence information for the country</li>
                                        <br />
                                        <li>Make that intelligence information available for one’s officials</li>
                                        <br />
                                        <li>Collect intelligence information about various mafias and drug cartels</li>
                                        <br />
                                        <li>Provide security-</li>
                                        <br />
                                        <li>Try to locate where weapons of mass destruction are made, kept and tested</li>
                                        <br />
                                        <li>Report all the information to elected representatives of United States of America, sometimes even directly to the President himself.</li>
                                        <br />
                                        <li>File proper paperwork for the case one is working on</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CIA AGENT</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={img6} alt="STEPS TO BECOME A CIA AGENT" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="requirements_to_become_a_CIA_agent">
                                <h2>REQUIREMENTS TO BECOME A CIA AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The first and foremost thing one needs to accept about becoming a CIA agent is that it is all about secrecy. If you are aiming to become a CIA agent then CIA advises you to carry out simple instructions; one must never disclose that one is going to apply for CIA agent. In fact, one must not even disclose that one is even thinking of applying for the job.
                                        Here are some requirements to become a CIA agent of United States of America. A CIA agent:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 18 years old</li>
                                        <br />
                                        <li>Must have a bachelor’s degree with at least 3.0 GPA</li>
                                        <br />
                                        <li>Should have a fluency in a foreign language</li>
                                        <br />
                                        <li>Should have some experience of travelling or living in some other country</li>
                                        <br />
                                        <li>Must have top security clearance in all tests</li>
                                        <br />
                                        <li>Must successfully pass the polygraph test</li>
                                        <br />
                                        <li>Must be able to adapt new things</li>
                                        <br />
                                        <li>Should be able to blend in any environment</li>
                                        <br />
                                        <li>Complete 18 months training of CIA agent</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        {/* <NavLink to={`/how-to-become-a-cia-agent-in-` + item.state.replace(/ /g, '-').toLowerCase() + `/`}>{item.state}</NavLink> */}
                                                        <NavLink to='/'>{item.state}</NavLink>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="steps_to_become_a_CIA_agent">
                                <h2>STEPS TO BECOME A CIA AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the step by step guide to help you comprehend how one becomes a CIA agent.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        The candidate aiming to become a CIA agent must be permanent US citizen, at least 18 years old and have secured a GED with excellent grades. Then one must secure a bachelor’s degree in like justice, criminology, languages and other related fields with at least 3.0 GPA. It is a highly competitive job that is why one must secure excellent GPA in the undergraduate degree to stand out from the rest. It is advisable to pursue a Master’s degree in the same fields as the candidates with Master’s degree are preferred.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass The Tests</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        The candidate applying for entrance level job at Central Intelligence Agent (CIA) has to go through extensive testing process. The candidate has to pass the aptitude test and then move on to the physical test. The candidates also have to go through extensive background check to ensure that the candidate has no connection with any cartel, mafia or gang. A candidate also has to pass the polygraph test and mental evaluation to get the job. A candidate must have top security clearance in all tests and have excellent GPA in his degree to acquire the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Training</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: `500` }}>
                                        It is one of the most sought after, highly competitive job hence only the best among best are selected for the job. The selected candidate then has to complete the Criminal Investigator Training Program (CITP) through the Federal Law Enforcement Training Center. The duration of the training is 18 months after which one finally joins the job where one has to work under the experienced official to learn the inner workings of job.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_long_does_it_take_to_become_a_CIA_agent">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CIA AGENT?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It takes four years to complete a bachelor’s degree in fields like justice, criminology, languages and other related fields. The candidates with Master’s degree are usually preferred than simple undergraduate candidates. When one finally clears the physical and written tests, extensive background check and polygraph test then one has to do CIA training for 18 months. Hence, it takes around 6 to 8 years to become a CIA agent in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="how_much_does_a_CIA_agent_usually_earn">
                                <h2>HOW MUCH DOES A CIA AGENT USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics marks the average per annum salary of special agents like CIA agent at $63,380. The pay of the CIA agents varies on the basis of many reasons like education, service duration as well as the level of sensitivity of the job.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <br />
                <br />
            </div>
        </>
    )
}