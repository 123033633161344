import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Midwife_in_florida.css';
import { NavLink } from 'react-router-dom';

export const Midwife_in_florida = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=florida")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=florida&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Midwife in Florida - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`To become a Midwife in Florida, one must first get their certification.  Find Florida Midwifery schools and programs here.`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A MIDWIFE IN FLORIDA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Responsibilities Of A Midwife In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Steps To Become A Midwife In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">How Long Does It Take To Become A Midwife In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Much Foes A Midwife Make In Florida</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Career Outlook For Midwife In Florida</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In Florida, Midwives assisted in delivering one out of every 12 babies in past few years. Midwives are playing important role in healthcare profession as they provide care for newborn babies, examining of pregnant women, monitoring of foetus, assistance in labor and care in postpartum. Midwives also accompany and support women during neonatal/prenatal and gynaecological examinations.
                                        The fundamentals of this profession are given below:
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>RESPONSIBILITIES OF A MIDWIFE IN FLORIDA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <h4>Personal requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Able to work under stressful environment</li>
                                        <br />
                                        <li>Able to deal with emergencies</li>
                                        <br />
                                        <li>Understands importance of teamwork</li>
                                        <br />
                                        <li>Adaptable and caring with different patients</li>
                                        <br />
                                        <li>Able to assist mother in neonatal and postpartum</li>
                                        <br />
                                        <li>Able to take care of mother and child after delivery</li>
                                    </ul>
                                </div>
                                <br />
                                <div className="row">
                                    <h4>Educational requirements:</h4>
                                </div>
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>A bachelor’s degree program in nursing</li>
                                        <br />
                                        <li>Master’s in midwifery</li>
                                        <br />
                                        <li>Authorized license in midwifery</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>STEPS TO BECOME A MIDWIFE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Get A Bachelor’s Degree Program</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In Florida, various institutions offer a 4-years bachelor’s degree program in nursing where the candidate is trained to hold professional expertise in health assessment, time management, critical thinking, handling of emergencies and complications management as well as monitoring/administration of medications/injections and taking of blood samples, body temperature etc.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Enroll In A Midwifery School</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        A master’s degree program in midwifery allows the person an insight in the profession. This 3-years degree program helps the candidate to build up the skills such as competency, good observational skills, handling of critical situations, assistance in Cesarean births, neonatal and prenatal care, care for newborn infants and women care.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Obtain Midwifery Licence</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The American Midwifery Certification Board is authorized by The Florida Board of Nursing to issue license to the midwives after which they are eligible to practice in any healthcare institution they consider fit. A midwifery license is mandatory and can be applied for once the candidate has passed out from any midwifery school of Florida.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A MIDWIFE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If the time required to complete educational requirements and appointment for job, is included then it may take around 8 years. This timeframe includes a 4-years bachelor’s degree, a 3-years master’s in midwifery training and an year to obtain license as well as a firm job. The time limit can vary from person-to-person for obtaining of license.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW MUCH DOES A MIDWIFE MAKE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        It is estimated that an average midwife makes around $103,399 per year, in Florida, whereas the average salary usually varies in between $95,075 to $117,168 per year, respectively. The increase or decrease in salaries depends typically on the number of deliveries administered/assisted, working hours and experience.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>CAREER OUTLOOK FOR MIDWIFE IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The prediction given by a survey states that their may an increase in jobs for midwives during 2016-26 in Florida at the rate of 21% whereas another report predicts that the rate may fall in between 19-27% for the introduction of new jobs for midwives in Florida in the time span of next ten years.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-midwife-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>MIDWIFE SCHOOLS IN FLORIDA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/florida/" className="btn btn-dark">Midwife Schools in Florida</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}