import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Cpa.css';
import cpa from '../images/cpa.jpg';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Cpa = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Cpa - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CPA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Certified Public Accountant (CPA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become Certified Public Accountant (CPA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Certified Public Accountant (CPA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Certified Public Accountant (CPA)</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Certified Public Accountant (CPA) Usually Earn?</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A CERTIFIED PUBLIC ACCOUNTANT (CPA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Certified Public Accountant (CPA) advises his clients on the financial issues and help in calculations of taxes and audit of the company or the business. We have provided a quick list of all the duties that are part of the general job description of a Certified Public Accountant (CPA). A Certified Public Accountant (CPA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Make financial plans while pointing out all the pros and cons</li>
                                        <br />
                                        <li>Make monthly and yearly audit reports</li>
                                        <br />
                                        <li>Create budgets and do detail analysis on them</li>
                                        <br />
                                        <li>Suggest methods to save money and cut back expenses</li>
                                        <br />
                                        <li>Advise on the taxes and helps to calculate them</li>
                                        <br />
                                        <li>Suggest some ways to save on taxes while making some business related decisions</li>
                                        <br />
                                        <li>Make audits of the accounts to point out the errors in the transactions caused by fraud or extra expenditures</li>
                                        <br />
                                        <li>Act as an advisor in financial matters</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>STEPS TO BECOME A CPA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={cpa} alt="STEPS TO BECOME A CPA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME CERTIFIED PUBLIC ACCOUNTANT (CPA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are some specifications or requirements which everyone aiming to become Certified Public Accountant (CPA) has to fulfill. A Certified Public Accountant (CPA):
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be a permanent citizen of United States of America</li>
                                        <br />
                                        <li>Must be at least 18 years old at the time of application submission</li>
                                        <br />
                                        <li>Must provide identification by providing social security number</li>
                                        <br />
                                        <li>Have a bachelor’s degree from a recognized degree</li>
                                        <br />
                                        <li>Must successfully pass all the four windows of the Certified Public Accountant (CPA) examination</li>
                                        <br />
                                        <li>Garner experience of at least 6 months to two years</li>
                                        <br />
                                        <li>Take the ethics test and successfully pass it</li>
                                        <br />
                                        <li>Apply for license after passing all the required examinations</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A CERTIFIED PUBLIC ACCOUNTANT (CPA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        We have compiled a step by step guide to help you realize how one becomes a Certified Public Accountant (CPA) in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The candidate for the post of Certified Public Accountant (CPA) must be at least 18 years old and have permanent nationality of United States of America. In addition to having a GED, one must also have an MBA or a bachelor’s degree. One can pursue a bachelor’s degree in business, accounting, finance or business administration and management and then make it equivalent to MBA by taking extra credit hours.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: Take The Certified Public Accountant (CPA) Examination</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The Certified Public Accountant (CPA) is divided into four sections as mentioned below.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Audit and Attestation</li>
                                        <br />
                                        <li>Financial Accounting and Reporting</li>
                                        <br />
                                        <li>Regulations</li>
                                        <br />
                                        <li>Business environments and concepts</li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is compulsory for every candidate to pass all the four sections of the Certified Public Accountant (CPA) examination to acquire the job. These four types of different tests will cover all the major niches that you studied during your MBA or Bachelor’s degree.
                                        It is advised to prepare at least six weeks for one section. It is advised not to give the test of more than two sections in each testing window. In addition to that, when one has given the test of one section, one has the time limit of 18 months to successfully pass the remaining three sections in the meantime. Failure to do so would inevitably lead to the expiry of the first examination.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Gather Work Experience And Acquire The License Of Certified Public Accountant (CPA)</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After successfully passing the four tests, the candidate must join a company and work hard to gain work experience. It takes minimum two years to gather enough work experience to apply for the license of Certified Public Accountant (CPA). When one finally becomes a licensed Certified Public Accountant (CPA) one can easily get promoted to high paying jobs.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A CERTIFIED PUBLIC ACCOUNTANT (CPA)?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The time duration for completing the bachelor’s degree in business, accounting, finance or business administration and management is around four years. Then one has to take additional credit hours for one more year to make your bachelor’s degree equivalent to MBA. Then you must be in accounting field for at least two years to become eligible to apply for the certification. So in essence, it takes nearly 8 years to become a Certified Public Accountant (CPA) in United States of America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A CERTIFIED PUBLIC ACCOUNTANT (CPA) USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average median pay of a Certified Public Accountant (CPA) is approximately $70,500 according to the facts and figures provided by the United States Bureau of Labor Statistics. California, Texas and New York are some of the states that reward the Certified Public Accountant (CPA) with excellent wages for their efforts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}