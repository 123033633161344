import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Police_officer_in_california.css';
import California from '../images/California-1.png';
import { NavLink } from 'react-router-dom';

export const Police_officer_in_california = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStatesByCity?state=california")
            .then(response => {
                setcities(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);
    ////console.log(cities);

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data=california&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How to Become a Police Officer In California - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A POLICE OFFICER IN CALIFORNIA
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#Responsibilities_of_a_Police_Officer_in_California">Responsibilities Of A Police Officer In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Requirements_to_become_a_Police_Officer_in_California">Requirements To Become A Police Officer In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Steps_to_become_a_Police_Officer_in_California">Steps To Become A Police Officer in California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_long_it_takes_to_become_a_Police_Officer_in_California">How Long Does It Take To Become A Police Officer in California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#How_much_does_a_Police_Officer_make_in_California">How Much Does A Police Officer Make In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#Career_Outlook_For_Police_Officers_in_California">Career Outlook For A Police Officer Make In California</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l7">Cities</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l8">Police Officers Schools in California</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Police officers in California hold a special place of respect among people. They not only risk their lives for the safety of people of California but they are also available for the first ones to be called in the hour of need.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="Responsibilities_of_a_Police_Officer_in_California">
                                <h2>RESPONSIBILITIES OF A POLICE OFFICER IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Make arrests for violators of laws</li>
                                        <br />
                                        <li>Investigates the conditions that are hazardous to life and property</li>
                                        <br />
                                        <li>Conduct initial crime scene investigation</li>
                                        <br />
                                        <li>Collect and preserves evidence</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A POLICE OFFICER IN CALIFORNIA</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={California} alt="HOW TO BECOME A POLICE OFFICER IN CALIFORNIA" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row heading_area" id="Requirements_to_become_a_Police_Officer_in_California">
                                <h2>REQUIREMENTS TO BECOME A POLICE OFFICER IN CALIFORNIA</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Be at least 18 yeas old</li>
                                        <br />
                                        <li>Hold citizenship and valid driving license of california</li>
                                        <br />
                                        <li>Hold a high school diploma/ GED certificate</li>
                                        <br />
                                        <li>Pass extensive background check</li>
                                        <br />
                                        <li>No criminal record</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="Steps_to_become_a_Police_Officer_in_California">
                                <h2>STEPS TO BECOME A POLICE OFFICER IN CALIFORNIA:</h2>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Fulfill The Minimum Eligibility Criteria:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The first step for being a police officer in California is to obtain the minimum required education to be eligible enough for this job. This comprises of at least a high-school diploma, Age of at least 17-21, no criminal record and California driving license.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Pass The Law Enforcement Entrance Exam:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To get admission in Police Academy, the applicant first needs to pass the police law enforcement exam of California that includes reading comprehension, grammar, math and situation analysis. The passing score is usually 70% or more.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In A Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After passing the LEE exam, the applicant needs to get themselves enrolled in a Police Academy of California. A lot of academies offer training for all the posts. Fresno City College: Police Academy, San Francisco Police Academy and Long beach Police Academy are a few out of numerous ones in California, United states.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Graduate From The Police Academy:</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        In the police academy, they get the necessary education and training required to work as a Police Officer in California. This training is usually of 26 weeks ( 6 months). Applicants also work for a 2 year Associate degree or a bachelors’ degree to get job in some police departments of California that require it.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5:Wait For The Final Review And Job Offer</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After graduating, the applicant is supposed to wait for the final review that is done by higher officials of the police department of California in which the applicant intended to work. After reviewing the records of training, interview and physical exam, applicant is called to confirm the job offer.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_long_it_takes_to_become_a_Police_Officer_in_California">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A POLICE OFFICER IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The training that starts after getting enrolled in a police academy takes about 26 weeks (6 months), including the LEE exam and final review, it takes 8-10 months to get appointed with a firm job in California.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="How_much_does_a_Police_Officer_make_in_California">
                                <h2>HOW MUCH DOES A POLICE OFFICER MAKE IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        In 2018, it’s recorded that a police officer makes about $63,380 per year/ $30.47 per hour in California.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="Career_Outlook_For_Police_Officers_in_California">
                                <h2>CAREER OUTLOOK FOR A POLICE OFFICER IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Number of jobs in California for Police Officers were 808,700. It’s predicted that the employment opportunities would increase on the rate of 5% during 2018-28 in California.
                                    </p>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink to={`/how-to-become-a-police-officer-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <div className="row heading_area" id="l7">
                                <h2>POLICE OFFICER SCHOOLS IN CALIFORNIA</h2>
                            </div>
                            <div className="container">
                                <div className="row btn_related">
                                    <div className="col-md-6">
                                        <NavLink to="/california/" className="btn btn-dark">Police Officer Schools in California</NavLink>
                                    </div>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <table className="table table-striped table-hover table-bordered" id="unibystate">
                                        <thead>
                                            <tr>
                                                <th>University Name</th>
                                                <th>Rank</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                unibystate.length > 0 ?
                                                    unibystate.map((u =>
                                                        <tr>
                                                            <td>{u.uniName}</td>
                                                            <td>{u.rank}</td>
                                                            <td>{u.address}</td>
                                                        </tr>
                                                    ))
                                                    : ""
                                            }
                                        </tbody>
                                    </table>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}