import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Realtor.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Realtor = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Realtor - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A REALTOR
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Realtor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Realtor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Realtor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Realtor</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Realtor Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A REALTOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The realtor is mostly a licensed, self-employed property agent that facilitate people to buy, sell, rent or mortgage properties. The realtor has a number of duties to perform, clients to meet and paperwork to do while working on the job. The realtor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Oversee all the matters that concern buying, selling or renting a property</li>
                                        <br />
                                        <li>Discuss the commission with the clients beforehand</li>
                                        <br />
                                        <li>Market the property in the community by using different marketing strategies</li>
                                        <br />
                                        <li>Act as the mediator between the people that want to buy a property and those who want to sell their property</li>
                                        <br />
                                        <li>Interview clients who want to purchase a property and do a background check on them</li>
                                        <br />
                                        <li>Decide whether the possible buyers of a property are financially stable to afford the building or not</li>
                                        <br />
                                        <li>Ask the clients about the type of property they want and the budget they have for the venture</li>
                                        <br />
                                        <li>Handles all the necessary paperwork that is part of buying, selling or renting a building</li>
                                        <br />
                                        <li>Provide a price range of the property after comparing it with the inflation and property rate at the given time</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A REALTOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There has been an observed increase in the demand of realtors in America hence, nowadays people have to meet a number of specific requirements in order to become a realtor and sell property. The realtor:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years old depending on the requirement of the specific state</li>
                                        <br />
                                        <li>Must have secured a High School Diploma or GED</li>
                                        <br />
                                        <li>Should have a Bachelor’s degree in marketing, finance or business administration to get employed in a prestigious company</li>
                                        <br />
                                        <li>Should have knowledge about the property and real estate laws of the specific state</li>
                                        <br />
                                        <li>Must fulfill all the prerequisites for acquiring the realtor license</li>
                                        <br />
                                        <li>Need to have experience of working as a realtor in the field of real estate</li>
                                        <br />
                                        <li>Should have excellent communications skills</li>
                                        <br />
                                        <li>Should be charming and persuasive to convince clients to make a deal</li>
                                        <br />
                                        <li>Should have general knowledge about the ongoing market rates for properties’ sale and purchase</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A REALTOR</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are confident of your communication skills and have the natural charisma to impress clients then we have compiled a step by step guide to educate you the proper way of becoming a realtor.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One only needs to have secured a High School Diploma to become eligible for the entry level job as a realtor. One could work as an assistant to the licensed realtor after securing high school diploma. But if one wants to become a licensed realtor and work in a corporate company then one needs to have a Bachelor’s degree in any business related field.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Complete The Prerequisites For The License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally secured a degree, GED or Bachelor’s degree, one has to acquire some experience in the field as a realtor. One could work as an intern for a licensed realtor or secure an entry level job as an associate realtor and receive on the job training regarding the real estate during the first year of the job.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Secure The Realtor License</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When one has finally learned the basic craft of working as a realtor by either working as an intern or doing entry level job, one could take the written examination for the realtor license. The examination covers all the basics of real estate and one needs to pass it in order to secure the realtor license.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A REALTOR?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One can start working as a relator just after completing the High School Diploma or acquiring GED. But it is compulsory to have a 4 years Bachelor’s degree if one wants to properly work in a corporate company. In addition to that one needs to satisfy the prerequisites for the license which also includes in field experience as a realtor for a few months. After securing that one has to take a written examination to acquire the realtor license. So, it is safe to assume that one needs to invest 1-5 years after securing the High School Diploma in order to become a realtor in America.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A REALTOR USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average realtor earns the mediocre salary of around $ 50,300 per year as stated in the facts and figures provided by the United States Bureau of Labor Statistics on the official website. The American states that provide best financial advantages to relators are New York, Hawaii and Texas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}