import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Truck_driver.css';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-bootstrap';

export const Truck_driver = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Truck Driver - Salary, Qualifications, Skills & Reviews : A Step-by-Step Guide</title>
                    <meta name='description' content={`Thinking of becoming a Truck Driver? Learn more about the role including reviews from current Truck Drivers, tasks and duties, how much Truck Drivers earn in your state, the skills employers are looking for and career pathways.`}  />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A TRUCK DRIVER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of Truck Driver</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Truck Driver</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Truck Driver</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Truck Driver</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Truck Driver Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF TRUCK DRIVER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is the general list of duties that a traditional truck driver usually carries out:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Transport finished goods as well as raw materials from one place to another safely</li>
                                        <br />
                                        <li>Deliver goods from one state to another state by using the sanctioned interstate routes</li>
                                        <br />
                                        <li>Majority of the truck drivers transport cargo but some also transport vehicles as well as passengers from one place to the other.</li>
                                        <br />
                                        <li>Keep the truck clean and provide best customer service</li>
                                        <br />
                                        <li>Inspect for any mechanical issues and rectify them at once to keep the truck at optimum level of performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A TRUCK DRIVER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Here is a list of general requirements that the truck drivers usually have to meet if they want to successfully secure the truck driver license. In order to achieve the CDL (Commercial Drivers License), the truck driver is generally expected to fulfill the requirements mentioned below:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be 18 years old for intrastate (within a state) truck drive.</li>
                                        <br />
                                        <li>Is expected to be at least 21 years old for interstate (between different states) truck drive.</li>
                                        <br />
                                        <li>Must be a United States Citizen.</li>
                                        <br />
                                        <li>Must provide a domiciliary proof using original documents of the area of his permanent residence in the state.</li>
                                        <br />
                                        <li>Provide the current DL class driver’s license</li>
                                        <br />
                                        <li>Submit the social security documentation</li>
                                        <br />
                                        <li>Provide proof of insurance as well as other financial engagements</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>Select STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <NavLink to={`/`} style={{ fontSize: '13px', textDecoration: 'underline', fontWeight: '900' }}>{item.state}</NavLink>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A TRUCK DRIVER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        There are four classes of CDL available for the truck drivers; Class A, B, C and personal DL. The Class A CDL provides the driver with most incentives and the driver has a wider range of options to choose from which narrows down in Class B and C until it leaves the option of driving only cars, vans and agricultural vehicles for personal DL holders.
                                        If you want to pursue a career as a Truck Driver then here are some general steps that every emerging truck driver has to undertake.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1: Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Generally, no formal education is required to work as a truck driver but the driver should at least have the High School diploma to get the permit. Although, the education requirements vary from state to state but a truck driver is expected to have a diploma from a truck driving institute to secure the license, preferably Class A CDL.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2: The Vehicle</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The condition as well as the weight of the vehicle plays a pivotal role in determining the class of the CDL. The truck drivers with Class A CDL should have a vehicle with weight more than 26,000 pounds. In addition to that, the trailer attached to the truck or any hind vehicle must also weigh more than 10,000 pounds. Likewise the weight of the vehicle and the trailer decreases as the permit shifts from Class B to Class C, thus narrowing the range of truck types that the driver can drive.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3: Experience On The Road</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        It is very important to become familiar with the routes as well as the alternate roads to get the permit. The driver’s experience on the road also plays an eminent role in availability of the Class A CDL as it is common for the Class A drivers to transport goods using interstate routes as well as to operate the Class B or C vehicles.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A TRUCK DRIVER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The duration depends upon the driver’s credentials, type of permit and the laws of the state. Generally, the truck driving school program to secure the Class A CDL usually spans over seven weeks. It is a full time program that runs five days per week, but the length of the program is usually maintained according to the laws and regulation of the given state.
                                        It takes rather less time to complete the training for Class B (probably one week) or Class C permit because of the limited options of truck types and load weight available to choose from. In short, becoming a truck driver often requires full time commitment as the journey could range from mere hours to many days while transporting the consignment.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A TRUCK DRIVER EARN</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The annual mean pay of Truck Drivers is around $43,680 in May 2018 according to the United States Bureau of Labor Statistics. There are nearly 3.5 million professional truck drivers working all over the United States of America according to the American Trucking Association out of which nearly 6% are professional female truck drivers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}