import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Surgeon.css';
import map from '../images/US-states-Abbreviation-map.jpg';

export const Surgeon = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Surgeon - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A SURGEON
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Surgeon</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Surgeon</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Steps To Become A Surgeon</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">How Long Does It Take To Become A Surgeon</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">How Much Does A Surgeon Usually Earn</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A SURGEON</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The surgeon is a highly skilled medical worker that performs invasive surgeries on the patients to remove tumors or malfunctioning organs and replace them with healthy organs. When working on the clock, a surgeon is extremely busy because one has to carry out a number of different surgeries as well as other basic duties associated with being a doctor. A surgeon:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Provide care to patients by doing surgery on them</li>
                                        <br />
                                        <li>Thoroughly examine the condition of the patient and then make a decision about conducting surgery or not</li>
                                        <br />
                                        <li>Explain the process of surgery to the patient in simple jargon</li>
                                        <br />
                                        <li>Check on the patients again post-surgery</li>
                                        <br />
                                        <li>Remove tumors from different parts of the body</li>
                                        <br />
                                        <li>Try to eradicate deformities in bones and muscles through surgery</li>
                                        <br />
                                        <li>Remove malfunctioning organs, tissues or glands and replace them with healthy and functional donated organs</li>
                                        <br />
                                        <li>Remove any unwanted growths that could potentially become a threatening tumor in future</li>
                                        <br />
                                        <li>Clear away any obstructions in the blood vessels especially those near to heart and lungs</li>
                                        <br />
                                        <li>Perform plastic or reconstructive surgeries on the patients that want to change the way their body looks</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A SURGEON</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        One has to have a highly selective skill set in order to become a surgeon that is why becoming a surgeon in USA is no smooth sailing. Every candidate for the position of a surgeon has to meet a list of requirements in order to secure the job. A surgeon:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must have secured a High School Diploma with excellent grades in science subjects</li>
                                        <br />
                                        <li>Must have a Bachelor’s degree in biology, chemistry or physics to get into medical school</li>
                                        <br />
                                        <li>Prepare for (MCAT) and secure exceptional marks in it</li>
                                        <br />
                                        <li>Secure admission in a medical school</li>
                                        <br />
                                        <li>Successfully graduate from the medical school</li>
                                        <br />
                                        <li>Complete the residency and improve the skill set</li>
                                        <br />
                                        <li>Successfully pass the three steps (USMLE) to obtain the medical license</li>
                                        <br />
                                        <li>The license must be renewed regularly before the last one expires</li>
                                        <br />
                                        <li>Pursue further studies in order to broaden the horizons</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEPS TO BECOME A SURGEON</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you are sure that you have the desired abilities and skills to pursue the extremely competitive career of a surgeon then here is a step by step to help you understand how one becomes a surgeon in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Basic Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        First and foremost, graduate from High School with excellent grades in sciences then secure a Bachelor’s degree in biology, chemistry, physics, physiology or anatomy. It is better to go for the degree which has maximum medical related subjects in it for better MCAT preparation.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Prepare MDCAT</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the Bachelor’s degree one must prepare for the Medical College Admissions Test (MCAT). The MCAT is conducted three times a year and a student can take the MCAT in any session. One has to secure extraordinary marks in MCAT, in order to secure enough marks to satisfy the high merits of medical schools.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Enroll In Medical School And Graduate</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        When the MDCAT score is good enough, one can enroll in a medical school. The medical degree takes 4 years in which there is 2 years of coursework whole the other two years are spent with students working closely with patients.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4:Complete The Residency Program And Obtain License By Passing The USMLE</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After graduating from the medical school, one enrolls in a residency program which is 3-7 years long depending on the type of course one is aiming for. After successfully completing the residency, one has to successfully pass all 3 examinations of U.S. Medical Licensing Examination (USMLE) to obtain the MD license. One needs to regularly renew the license to keep practicing as a surgeon in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>HOW LONG DOES IT TAKE TO BECOME A SURGEON?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First of all, one secures a 4 years Bachelor’s degree then one has to pass the MCAT to get into medical school. Then it takes 4 years to pass the medical school, followed by 3-7 years long residency program. After that, one has to pass the three parts of USMLE to acquire the license which takes another year. Hence, it is safe to estimate, that it takes 12-15 years for one to finally become a surgeon in USA.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>HOW MUCH DOES A SURGEON USUALLY EARN?</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The United States Bureau of Labor Statistics has estimated the average annual salary of a surgeon to be around $ 203,880. The American states that are most financially preferable for surgeons to find employments opportunities are Minnesota, New Hampshire and Oklahoma.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}