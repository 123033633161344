import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';

import './Software_developer.css';
import map from '../images/US-states-Abbreviation-map.jpg';
import { NavLink } from 'react-router-dom';

export const Software_developer = () => {
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const [progress, setProgress] = useState(50);
    const [states, setstates] = useState([]);
    useEffect(() => {
        axios
            .get(Base_API_URL + "/api/Home/GetStates")
            .then(response => {
                setstates(response.data)
                setProgress(100)
            })
            .catch(err => {
                ////console.log(err);
            })
    }, []);

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a Software Developer - {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A SOFTWARE DEVELOPER
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">Duties Of A Software Developer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become A Software Developer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#STATES">STATES</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">Step By Step Guide To Become A Software Developer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">Time Duration Required To Become A Software Developer</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">Average Annual Earnings Of A Software Developer</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you want to pursue a career as a Software Developer you must acquire some particular educational degrees as well as successfully complete training and internship programs. A quick guideline of becoming a software developer is given below.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <table className="table table-striped border-bottom">
                                        <tbody>
                                            <tr>
                                                <td>Minimum Age Requirement</td>
                                                <td>Must be at least 18/21 years old</td>
                                            </tr>
                                            <tr>
                                                <td>Minimum Education Criteria</td>
                                                <td>Bachelor’s Degree</td>
                                            </tr>
                                            <tr>
                                                <td>Preferred Major</td>
                                                <td>Computer Science, Software Engineering or any other related niche</td>
                                            </tr>
                                            <tr>
                                                <td>Experience Required to Secure Entry Level Job</td>
                                                <td>None for entry level job and four years of in field experience for Professional Engineer (PE) credential</td>
                                            </tr>
                                            <tr>
                                                <td>Duration of Training Required</td>
                                                <td>A few months on the job training for entry level job</td>
                                            </tr>
                                            <tr>
                                                <td>Other Requirements</td>
                                                <td>	Have excellent problem solving skills, Know the technicalities of coding, running and testing a computer software, Have eye for details and Must be creative and innovative in the field work</td>
                                            </tr>
                                            <tr>
                                                <td>Median salary per annum (BLS)</td>
                                                <td>$105,590</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row heading_area" id="l1">
                                <h2>DUTIES OF A SOFTWARE DEVELOPER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The Software Developer commonly referred to as Computer Software Developer has the main responsibility of designing, coding, encoding and running or testing a newly developed software. A Software Developer lives a rather hectic life as one is working long hours in the office working on some software. A Software Developer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Collect information, research and gather data regarding what kind of software is in demand</li>
                                        <br />
                                        <li>Plan a software that fits the requirements of the client</li>
                                        <br />
                                        <li>Present the ideas to the clients and acquire approval in order to commence the software development process</li>
                                        <br />
                                        <li>Use a wide range of software tools, algorithms and theorems to write new software codes</li>
                                        <br />
                                        <li>Design a layout of the software highlighting major functions that the software will perform</li>
                                        <br />
                                        <li>Commence the coding process and give structure to the software</li>
                                        <br />
                                        <li>Write a compact and efficient code that fits the criteria of the software</li>
                                        <br />
                                        <li>Complete the coding process and initialize the software</li>
                                        <br />
                                        <li>Test run the program and single out the faults and parts that need modification</li>
                                        <br />
                                        <li>Rewrite the code in order to modify all the errors and glitches</li>
                                        <br />
                                        <li>Run the program again to ensure that the program runs smoothly without any glitches</li>
                                        <br />
                                        <li>Review old software and critically evaluate them to find out errors in their coding</li>
                                        <br />
                                        <li>Modify old software to keep the software up to date</li>
                                        <br />
                                        <li>Keep notes on the performance of the software</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A SOFTWARE DEVELOPER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have a passion for developing software and love to experiment with new technology then you must fulfil the requirements mentioned below in order to become a Software Developer in US. A Software Developer:
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Must be at least 18/21 years old</li>
                                        <br />
                                        <li>Must have a Bachelor’s degree in Computer Science or any other related niche</li>
                                        <br />
                                        <li>Should undergo an internship program to increase chances of securing a better entry level job</li>
                                        <br />
                                        <li>Must complete on the job training as one acquires the entry level Software Developer job</li>
                                        <br />
                                        <li>Should pursue a Master’s degree in Computer Science or any other related niche for better prospects at becoming Head Software Developer</li>
                                        <br />
                                        <li>Must acquire at least four years of in field experience as Software Developer in order to acquire the Professional Engineer (PE) credential</li>
                                        <br />
                                        <li>Must successfully clear the Fundamentals of Engineering (FE) exam</li>
                                        <br />
                                        <li>Could also secure certification of Software Development from Institute for Certification of Computer Professionals (ICCP)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row heading_area" id="STATES">
                                <h2>SELECT STATES</h2>
                            </div>
                            <img src={map} alt="US-Map" style={{ width: '100%', height: '300px' }} />
                            <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                <div className="row p-3" style={{ borderBottom: '5px solid rgb(44, 52, 52)', marginBottom: '2%' }}>
                                    {
                                        states != null && states.length > 0 ?
                                            states.map((item, key) => {
                                                return (
                                                    <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                        <a>{item.state}</a>
                                                    </div>
                                                )
                                            })
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>STEP BY STEP GUIDE TO BECOME A SOFTWARE DEVELOPER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        If you have committed yourself to become a Software Developer then we have compiled a systematic guide for you to follow in order to pursue the career of Software Developer.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1:Education</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        First and foremost, one must secure a high school diploma with excellent grades in computer science, mathematics, and statistics. Furthermore, one should enroll in an accredited college and secure Bachelors in Computer Science, Software Engineering, Information and Communications Technology. If one wants to aim for an executive job then it is mandatory for one to have a Master’s degree in the same field.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2:Internship And Job</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        After completing the studies it is advised that one should enroll in an internship program in a Software Company to learn the basics of the job so that when one finally acquires job one can work straight in the field with the professionals without much training. Otherwise newly hired software developers often have to undergo a few months of on the job training before working in the field.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', /* list-style-position: inside; */ borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3:Acquire Professional Engineer (PE) Credential And Computer Science Certification</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        One must have at least 4 years of work experience as a professional Software Developer to become eligible for acquiring Professional Engineer (PE) credential. One needs to pass the Fundamentals of Engineering (FE) exam in the desired niche of software development for specialization in the given field.
                                        <br />
                                        In addition to that, one could also secure software development certification from Institute for Certification of Computer Professionals (ICCP) as it helps one to become competent in the field. In order to acquire that, one needs to give a few examinations, secure membership of the organization as well as renew the certificate after every few years.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>TIME DURATION REQUIRED TO BECOME A SOFTWARE DEVELOPER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        First and foremost, it takes 4 years to acquire the Computer Science Bachelor’s degree. Then one could either pursue an internship or receive on the job training for the computer developer job. If one wants to have Professional Engineer (PE) credential then one needs to secure at least 4 years of experience as well as pass Fundamentals of Engineering (FE) exam. One could also secure a Master’s degree of 2 to 3 years to acquire executive jobs as well and acquire certification as well. So, altogether, one needs to invest at least 5 to 12 years in the field to become a Software Developer in US.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l5">
                                <h2>AVERAGE ANNUAL EARNINGS OF A SOFTWARE DEVELOPER</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average Software Developer in US earns nearly $105,590 per year according to the facts and figures provided by the United States Bureau of Labor Statistics. California, New Jersey and Virginia are the American states that facilitate the Software Developers most by providing them with best salary packages.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l6">
                                <h2>SUGGESTIONS</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <ul className="profession_with_states">
                                        <li><NavLink to="/diesel-mechanic/">Steps to become a Diesel Mechanic</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </>
    )
}