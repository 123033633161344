import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { useParams, NavLink } from "react-router-dom";

import './Cia_agent_in_other_states.css';
import _cia from '../images/_cia.png';
import capture3 from '../images/Capture3.PNG';

export const Cia_agent_in_other_states = () => {
    window.location.href = '/';
    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g, '');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    const parameter = useParams();
    parameter.parameter = parameter.parameter.replace('how-to-become-a-cia-agent-in-', ' ').replace(/-/g, ' ').trim();
    //console.log(parameter.parameter);

    const [count, setCount] = useState(0);
    const [progress, setProgress] = useState(50);
    const [cities, setcities] = useState([]);    
    const [rltdcities, setrltdcities] = useState([]);
    const [statecitytable, setstatecitytable] = useState([]);
    useEffect(() => {
        if (parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
            || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
            || parameter.parameter === "district of columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
            || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
            || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
            || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
            || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
            || parameter.parameter === "nevada" || parameter.parameter === "new hampshire" || parameter.parameter === "new jersey" || parameter.parameter === "new mexico"
            || parameter.parameter === "new york" || parameter.parameter === "north carolina" || parameter.parameter === "north dakota" || parameter.parameter === "ohio"
            || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode island"
            || parameter.parameter === "south carolina" || parameter.parameter === "south dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
            || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
            || parameter.parameter === "washington" || parameter.parameter === "west virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming") {
            axios
                .get(Base_API_URL + "/api/Home/GetStatesByCity?state=" + parameter.parameter)
                .then(response => {
                    setcities(response.data)
                    setProgress(100)
                })
                .catch(err => {
                    ////console.log(err);
                })
        }
        else {
            setProgress(50)
            window.scrollTo(0, 0)
            axios
                .get(Base_API_URL + "/api/Home/GetRelatedCities?City=" + parameter.parameter.slice(0, -4))
                .then(response => {
                    setrltdcities(response.data)
                    setProgress(100)
                })
                .catch(err => {
                    ////console.log(err);
                })

            axios
                .get(Base_API_URL + "/api/Home/GetStateCityTable?City=" + parameter.parameter.slice(0, -4))
                .then(response => setstatecitytable(response.data))
                .catch(err => {
                    ////console.log(err);
                })
        }
    }, [count]);
    ////console.log(cities);
    ////console.log(rltdcities);
    ////console.log(statecitytable);

    function endCaseWords(input) {
        return input.toLowerCase().split(" ").map(function (item) {
            return item.slice(0, -1) + item.slice(-1).toUpperCase();
        }).join(" ");
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    //var newUrl = currentUrl.slice(0, -1);
    if (lastElement !== "/") {
        window.history.pushState({}, null, currentUrl + '/');
    }

    if (parameter.parameter === "alabama" || parameter.parameter === "alaska" || parameter.parameter === "arizona" || parameter.parameter === "arkansas"
        || parameter.parameter === "california" || parameter.parameter === "colorado" || parameter.parameter === "connecticut" || parameter.parameter === "delaware"
        || parameter.parameter === "district of columbia" || parameter.parameter === "florida" || parameter.parameter === "georgia" || parameter.parameter === "hawaii"
        || parameter.parameter === "idaho" || parameter.parameter === "illinois" || parameter.parameter === "indiana" || parameter.parameter === "iowa"
        || parameter.parameter === "kansas" || parameter.parameter === "kentucky" || parameter.parameter === "louisiana" || parameter.parameter === "maine"
        || parameter.parameter === "maryland" || parameter.parameter === "massachusetts" || parameter.parameter === "michigan" || parameter.parameter === "minnesota"
        || parameter.parameter === "mississippi" || parameter.parameter === "missouri" || parameter.parameter === "montana" || parameter.parameter === "nebraska"
        || parameter.parameter === "nevada" || parameter.parameter === "new hampshire" || parameter.parameter === "new jersey" || parameter.parameter === "new mexico"
        || parameter.parameter === "new york" || parameter.parameter === "north carolina" || parameter.parameter === "north dakota" || parameter.parameter === "ohio"
        || parameter.parameter === "oklahoma" || parameter.parameter === "oregon" || parameter.parameter === "pennsylvania" || parameter.parameter === "rhode island"
        || parameter.parameter === "south carolina" || parameter.parameter === "south dakota" || parameter.parameter === "tel" || parameter.parameter === "tennessee"
        || parameter.parameter === "texas" || parameter.parameter === "utah" || parameter.parameter === "vermont" || parameter.parameter === "virginia"
        || parameter.parameter === "washington" || parameter.parameter === "west virginia" || parameter.parameter === "wisconsin" || parameter.parameter === "wyoming") {
        rltdcities.length = 0;
        statecitytable.length = 0;
    }
    else {
        cities.length = 0;
        if (!parameter.parameter.includes(",")) {
            parameter.parameter = parameter.parameter.slice(0, -3) + ", " + parameter.parameter.slice(-2);
            //parameter.parameter = endCaseWords(parameter.parameter);
        }
    }

    const [unibystate, setunibystate] = useState([]);
    const [pages, setpages] = useState(1);
    const [length, setlength] = useState(0);
    useEffect(() => {
        axios.get(Base_API_URL + "/api/Home/GetAllUniversitiesData?Data="+ parameter.parameter.toLowerCase() +"&pageNumber=" + pages + "&pageSize=10")
            .then(response => {
                setunibystate(pre => [...pre, ...response.data])
                setlength(response.data.length)
            })
            .catch(err => {
                //////console.log(err);
            })
    }, [pages])

    if (unibystate.length > 0) {
        if (length > 0) {
            var timer = setTimeout(() => setpages((c) => c + 1), 1000);
        } else {
            clearTimeout(timer);
        }
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <div className='container'>
                <Helmet>
                    <title>How To Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} - {year} </title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <br />
                <div className="container bg-dark text-white font-weight-bold">
                    <div className="row p-2" style={{ fontSize: '150%' }}>
                        HOW TO BECOME A CIA AGENT IN {parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}
                    </div>
                </div>
                <br />
                <br />
                <div className="container p-0">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#d6b816" data-header-text-color="dark" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '806.146px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=d6b816&amp;header_text_color=dark&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fcia-agent%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <div className="container sticky-top">
                                <div className="row">
                                    <ul className="steps_area3">
                                        <li><a onClick={(e) => removeDefault(e)} href="#l1">How to Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l2">Requirements To Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l3">How Long Does it take to Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l4">What is Average Salary of CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                        <br />
                                        <li><a onClick={(e) => removeDefault(e)} href="#l5">CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Benefits</a></li>
                                        {
                                            cities.length > 0 && cities != null ?
                                                <div>
                                                    <br />
                                                    <li><a onClick={(e) => removeDefault(e)} href="#l6">Cities</a></li>
                                                    <br />
                                                    <li><a onClick={(e) => removeDefault(e)} href="#l7">CIA Agent Schools in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</a></li>
                                                </div>
                                                : ""
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row heading_area" id="l1">
                                <h2>HOW TO BECOME A CIA AGENT IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        To Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} may seem like a very glamorous job, thanks to the job’s depiction in popular culture. {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} CIA Agent professionals are often required to have certain levels of education, complete specialized training.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>Meet the minimum requirements to Become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</li>
                                        <br />
                                        <li>Get a bachelor’s degree in  {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</li>
                                        <br />
                                        <li>Consider education for {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row" style={{ border: '2px solid #41b3a3', borderRadius: '5px' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: '#e05700' }}>HOW TO BECOME A CIA AGENT IN {parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h5>
                                    </div>
                                    <div className="col-md-5 p-0">
                                        <img src={_cia} alt={'HOW TO BECOME A CIA AGENT IN ' + parameter.parameter.toUpperCase().replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row heading_area" id="l2">
                                <h2>REQUIREMENTS TO BECOME A CIA AGENT IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        Though CIA Agent of {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} the requirements for each post vary based on the nature of the job, there are some basic criteria that all candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} need to fulfill. For instance, all candidates must follow Steps:
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <h6>Following are the steps to become a CIA agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h6>
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 1</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for CIA Agent must be citizens of {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} in US
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 2</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must be at least 18 Years old
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 3</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Candidate must have a bachelor’s degree with at Least 3.0 GPA.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 4</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} pass a comprehensive background investigation
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 5</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Candidate pass a Polygraph test
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 6</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must pass medical and psychological test
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 7</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Candidate must have top security clearance in all tests.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 8</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        All candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must pass a thorough physical and mental examination.
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 9</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} not have any criminal record
                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row border" style={{ background: 'rebeccapurple', fontSize: '20px', lineHeight: '25px', padding: '10px 10px', boxSizing: 'border-box', color: '#fff', fontWeight: 'bold', borderRadius: '10px', textTransform: 'capitalize' }}>
                                    <h5>Step 10</h5>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        Candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} possess knowledge of international Affairs
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '500' }}>
                                        The basic requirements to become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} CIA Agent must have a bachelor’s degree with at Least 3.0 GPA and must pass medical and psychological test in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}. Though CIA Agent of {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must be 18 years old in order to work, limited on-the-job training can begin at a younger age.
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l3">
                                <h2>HOW LONG IT TAKES TO BECOME A CIA AGENT IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}, you first need to get the required education. Candidates for CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} must be four years to complete a bachelor’s degree in fields like justice, criminology, languages and other related fields. To become a CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Candidate with Master’s degree are usually preferred than simple undergraduate candidates. {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} CIA Agent candidates clears the physical and written tests, extensive background check and pass polygraph test. {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} CIA Agent training for 18 months. Hence, it takes around 6 to 8 years to become a CIA agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}
                                    </p>
                                </div>
                            </div>
                            <div className="row heading_area" id="l4">
                                <h2>WHAT IS THE AVERAGE SALARY OF CIA AGENT IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <p className="text-justify" style={{ fontWeight: '600' }}>
                                        The average salary for CIA agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} is range between $54,308 to $155,858, depending on the exact job title. {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} CIA Agent offers competitive salaries and individuals may earn higher starting salaries depending upon their experience level.
                                    </p>
                                </div>
                            </div>
                            <br />
                            <img src={capture3} style={{ width: '100%' }} />
                            <br /><br />
                            <div className="row heading_area">
                                <h5>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} average salary change by experience in United States</h5>
                            </div>
                            <table className="table table-bordered table-responsive-md">
                                <tr>
                                    <th>Entry Level</th>
                                    <th>Early Career</th>
                                    <th>Mid Career</th>
                                    <th>Late Career</th>
                                    <th>Experienced</th>
                                </tr>
                                <tr>
                                    <td>10.5%</td>
                                    <td>26.3%</td>
                                    <td>26.3%</td>
                                    <td>10.5%</td>
                                    <td>26.3%</td>
                                </tr>
                            </table>
                            <div className="row heading_area">
                                <h5>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} Salary Comparison By Gender</h5>
                            </div>
                            <table className="table table-bordered">
                                <tr>
                                    <th>Male</th>
                                    <th>Female</th>
                                </tr>
                                <tr>
                                    <td>72.2%</td>
                                    <td>27.8%</td>
                                </tr>
                            </table>
                            <div className="row heading_area" id="l5">
                                <h2>CIA AGENT IN {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} BENEFITS</h2>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <ul className="steps_area1">
                                        <li>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} annual leaves, sick and family-friendly leaves</li>
                                        <br />
                                        <li>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} employees are entitled to ten Paid federal holidays per year</li>
                                        <br />
                                        <li>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} offers eligible child care subsidy program</li>
                                        <br />
                                        <li>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} employees can choose from different types of insurance plans (high deductible plans, fee for service, etc.)</li>
                                        <br />
                                        <li>CIA Agent in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} are offered a variety of training programs</li>
                                    </ul>
                                </div>
                            </div>
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l6">
                                            <h2>SELECT CITIES</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    cities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink onClick={() => setCount((c) => c + 1)} to={`/how-to-become-a-cia-agent-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                cities.length > 0 && cities != null ?
                                    <div>
                                        <div className="row heading_area" id="l7">
                                            <h2>CIA AGENT SCHOOLS IN {parameter.parameter.toUpperCase()}</h2>
                                        </div>
                                        <div className="container">
                                            <div className="row btn_related">
                                                <div className="col-md-6">
                                                    <NavLink to={`/` + parameter.parameter.replace(/ /g, '-').toLowerCase() + `/`} className="btn btn-dark">CIA Agent Schools in {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <table className="table table-striped table-hover table-bordered" id="unibystate">
                                            <thead>
                                                <tr>
                                                    <th>University Name</th>
                                                    <th>Rank</th>
                                                    <th>Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    unibystate.length > 0 ?
                                                        unibystate.map((u =>
                                                            <tr>
                                                                <td>{u.uniName}</td>
                                                                <td>{u.rank}</td>
                                                                <td>{u.address}</td>
                                                            </tr>
                                                        ))
                                                        : ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    : ""
                            }
                            {
                                rltdcities.length > 0 && rltdcities != null ?
                                    <div>
                                        <div className="row heading_area">
                                            <h2>Cities related to {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                                        </div>
                                        <div className="container" style={{ backgroundColor: '#ebedf1' }}>
                                            <div className="row pt-2 pb-2 pl-3">
                                                {
                                                    rltdcities.map((x, key) => {
                                                        return (
                                                            <div className="col-md-3 col-6 profession_with_states" key={key}>
                                                                <NavLink onClick={() => setCount((c) => c + 1)} to={`/how-to-become-a-cia-agent-in-` + x.city.replace(/ /g, '-').toLowerCase() + "-" + x.state_Id.toLowerCase() + `/`}>{x.city}, {x.state_Id}</NavLink>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                statecitytable.length > 0 && statecitytable != null ?
                                    <div>
                                        <div className="row heading_area">
                                            <h2>About {parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })}</h2>
                                        </div>
                                        <table className="table table-striped table-hover table-bordered table-responsive-md" id="aboutcity">
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Latitude</th>
                                                    <th>Longitude</th>
                                                    <th>Population</th>
                                                    <th>Military</th>
                                                    <th>Incorporated</th>
                                                    <th>Time Zone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    statecitytable.map((x, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{x.ranking}</td>
                                                                <td>{x.lat}</td>
                                                                <td>{x.lng}</td>
                                                                <td>{x.population}</td>
                                                                <td>{x.military}</td>
                                                                <td>{x.incorporated}</td>
                                                                <td>{x.timezone.replace('_', ' ')}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                </div >
                <br />
                <br />
            </div>
        </>
    )
}