import React from 'react';
import LoadingBar from 'react-top-loading-bar';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { Base_API_URL } from '../API.js'
import { useState, useEffect } from 'react';
import { NavLink, useParams, useLocation } from "react-router-dom";
import './SchoolDetails.css';
import map from '../images/US-states-Abbreviation-map.jpg';
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaBook } from "@react-icons/all-files/fa/FaBook";
import { FaFax } from "@react-icons/all-files/fa/FaFax";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { AiOutlineDollar } from "@react-icons/all-files/ai/AiOutlineDollar";
import { FaSignal } from "@react-icons/all-files/fa/FaSignal";
import { FaQuestionCircle } from "@react-icons/all-files/fa/FaQuestionCircle";
import { FaRss } from "@react-icons/all-files/fa/FaRss";
import Spinner from 'react-bootstrap/Spinner';

export const SchoolDetails = () => {
    const location = useLocation();
    const parameter = useParams();
    parameter.parameter = parameter.parameter.replace(/-/g, ' ').trim();
    //console.log(parameter.parameter);

    const [count, setcount] = useState(0);
    const [progress, setProgress] = useState(50);
    const [universitydetails, setuniversitydetails] = useState([]);
    const [universitydetailserr, setUniversitydetailserr] = useState(0);
    const [lstuniversity_degree, setuniversity_degree] = useState([]);
    const [relateduniversities, setrelateduniversities] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get(Base_API_URL + "/api/Home/GetUniversitiesDetails?UniName=" + parameter.parameter)
            .then(response => {
                if (response.data.length > 0) {
                    setuniversitydetails(response.data)
                    setProgress(100)
                } else {
                    setUniversitydetailserr(1)
                    setProgress(100)
                }
            })
            .catch(err => {
                //console.log(err);
                setUniversitydetailserr(2)
                setProgress(100)
            })

        axios.get(Base_API_URL + "/api/Home/GetDegreeDetails?Name=" + parameter.parameter)
            .then(response => {
                setuniversity_degree(response.data)
            })
            .catch(err => {
                //console.log(err);
            })

        axios.get(Base_API_URL + "/api/SchoolnDegree/GetRelatedUniversities?Name=" + parameter.parameter)
            .then(response => setrelateduniversities(response.data))
            .catch(err => {
                //console.log(err);
            })
    }, [count]);
    //console.log(universitydetails);
    //console.log(lstuniversity_degree);

    if (universitydetails.length > 0) {
        var academic_structure = universitydetails[0].programs.split("/");
        academic_structure = academic_structure.filter(function (e) { return e.replace(/(\r\n|\n|\r)/gm, "") }).slice(2);
        ////console.log(academic_structure);
    }

    var lstunidegree = [];
    if (lstuniversity_degree.length > 0) {
        lstuniversity_degree.map(item => (
            lstunidegree.push(item.degreeDetail.split(',').filter((i) => i != " "))
        ))
        ////console.log(lstunidegree);
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    let d = new Date();
    let year = d.getFullYear().toString();

    return (
        <>
            <LoadingBar color='blue' height={5} progress={progress} />
            <Helmet>
                <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase(); })} {year} - Profile, Rankings and Data | US Best Schools</title>
                <meta name='description' content={parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }) + ` ` + year + ` United States ranking & review including accreditation, study areas, degree levels, tuition range, admission rate, facilities, services and official social media.`} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            {

                universitydetailserr == 0 ?
                    universitydetails.length > 0 ?
                        <>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-12 col-md-2 text-md-left text-center mt-auto mb-auto">
                                                        <img src={universitydetails[0].image} style={{ width: '80px', height: '80px' }} />
                                                    </div>
                                                    <div className="col-12 col-md-8 text-center mt-auto mb-auto">
                                                        <h3>{universitydetails[0].uniName}</h3>
                                                        <label><b>Rank: </b>{universitydetails[0].rank}</label>
                                                    </div>
                                                    <div className="col-12 col-md-2 text-center mt-auto mb-auto">
                                                        <NavLink to={`/forms`} className="btn btn-dark font-weight-bold" style={{ width: '100%', borderRadius: '50px', color: 'white' }}>Request Info</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="csn-widget border-bottom" data-designation="ground" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="search" data-header-background="#2e5058" data-header-text-color="light" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                            <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '377px' }} src="https://widget.careerschoolnow.org?type=search&amp;designation=ground&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=2e5058&amp;header_text_color=light&amp;href=https%3A%2F%2Fwww.stepstobecomea.com%2Fschools%2Fa.t.-still-university%2F" id="iFrameResizer0"></iframe></div></div>
                                    </div>
                                    <div className="col-md-8 mt-md-0 mt-4">
                                        <div className="card">
                                            <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>About</h2>
                                            <div className="card-body">
                                                <p className="text-justify">
                                                    {universitydetails[0].uniName} is a private institution. It
                                                    has a total undergraduate enrollment of {universitydetails[0].studentEnrollment},
                                                    its setting is {universitydetails[0].campusSetting}, and the campus size is 450 acres.
                                                    It utilizes a {universitydetails[0].academicCalendar} based academic calendar.&nbsp;
                                                    {universitydetails[0].uniName}'s ranking in the {year} is #{universitydetails[0].rank}.
                                                    It has a total academic staff of {universitydetails[0].academicStaff}. {universitydetails[0].uniName}
                                                    is located at {universitydetails[0].address} and can be contacted via {universitydetails[0].tel}.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>Size And Profile</h2>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table table-bordered">
                                                            <tr>
                                                                <th>Control Type</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].controlType}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Academic Staff</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].academicStaff}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Student Enrollment</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].studentEnrollment}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Campus Setting</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].campusSetting}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Entity Type</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].entity}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Academic Calendar</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].academicCalendar}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {
                                academic_structure != null && academic_structure.length > 0 ?
                                    <div className="container">
                                        <div className="card text-center">
                                            <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>Academic Structure</h2>
                                            <div className="card-body">
                                                <button className="btn btn-outline-dark disabled mb-1"><b>{universitydetails[0].uniName}</b></button>
                                                <center>|</center>
                                                <div className="row mt-2 justify-content-center">
                                                    <p>
                                                        {
                                                            academic_structure.map((item, i) => {
                                                                return (
                                                                    <button disabled i={i} className="btn btn-outline-dark mb-1 mr-1" style={{ color: 'black' }}>{item}</button>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <br />
                            {
                                lstuniversity_degree != null && lstuniversity_degree.length > 0 ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 col-md-12">
                                                <div className="card">
                                                    <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>Study Areas And Degree Levels</h2>
                                                    <div className="card-body" id="myGroup_degree">
                                                        {
                                                            lstuniversity_degree.map((item, i) => {
                                                                return (
                                                                    <div className='container p-0'>
                                                                        <div className="row mb-1 border">
                                                                            <div className="col-md-6 text-md-left text-center" style={{ paddingTop: '7px' }}>
                                                                                <FaBook /> <b>{item.degreeName}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div className="show row mb-1 divparent">
                                                                            <div className="col-12">
                                                                                <div className="card card-body">
                                                                                    <div className="row mt-2 justify-content-center">
                                                                                        <p className="text-center">
                                                                                            {
                                                                                                lstunidegree != null && lstunidegree.length > 0 ?
                                                                                                    lstunidegree[i].map((item, j) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <button disabled className='btn btn-outline-dark mb-1 mr-1' style={{ color: 'black' }}>{item.replace('amp;', '').trim()}</button>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                    : ""
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>University Snapshot</h2>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Entity</b></p>
                                                        <p><AiOutlineDollar size={50} /></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].entity}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Selectivity</b></p>
                                                        <p><FaQuestionCircle size={50} /></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].selectivity}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Control</b></p>
                                                        <p><FaBuilding size={50} /></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].control}</p>
                                                    </div>
                                                    <div className="col-md-3 col-6 text-center">
                                                        <p><b>Size</b></p>
                                                        <p><FaSignal size={50} /></p>
                                                        <p style={{ textTransform: 'capitalize' }}>{universitydetails[0].size}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h2 className="card-header text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}>Carnegie Classification</h2>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                                            <tr>
                                                                <th>Basic Classification</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].basicClassification}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Undergraduate Instructional Program</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].undergraduateInstructionalProgram}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Enrollment Profile</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].enrollmentProfile}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Graduate Instructional Program</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].graduateInstructionalProgram}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Size & Setting</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].sizeAndSetting}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Undergraduate Profile</th>
                                                                <td style={{ textTransform: 'capitalize' }}>{universitydetails[0].undergraduateProfile}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <div className="card">
                                            <h2 className="card-header text-center" style={{ backgroundColor: 'black', color: 'white' }}>University Location</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <img src={map} style={{ height: '300px' }} className="card-img-top img-fluid border" alt="US-Map" />
                                                </div>
                                                <div className="col-md-6 mt-auto mb-auto">
                                                    <div className="card-body text-center font-weight-bold">
                                                        <p className="card-text"><b>Tel: </b> <a href={`tel:` + universitydetails[0].tel} data-tel={universitydetails[0].tel}><FaPhone style={{ color: 'blue' }} /> {universitydetails[0].tel}</a></p>
                                                        <p className="card-text"><b>Fax: <FaFax style={{ color: 'blue' }} /></b>{universitydetails[0].fax}</p>
                                                        <p className="card-text font-weight-normal"><b>Address: <FaMapMarkerAlt style={{ color: 'red' }} /> </b>{universitydetails[0].address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <NavLink to={`/forms`} className="btn btn-outline-danger d-sm-none fixed-bottom d-block btn-block font-weight-bold" style={{ height: '50px', width: '100%', fontSize: '135%', color: 'white', borderRadius: 0 }}>Request Info</NavLink>
                        </>
                        : <div className="container vh-100">
                            <div className="row justify-content-center centered">
                                <div className="col-12 text-danger text-center">
                                    <h5><Spinner animation="border" size="sm" variant="danger" /> Loading ...</h5>
                                </div>
                            </div>
                        </div>
                    : universitydetailserr == 1 ?
                        <div className="container vh-100">
                            <div className="row justify-content-center centered">
                                <div className="col-12 text-danger text-center">
                                    <h5><FaExclamationCircle /> No Data Found</h5>
                                </div>
                            </div>
                        </div>
                        : <div className="container vh-100">
                            <div className="row justify-content-center centered">
                                <div className="col-12 text-danger text-center">
                                    <h5><FaRss /> Check your Internet Connection or Reload the page</h5>
                                </div>
                            </div>
                        </div>
            }
            <br />
            {
                relateduniversities.length > 0 &&
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3 className="p-3 text-center text-md-left" style={{ backgroundColor: 'black', color: 'white' }}><strong>Related Schools, Colleges And Universities</strong></h3>
                        </div>
                    </div>
                    <table className='table table-bordered table-striped table-md-responsive'>
                        <thead>
                            <tr className='bg-dark text-light'>
                                <th>Institute Name</th>
                                <th>Rank</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                relateduniversities.map((u =>
                                    <tr>
                                        <td><NavLink onClick={() => setcount(count + 1)} to={'/schools/' + u.uniName.trim().replace(/ /g, '-').toLowerCase()}>{u.uniName}</NavLink></td>
                                        <td><NavLink onClick={() => setcount(count + 1)} to={'/schools/' + u.uniName.trim().replace(/ /g, '-').toLowerCase()}>{u.rank}</NavLink></td>
                                        <td><NavLink onClick={() => setcount(count + 1)} to={'/schools/' + u.uniName.trim().replace(/ /g, '-').toLowerCase()}>{u.address}</NavLink></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
            <br />
        </>
    )
}